.games-outer {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.back-icon {
  position: absolute;
  left: 2vw;
  font-size: 2.5vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  margin-top: 1vw;
}

.place-holder {
  width: 400px;
  height: 200px;
}

.games-widget {
  position: relative;
  width: 30vw;
  height: 15vw;
  border: 0.3vw solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-radius: 1vw;
  overflow: hidden;
  box-shadow: 0px 0px 0.5vw var(--main-color);
}

.games-widget:hover {
  border: 0.3vw solid var(--main-color);
  box-shadow: 0px 0px 0.9vw var(--main-color);
  cursor: pointer;
}
.games-widget-outer {
  position: relative;
  width: 33.3vw;
  height: 18vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.matthijsle-title {
  display: flex;
  flex-direction: row;
  gap: 0.35vw;
}

.matthijsle-title-tile {
  height: 2.5vw;
  width: 2.5vw;
  font-size: 1.5vw;
  border-radius: 0.5vw;
  color: white;
  font-weight: bold;
  background-color: var(--incorrect-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: black 0px 0px 0.2vw;
}

.matthijsle-title-tile.green {
  background-color: var(--correct-color);
}

.matthijsle-title-tile.yellow {
  background-color: var(--present-color);
}

.matthijsle-subtitle {
  font-size: 2.5vw;
  font-weight: 1000;
  color: white;
  margin-top: 1vw;
  text-shadow: var(--main-color) 0px 0px 1vw;
}

.music_quiz_upper_title {
  font-size: 1.5vw;
  color: white;
  font-weight: 800;
  z-index: 1;
  text-shadow: black 0px 0px 1vw;
}

.music_quiz_lower_title {
  font-size: 4vw;
  color: white;
  margin-bottom: 10px;
  font-weight: 800;
  z-index: 1;
  text-shadow: black 0px 0px 1vw;
}

.matthijs-sweeper-widget-background {
  position: absolute;
  width: 30vw;
  height: 15vw;
  z-index: 0;
  color: black;
  left: 0;
  top: 0;
}

.matthijs-sweeper-widget-background-tiles {
  display: grid;
  height: 15vw;
  width: 30vw;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(8, 1fr);
}

.tile {
  width: 1.875vw;
  height: 1.875vw;
  background-color: var(--minesweeper-main-color);
  border-left: 0.225vw solid var(--minesweeper-lighter-shade);
  border-top: 0.225vw solid var(--minesweeper-lighter-shade);
  border-right: 0.225vw solid var(--minesweeper-darker-shade);
  border-bottom: 0.225vw solid var(--minesweeper-darker-shade);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono";
  font-weight: 1000;
  font-size: 1.4vw;
}

.tile[value]:not([value=""]):not([value="flag"]) {
  background-color: var(--minesweeper-semi-dark-shade);
  border: 0.08vw solid var(--minesweeper-darker-shade);
}

.tile[value="1"] {
  color: rgb(0, 0, 217);
}

.tile[value="flag"] {
  color: red;
}

.tile[value="2"] {
  color: rgb(1, 113, 1);
}

.tile[value="3"] {
  color: rgb(218, 0, 0);
}

.tile[value="4"] {
  color: rgb(0, 0, 111);
}

.matthijs-sweeper-title {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Digital-Clock";
  z-index: 10;
  color: rgb(255, 238, 0);
  font-size: 6vw;
  font-weight: 1000;
  left: 0;
  top: 0;
  text-align: center;
  text-shadow: black 0px 0px 0.4vw;
}

.music_quiz_widget_background {
  position: absolute;
  width: 30vw;
  height: 15vw;
  background-color: rgb(29, 185, 84);
  z-index: 0;
}

.music_note {
  color: black;
  position: absolute;
}

.music_note.a {
  top: 0px;
  left: 0.75vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.b {
  top: 2.25vw;
  left: 3vw;
  font-size: 7.5vw;
  transform: rotate(-20deg);
}

.music_note.c {
  top: 2.25vw;
  right: 1.5vw;
  font-size: 9vw;
  transform: rotate(-10deg);
}

.music_note.d {
  top: 15vw;
  left: 7.5vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.e {
  top: 6vw;
  left: 15vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.f {
  top: 8.25vw;
  left: 23.25vw;
  font-size: 6vw;
  transform: rotate(10deg);
}

.music_note.g {
  top: 8.25vw;
  left: 0px;
  font-size: 6vw;
  transform: rotate(-15deg);
}

.music_note.h {
  top: 8.25vw;
  left: 4.5vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.i {
  top: 4.125vw;
  left: 10.125vw;
  font-size: 6vw;
  transform: rotate(-10deg);
}

.music_note.j {
  top: 9.75vw;
  left: 9vw;
  font-size: 4.6vw;
  transform: rotate(-20deg);
}

.music_note.k {
  top: 0px;
  left: 12vw;
  font-size: 4.5vw;
  transform: rotate(-20deg);
}

.music_note.l {
  top: 9.75vw;
  left: 12.75vw;
  font-size: 3.75vw;
  transform: rotate(-20deg);
}

.music_note.m {
  top: 0.75vw;
  right: 0px;
  font-size: 4.5vw;
  transform: rotate(-20deg);
}

.music_note.n {
  top: 1.5vw;
  left: 14.625vw;
  font-size: 6vw;
  transform: rotate(10deg);
}

.music_note.o {
  top: 9.75vw;
  left: 18vw;
  font-size: 4.5vw;
  transform: rotate(-10deg);
}

.music_note.p {
  top: 0px;
  left: 19.5vw;
  font-size: 4.5vw;
  transform: rotate(-10deg);
}

.music_note.q {
  top: 0px;
  left: 7.5vw;
  font-size: 3.75vw;
  transform: rotate(-10deg);
}

.music_note.r {
  top: 9.75vw;
  left: 18vw;
  font-size: 4.5vw;
  transform: rotate(-10deg);
}

.games-widget.music {
  background-color: rgb(29, 185, 84);
}

.games-widget.sweeper {
  background-color: gray;
}

.snake-widget {
  width: 30vw;
  height: 15vw;
  position: relative;
  background-color: var(--screen-color);
}

.snake-body {
  position: absolute;
  width: 10vw;
  height: 5vw;
  border-radius: 5vw 5vw 0 0;
  border: 2.5vw solid var(--snake-color);
  border-bottom: none;
}

.snake-body.a {
  top: 7vw;
  left: 2.5vw;
  transform: rotate(180deg);
}

.snake-body.b {
  top: 2.5vw;
  left: 10vw;
}

.snake-body.c {
  top: 7vw;
  right: 2.5vw;
  transform: rotate(180deg);
}

.snake-tail {
  position: absolute;
  top: 5.7vw;
  left: 2.5vw;
  width: 2.5vw;
  height: 1.5vw;
  background-color: var(--snake-color);
  border-radius: 2.5vw 2.5vw 0 0;
}

.snake-head {
  position: absolute;
  width: 8vw;
  top: 1.9vw;
  right: -0.2vw;
}

.snake-title {
  position: absolute;
  font-size: 5vw;
  font-weight: 1000;
  z-index: 100;
  color: red;
  width: 30vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: rgb(77, 0, 0) 0px 0px 0.5vw;
  padding: 0 3vw 0 0;
}

.games-widget.snake {
  background-color: var(--screen-color);
}

.snake-tongue {
  position: absolute;
  top: 7.3vw;
  right: 3.3vw;
  height: 2vw;
  width: 0.5vw;
  background-color: red;
  z-index: 100;
  transform: rotate(-10deg);
}

.snake-tongue-tip {
  position: absolute;
  bottom: -1vw;
  left: -0.1vw;
  z-index: 1000;
  border-top: 1.5vw solid red;
  border-left: 0.2vw solid transparent;
  border-right: 0.2vw solid transparent;
  transform: rotate(10deg);
}

.snake-tongue-tip-right {
  position: absolute;
  bottom: -1vw;
  right: -0.1vw;
  z-index: 1000;
  border-top: 1.5vw solid red;
  border-left: 0.2vw solid transparent;
  border-right: 0.2vw solid transparent;
  transform: rotate(-10deg);
}

.tetris_widget_background {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.space-image {
  position: absolute;
  width: 30vw;
  height: 20vw;
  z-index: 0;
}

.tetris_letter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: 7vw;
  width: 4.2vw;
}

.tetris_letter .pixel {
  width: 1.4vw;
  height: 1.4vw;
  z-index: 10;
}

.tetris_letter.t .pixel.filled {
  background-color: rgb(0, 240, 240);
  border-top: 0.4vw solid rgb(96, 246, 246);
  border-left: 0.4vw solid rgb(49, 243, 243);
  border-bottom: 0.4vw solid rgb(0, 123, 123);
  border-right: 0.4vw solid rgb(0, 167, 167);
}

.tetris_letter.e .pixel.filled {
  background-color: rgb(0, 240, 0);
  border-top: 0.4vw solid rgb(96, 246, 96);
  border-left: 0.4vw solid rgb(49, 243, 49);
  border-bottom: 0.4vw solid rgb(0, 123, 0);
  border-right: 0.4vw solid rgb(0, 167, 0);
}

.tetris_letter.t2 .pixel.filled {
  background-color: rgb(160, 0, 240);
  border-top: 0.4vw solid rgb(196, 96, 246);
  border-left: 0.4vw solid rgb(178, 49, 243);
  border-bottom: 0.4vw solid rgb(82, 0, 123);
  border-right: 0.4vw solid rgb(111, 0, 167);
}

.tetris_letter.r .pixel.filled {
  background-color: rgb(240, 0, 0);
  border-top: 0.4vw solid rgb(246, 96, 96);
  border-left: 0.4vw solid rgb(243, 49, 49);
  border-bottom: 0.4vw solid rgb(123, 0, 0);
  border-right: 0.4vw solid rgb(167, 0, 0);
}

.tetris_letter.i .pixel.filled {
  background-color: rgb(240, 240, 0);
  border-top: 0.4vw solid rgb(246, 246, 96);
  border-left: 0.4vw solid rgb(243, 243, 49);
  border-bottom: 0.4vw solid rgb(123, 123, 0);
  border-right: 0.4vw solid rgb(167, 167, 0);
}

.tetris_letter.s .pixel.filled {
  background-color: rgb(0, 0, 240);
  border-top: 0.4vw solid rgb(96, 96, 246);
  border-left: 0.4vw solid rgb(49, 49, 243);
  border-bottom: 0.4vw solid rgb(0, 0, 123);
  border-right: 0.4vw solid rgb(0, 0, 167);
}

.ttfe_widget {
  height: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transform: scale(1);
}

.ttfe_row {
  width: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ttfe_widget_tile {
  width: 4.5vw;
  height: 4.5vw;
  background-color: #eee4da;
  border-radius: 0.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
  font-weight: 600;
  color: rgb(90, 86, 86);
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: rgb(54, 54, 54, 0.5) 0px 0px 1vw;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0.5vw;
}

.ttfe_title {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8vw;
  background-color: rgb(0, 0, 0, 0.2);
}

.ttfe_title_tile {
  width: 6.5vw;
  height: 6.5vw;
  background-color: green;
  border-radius: 1vw;
  background-color: #edc22e;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 0px 1vw rgb(0, 0, 0, 0.5);
  border: 0.2vw solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  font-weight: 600;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1vw;
  z-index: 10;
}

.ttfe_widget_tile.tile2 {
  background-color: #eee4da;
}

.ttfe_widget_tile.tile4 {
  background-color: #ede0c8;
}

.ttfe_widget_tile.tile8 {
  color: white;
  background-color: #f2b179;
}

.ttfe_widget_tile.tile16 {
  color: white;
  background-color: #f59563;
}

.ttfe_widget_tile.tile32 {
  color: white;
  background-color: #f67c5f;
}

.ttfe_widget_tile.tile64 {
  color: white;
  background-color: #f63e3b;
}

.ttfe_widget_tile.tile128 {
  color: white;
  background-color: #edcf72;
  font-size: 2.2vw;
}

.ttfe_widget_tile.tile256 {
  color: white;
  background-color: #edcc61;
  font-size: 2.2vw;
}

.ttfe_widget_tile.tile512 {
  color: white;
  background-color: #edc850;
  font-size: 2.2vw;
}

.ttfe_widget_tile.tile1024 {
  color: white;
  background-color: #edc53f;
  font-size: 1.8vw;
}

.ttfe_widget_tile.tile2048 {
  color: white;
  background-color: #edc22e;
  font-size: 2.4vw;
}

.tileSuper {
  color: white;
  background-color: darkblue;
  font-size: 2.1vw;
}

.games-widget.ttfe {
  background-color: rgb(90, 86, 86);
}

@media only screen and (max-width: 600px) {
  


  .games-widget {
    transform: scale(3.1);
    border-radius: 1vw;

  }

  .games-grid {
    display: flex;
    flex-direction: column;
    will-change: transform;

    gap: 3vw;
  }

  .games-widget-outer {
    position: relative;
    width: 100vw;
    height: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}
