.thirty-seconds-outer {
    min-height: 100vh;
    height: 48vw;
    position: relative;
    overflow: hidden;
}

.kies-kaartje-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.thirty-seconds-zandloper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18vw;
    margin-top: -3vw;
    transform: scale(0.5);
}

.kaartje-row {
    position: absolute;
    margin-top: 2vw;
    bottom: 3vw;
    width: 100%;
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .thirty-seconds-outer {
        height: 150vw;
    }
    .kies-kaartje-row{
        width: 100%;
        height: 30vw;
        display: flex;
        align-items: center;
    }
    .kaartje-row {
        height: 45vw;
        margin-bottom: 10vw;
    }
        .thirty-seconds-zandloper{
            transform: scale(1.5);
            margin-top: 25vw;
        }
}