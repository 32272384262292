/* * {
    outline: 1px solid green ;
} */

.music-quiz {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spotify-login-button {
  padding: 10px 25px;
  text-decoration: none;
  color: white;
  background-color: rgb(29, 185, 84);
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
  border-radius: 25px;
}

.test {
  background-color: #ffffff;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.playlist-loading-indicator {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid rgba(168, 145, 66, 0.1);
  border-top: 2px solid rgb(168, 145, 66);
  animation: 1s linear infinite spin-animation;
}

.playlist-outer {
  width: 300px;
  height: 300px;
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  border-radius: 20px;
}

.playlist-list {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.playlist-image-outer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playlist-image {
  width: 200px;
  height: 200px;
}

.playlist-title {
  width: 100%;
  color: white;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.back-to-playlists {
  width: 100px;
  height: 200px;
  color: white;
  font-size: 30px;
}

.playlist-button {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.error-modal {
  width: min(350px, 95vw);
  height: fit-content;
  color: white;
  background-color: black;
  border: 1px solid red;
  padding: 10px 20px 30px 20px;
  border-radius: 5px;
  font-size: 20px;
}

.error-modal-title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  color: red;
  margin-bottom: 20px;
  font-weight: 600;
}

.player-edit-button {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px solid white;
}

.player-edit-button.edit {
  background-color: orange;

  font-size: 15px;
}
.player-edit-button.delete {
  background-color: red;
  font-size: 20px;
}

.player-edit-button.save {
  width: 35px !important;
  height: 35px !important;
  background-color: rgb(29, 185, 84);
  border: 2px solid white;
  font-size: 20px;
}

.expire-modal {
  width: 350px;
  font-size: 20px;
  color: white;
  border: 1px solid red;
  padding: 0 20px 30px 20px;
}

.expire-modal-title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: red;
  padding: 20px 0;
}

.expire-modal-buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.expire-modal-button {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 0;
  border: 2px solid white;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 600;
}

.expire-modal-button.relogin {
  background-color: rgb(29, 185, 84);
}

@media only screen and (max-width: 400px) {
  .music-quiz {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spotify-login-button {
    padding: 2vw 5vw;
    text-decoration: none;
    color: white;
    background-color: rgb(29, 185, 84);
    display: flex;
    flex-direction: row;
    gap: 4vw;
    justify-content: center;
    align-items: center;
    font-size: 8vw;
    font-weight: 600;
    border-radius: 5vw;
  }

  .test {
    background-color: #ffffff;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .playlist-loading-indicator {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid rgba(168, 145, 66, 0.1);
    border-top: 2px solid rgb(168, 145, 66);
    animation: 1s linear infinite spin-animation;
  }

  .playlist-outer {
    width: 100%;
    height: fit-content;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    color: white;
    padding: 5vw;
    border-radius: 5vw;
  }

  .playlist-list {
    width: 100vw;
    padding: 5vw 5vw;
    display: flex;
    flex-direction: column;
    gap: 5vw;
  }

  .playlist-image-outer {
    width: 100%;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .playlist-image {
    width: 70vw;
    height: 70vw;
  }

  .playlist-title {
    width: 100%;
    color: white;
    font-size: 8vw;
    font-weight: 600;
    text-align: center;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2.5vw;
  }

  .back-to-playlists {
    width: 100px;
    height: 200px;
    color: white;
    font-size: 30px;
  }

  .playlist-button {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }

  .error-modal {
    width: min(350px, 95vw);
    height: fit-content;
    color: white;
    background-color: black;
    border: 1px solid red;
    padding: 3vw 6vw 9vw 6vw;
    border-radius: 1vw;
    font-size: 6.5vw;
  }

  .error-modal-title {
    width: 100%;
    text-align: center;
    font-size: 8vw;
    color: red;
    margin-bottom: 5vw;
    font-weight: 700;
  }

  .player-edit-buttons {
    position: absolute;
    right: 0;
    width: 17.5vw;
    height: 16vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .player-edit-button {
    height: 5.5vw;
    width: 5.5vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 1px solid white;
  }

  .player-edit-button.edit {
    background-color: orange;

    font-size: 3.5vw;
  }
  .player-edit-button.delete {
    background-color: red;
    font-size: 5vw;
  }

  .player-edit-button.save {
    width: 8vw !important;
    height: 8vw !important;
    background-color: rgb(29, 185, 84);
    border: 2px solid white;
    font-size: 5vw;
  }
}
