*{
      --font: "Noto Serif";
}

@keyframes slide-in {
  0% {
    margin-bottom: 200vw;
  }
  100% {
    margin-bottom: 0vw;
  }
}

.home_screen_outer {
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--top-bar-height));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home_screen_abs {
  position: absolute;
  width: 100vw;
  height: calc(100vh - var(--top-bar-height));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow-y: hidden;

}


.home_screen_qr_code_outer {
  position: absolute;
  z-index: 2;
  width: 35vw;
  height: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5vw;
  color: white;
  font-family: "Roboto";
  font-size: 2vw;
  font-weight: 300;
  background-color: var(--main-background-color);
}

.buttons-helper {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  justify-content: center;
}
.home_screen_qr_pic {
  width: 10vw;
  height: 10vw;
}

.home_screen_card_outer {
  position: relative;
  width: 35.3vw;
  height: 20.3vw;
  border-radius: 1vw;
  animation: slide-in 2s;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 0px 1vw var(--main-color-op3);
  background-color: var(--main-color-op2);
}

.home_screen_card_inner {
  position: absolute;
  top: 0.15vw;
  left: 0.15vw;
  width: 35vw;
  height: 20vw;
  border-radius: 1vw;
  background-color: var(--background-color);
  background: repeating-linear-gradient(
    -135deg,
    var(--background-color),
    var(--background-color) 0.3vw,
    var(--main-background-color),
    var(--main-background-color) 0.4vw
  );
  animation: slide-in 2s;
  z-index: 2;
  border: 0.1vw solid  var(--main-border-color);
}

.home_screen_titles {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--main-color);
  font-family: var(--font);
  top: 1.5vw;
  left: 3vw;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
}

.home_screen_name {
  font-size: 3vw;
  font-weight: 400;
}

.home_screen_jobtitle {
  margin-top: 0vw;
  font-size: 1.5vw;
  margin-left: 0.1vw;
}
.home_screen_jobtitle.small {
  font-size: 1.2vw;
  margin-top: 0vw;
}

.home_screen_signature {
  position: absolute;
  width: 10vw;
  bottom: 1vw;
  right: -1vw;
  opacity: 0.75;
}

.home_screen_contacts {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--main-color);
  bottom: 2vw;
  left: 3vw;
  font-size: 1.3vw;
  text-shadow:   var(--text-shadow-color) 0px 0px 0.1vw;
  font-family: var(--font);
  height: fit-content;
}

.home_screen_contact {
  color: var(--main-color);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1vw;
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(-60deg);
  }

  25% {
    transform: rotate(60deg);
  }

  50% {
    transform: rotate(120deg);
  }

  75% {
    transform: rotate(240deg);
  }

  100% {
    transform: rotate(300deg);
  }
}

@keyframes slidediebitchup {
  0% {
    top: 0vw;
  }

  90% {
   top: -9vw;
   transform: scale(1);
  }
  95% {
      top: -9vw;
    transform: scale(1.1);
  }
  100% {
      top: -9vw;
    transform: scale(1);
  }
}

@keyframes slidediebitchdown {
  0% {
    bottom: 0vw;
  }

  90% {
    bottom: -9vw;
    transform: scale(1);
  }

  95% {
    bottom: -9vw;
    transform: scale(1.1);
  }

  100% {
    bottom: -9vw;
    transform: scale(1);
  }
}
.home_screen_button {
  position: absolute;
  background: repeating-linear-gradient(
    -135deg,
    var(--background-color),
    var(--background-color) 0.3vw,
    var(--main-background-color),
    var(--main-background-color) 0.4vw
  );
  padding: 0 2vw;
  height: 3.5vw;
  color: white;
  z-index: 100;
  color: var(--main-color);
  border-radius: 2vw;
  border: 0.1vw solid  var(--main-border-color);
  box-shadow: 0px 0px 1vw var(--main-color-op3);
  font-family: "Roboto";
  font-weight: 400;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  z-index: 0;
  font-size: 1.8vw;
}

.test-track {
  width: 20vw;
}
.switch-icon {
  font-size: 1vw;
  height: 1.3vw;
  width: 1.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
}

.home_screen_socials {
  position: absolute;
  width: 30vw;
  height: 5vw;
  animation: slidediebitchdown 2s linear forwards;
  animation-delay: 1.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}


.home_screen_socials_button {
  height: 4vw;
  width: 4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5vw;
  box-shadow: 0px 0px 0.5vw var(--main-border-color);
  cursor: pointer;
}

.home_screen_socials_button.facebook {
  background: linear-gradient(135deg, #3a5795, #2b4886, #3a5795);

}
.home_screen_socials_button.insta {
  background: linear-gradient(
    135deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.home_screen_socials_button.whatsapp {
  background: linear-gradient(135deg, #1ebea5, #00e676, #1ebea5);
}

.home_screen_socials_button.linkedin {
  background: linear-gradient(135deg, #0077b5, #00a0dc, #0077b5);
}

.home_screen_button:hover {
  border: 0.1vw solid var(--main-color);
  box-shadow: 0px 0px 0.5vw var(--main-border-color);
    cursor: pointer;
}

.home_screen_button.games {
  left: 0;
  animation:  slidediebitchup 2s linear forwards;
  animation-delay: 1.5s;
}

.home_screen_button.tools {
  right: 0;
  animation: slidediebitchup 2s linear forwards;
  animation-delay: 1.5s;
}

.home_screen_button.socials {
  animation: slidediebitchdown 2s linear forwards;
  left: 0;
  animation-delay: 1.5s;
}
.home_screen_button.piano_xx {
  animation: slidediebitchdown 2s linear forwards;
  right: 0;
  animation-delay: 1.5s;
}

.home_screen_icon {
  /* font-size: 1.9vw; */
  filter: drop-shadow(0px 0px 0.1vw var(--icon-shadow-color));
}
.card_spinner {
  position: absolute;
  height: 30vw;
  width: 5vw;
  transform-origin: bottom left;
  left: 15.5vw;
  top: -21.2vw;
  z-index: 1;
  animation: rotate-spinner 4s linear infinite;
  background-image: linear-gradient(
    90deg,
    var(--main-border-color),
    var(--main-color)
  );
}



@media only screen and (max-width: 600px) {

.home_screen_card_inner {
  border-width: 0.01vw;
}

.home_screen_button {
  border-width: 0.01vw;
}
  .home_screen_outer {

    height: calc(100vh - var(--top-bar-height));

  }
  
  .home_screen_abs {
    height: calc(100vh - var(--top-bar-height));

  }
  .home_screen_card_outer {
    transform: scale(2.7);
  }

  .buttons-helper {
    transform: scale(2.7);
  }

  .home_screen_qr_code_outer {
    transform: scale(2.7);
  }
}