.ttfe_outer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  flex-direction: column;
}

.ttfe_grid {
  position: relative;
  width: 30vw;
  height: 30vw;
  background-color: rgb(90, 86, 86);
  border-radius: 1vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1vw;
  padding: 1vw;
}

.ttfe_grid .ttfe_tile_temp {
  background-color: rgb(104, 104, 104);
  border-radius: 0.5vw;
}

.test-button {
  height: 2vw;
  width: 2vw;
  background-color: red;
}

.tiles-container {
  position: absolute;
  padding: 1vw;
}

.ttfe_tile {
  --x: 3;
  --y: 1;
  --cell-gap: 1vw;
  height: 6.25vw;
  width: 6.25vw;
  background-color: red;
  z-index: 10;
  position: absolute;
  left: calc((var(--x) - 1) * 6.25vw + var(--x) * var(--cell-gap));
  top: calc((var(--y) - 1) * 6.25vw + var(--y) * var(--cell-gap));
  transition: 100ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2vw;
  font-weight: 600;
  animation: appear 200ms;
  color: rgb(90, 86, 86);
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0.5vw;
  box-shadow: rgb(54, 54, 54, 0.5) 0px 0px 1vw;
}

.ttfe_tile.no_animation {
  animation: none;
}

.undo_bar {
  width: 30vw;
  height: 4vw;
  display: flex;
        flex-direction: row-reverse;
  align-items: center;
}

.undo_button {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.8vw;
  font-size: 1.5vw;
  align-items: center;
  justify-content: center;
  background-color: rgb(54, 53, 53);
  padding: 0.2vw 1vw;
  border-radius: 0.7vw;
  color: rgba(218, 209, 209, 0.6);
  border: 0.2vw solid rgb(90, 86, 86);
  font-weight: 700;
  width: 10vw;
}

.undos_left_indicator {
  position: absolute;
  padding: 0 0 0.2vw 0;
  right: -0.75vw;
  top: -0.75vw;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  background-color: rgb(0, 207, 0);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9vw;
  box-shadow: 0px 0px 0.4vw rgba(0, 0, 0, 0.3);
}

.undos_left_indicator.medium {
  background-color: orange;
}

.undos_left_indicator.empty {
  background-color: red;
}

.undo_button.inactive {
  background-color: rgba(54, 53, 53, 0.2);
}
.undo_icon {
  font-size: 1.1vw;
  margin-top: 0.2vw;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  80% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ttfe_tile.tile2 {
  background-color: #eee4da;
}
.ttfe_tile.tile4 {
  background-color: #ede0c8;
}
.ttfe_tile.tile8 {
  color: white;
  background-color: #f2b179;
}
.ttfe_tile.tile16 {
  color: white;
  background-color: #f59563;
}
.ttfe_tile.tile32 {
  color: white;
  background-color: #f67c5f;
}
.ttfe_tile.tile64 {
  color: white;
  background-color: #f63e3b;
}
.ttfe_tile.tile128 {
  color: white;
  background-color: #edcf72;
  font-size: 3vw;
}
.ttfe_tile.tile256 {
  color: white;
  background-color: #edcc61;
  font-size: 3vw;
}
.ttfe_tile.tile512 {
  color: white;
  background-color: #edc850;
  font-size: 3vw;
}
.ttfe_tile.tile1024 {
  color: white;
  background-color: #edc53f;
  font-size: 2.4vw;
}
.ttfe_tile.tile2048 {
  color: white;
  background-color: #edc22e;
  font-size: 2.4vw;
}

.tileSuper {
  color: white;
  background-color: darkblue;
  font-size: 2.1vw;
}

@keyframes gameover-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ttfe_gameover_screen {
  position: absolute;
  width: 30vw;
  height: 30vw;
  background-color: rgba(218, 209, 209, 0.6);
  z-index: 100;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.2vw;
  color: rgb(54, 53, 53);
  font-weight: 1000;
  font-family: Arial, Helvetica, sans-serif;
  animation: gameover-appear 2s;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
}

.ttfe_try_again {
  color: rgb(54, 53, 53);
  font-size: 1.5vw;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5vw 1vw;
  border-radius: 2vw;
  gap: 1vw;
  border: 0.2vw solid rgb(54, 53, 53);
  margin-top: 1vw;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 1vw;
}
.ttfe_score_bar {
  width: 30vw;
  height: 6vw;
  gap: 0.5vw;
  display: flex;
  flex-direction: row-reverse;
}

.ttfe_score_title {
  color: rgb(90, 86, 86);
  font-size: 1.2vw;
  font-weight: 700;
  margin-bottom: 0.2vw;
}

.ttfe_score_value {
  font-size: 2vw;
  font-weight: 800;
  color: white;
}
.ttfe_score_display {
  position: relative;
  height: 6vw;
  width: 10vw;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgb(54, 53, 53);
  border-radius: 1vw;
  border: 0.2vw solid rgb(90, 86, 86);
}

@keyframes pop-up {
  0% {
    top: 1vw;
    opacity: 1;
  }
  90% {
    top: 4vw;
  }
  100% {
    opacity: 0;
  }
}

.ttfe_score_popup {
  position: absolute;
  top: 1vw;
  left: 0;
  opacity: 0;
  font-size: 2vw;
  color: rgb(90, 86, 86);
  font-weight: 1000;
  animation: 1.5s pop-up;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
}

.ttfe_reached_screen {
  position: absolute;
  width: 30vw;
  height: 30vw;
  background-color: rgba(218, 209, 209, 0.6);
  z-index: 100;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  color: rgb(54, 53, 53);
  font-weight: 1000;
  font-family: Arial, Helvetica, sans-serif;
  animation: gameover-appear 2s;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
}

.ttfe_reached_subtext {
  color: rgb(54, 53, 53);
  font-size: 1.5vw;
  text-align: center;
}

.ttfe_reached_button {
  color: white;
  background-color: rgb(54, 53, 53);
  font-size: 1.5vw;
  width: 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
  border-radius: 1vw;
  margin: 1vw;
  border: 0.2vw solid rgb(90, 86, 86);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 1vw;
}
.ttfe_choice_screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(36, 36, 36);
}

.ttfe_choice_title {
  font-size: 2vw;
  font-weight: 700;
  margin: 1vw;
}

.ttfe_choice_explanation {
  margin: 1vw;
  font-size: 1.5vw;
  text-align: center;
}
.ttfe_game_choice_option {
  width: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vw;
  background-color: red;
  border-radius: 1vw;
  border: 0.2vw solid rgb(90, 86, 86);
  color: white;
  font-weight: 700;
  font-size: 2.5vw;
  box-shadow: 0px 0px 1vw rgba(0, 0, 0, 0.6);
  text-shadow: black 0.15vw 0.15vw;
  padding: 0 0 0.5vw 0;
}

.ttfe_game_choice_option.regular {
  background-color: orange;
}

.ttfe_game_choice_option.unlimited {
  background-color: rgb(0, 207, 0);
}
@media only screen and (max-width: 600px) {
  .ttfe_outer {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
  }

  .ttfe_grid {
    position: relative;
    width: 98vw;
    height: 98vw;
    background-color: rgb(90, 86, 86);
    border-radius: 2vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 2.5vw;
    padding: 2.5vw;
  }

  .ttfe_grid .ttfe_tile_temp {
    background-color: rgb(104, 104, 104);
    border-radius: 1vw;
  }

  .test-button {
    height: 2vw;
    width: 2vw;
    background-color: red;
  }

  .tiles-container {
    position: absolute;
    padding: 2.5vw;
  }

  .ttfe_tile {
    --x: 3;
    --y: 1;
    --cell-gap: 2vw;
    height: 22vw;
    width: 22vw;
    background-color: red;
    z-index: 10;
    position: absolute;
    left: calc((var(--x) - 1) * 22vw + var(--x) * var(--cell-gap));
    top: calc((var(--y) - 1) * 22vw + var(--y) * var(--cell-gap));
    transition: 100ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vw;
    font-weight: 600;
    animation: appear 200ms;
    color: rgb(90, 86, 86);
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 1.5vw;
    box-shadow: rgb(54, 54, 54, 0.5) 0px 0px 2vw;
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    80% {
      opacity: 1;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .ttfe_tile.tile2 {
    background-color: #eee4da;
  }

  .ttfe_tile.tile4 {
    background-color: #ede0c8;
  }

  .ttfe_tile.tile8 {
    color: white;
    background-color: #f2b179;
  }

  .ttfe_tile.tile16 {
    color: white;
    background-color: #f59563;
  }

  .ttfe_tile.tile32 {
    color: white;
    background-color: #f67c5f;
  }

  .ttfe_tile.tile64 {
    color: white;
    background-color: #f63e3b;
  }

  .ttfe_tile.tile128 {
    color: white;
    background-color: #edcf72;
    font-size: 9vw;
  }

  .ttfe_tile.tile256 {
    color: white;
    background-color: #edcc61;
    font-size: 9vw;
  }

  .ttfe_tile.tile512 {
    color: white;
    background-color: #edc850;
    font-size: 9vw;
  }

  .ttfe_tile.tile1024 {
    color: white;
    background-color: #edc53f;
    font-size: 7.5vw;
  }

  .ttfe_tile.tile2048 {
    color: white;
    background-color: #edc22e;
    font-size: 7.5vw;
  }

  .tileSuper {
    color: white;
    background-color: darkblue;
    font-size: 7vw;
  }
  .ttfe_gameover_screen {
    position: absolute;
    width: 98vw;
    height: 98vw;
    background-color: rgba(218, 209, 209, 0.6);
    z-index: 100;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14vw;
    color: rgb(54, 53, 53);
    font-weight: 1000;
    font-family: Arial, Helvetica, sans-serif;
    animation: gameover-appear 2s;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2vw;
  }

  .ttfe_try_again {
    color: rgb(54, 53, 53);
    font-size: 5vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5vw 3vw;
    border-radius: 4.5vw;
    gap: 3vw;
    border: 0.2vw solid rgb(54, 53, 53);
    margin-top: 1vw;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2.5vw;
  }

  .ttfe_score_bar {
    width: 98vw;
    height: 20vw;

    margin-bottom: 1vw;
  }

  .ttfe_score_title {
    color: rgb(90, 86, 86);
    font-size: 4vw;
    font-weight: 700;
    margin-bottom: 0.6vw;
  }

  .ttfe_score_value {
    font-size: 7vw;
    font-weight: 800;
    color: white;
  }

  .ttfe_score_display {
    height: 20vw;
    width: 40vw;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgb(54, 53, 53);
    border-radius: 2vw;
    border: 1vw solid rgb(90, 86, 86);
  }

  @keyframes pop-up {
    0% {
      top: 4vw;
      opacity: 1;
    }

    90% {
      top: 10vw;
    }

    100% {
      opacity: 0;
    }
  }

  .ttfe_score_popup {
    position: absolute;
    top: 1vw;
    left: 0;
    opacity: 0;
    font-size: 6vw;
    color: rgb(90, 86, 86);
    font-weight: 1000;
    animation: 1.5s pop-up;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
  }

  .ttfe_reached_screen {
    position: absolute;
    width: 98vw;
    height: 98vw;
    background-color: rgba(218, 209, 209, 0.6);
    z-index: 100;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9.5vw;
    color: rgb(54, 53, 53);
    font-weight: 1000;
    font-family: Arial, Helvetica, sans-serif;
    animation: gameover-appear 2s;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2vw;
  }

  .ttfe_reached_subtext {
    color: rgb(54, 53, 53);
    font-size: 5vw;
    text-align: center;
  }

  .ttfe_reached_button {
    color: white;
    background-color: rgb(54, 53, 53);
    font-size: 4vw;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    border-radius: 3vw;
    margin: 1vw;
    border: 0.6vw solid rgb(90, 86, 86);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2vw;
  }

  .ttfe_reached_button {
    color: white;
    background-color: rgb(54, 53, 53);
    font-size: 1.5vw;
    width: 16vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    border-radius: 1vw;
    margin: 1vw;
    border: 0.2vw solid rgb(90, 86, 86);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 1vw;
  }

  .ttfe_choice_screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(36, 36, 36);
  }

  .ttfe_choice_title {
    font-size: 7vw;
    font-weight: 700;
    margin: 5vw;
    text-align: center;
  }

  .ttfe_choice_explanation {
    margin: 3vw;
    font-size: 4vw;
    text-align: center;
  }

  .ttfe_game_choice_option {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    background-color: red;
    border-radius: 3vw;
    border: 0.5vw solid rgb(90, 86, 86);
    color: white;
    font-weight: 700;
    font-size: 7vw;
    box-shadow: 0px 0px 3vw rgba(0, 0, 0, 0.3);
    text-shadow: black 0.4vw 0.4vw;
    padding: 0 0 1vw 0;
  }

  .ttfe_game_choice_option.regular {
    background-color: orange;
  }

  .ttfe_game_choice_option.unlimited {
    background-color: rgb(0, 207, 0);
  }

    .undo_bar {
      width: 97vw;
      height: 12vw;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  
    .undo_button {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 3vw;
      font-size: 5vw;
      align-items: center;
      justify-content: center;
      background-color: rgb(54, 53, 53);
      padding: 0.2vw 1vw;
      border-radius: 1.5vw;
      color: rgba(218, 209, 209, 0.6);
      border: 0.6vw solid rgb(90, 86, 86);
      font-weight: 700;
      width: 32vw;
    }
  
    .undos_left_indicator {
      position: absolute;
      padding: 0 0 0.5vw 0;
      right: -2vw;
      top: -2vw;
      height: 4vw;
      width: 4vw;
      border-radius: 50%;
      background-color: rgb(0, 207, 0);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5vw;
      box-shadow: 0px 0px 1vw rgba(0, 0, 0, 0.3);
    }
  
    .undos_left_indicator.medium {
      background-color: orange;
    }
  
    .undos_left_indicator.empty {
      background-color: red;
    }
  
    .undo_button.inactive {
      background-color: rgba(54, 53, 53, 0.2);
    }
  
    .undo_icon {
      font-size: 3vw;
      margin-top: 0.2vw;
    }
}
