.rain-container {
    --container-width: 15.5vw;
    position: absolute;
    width: var(--container-width);
    height: 3vw;
    overflow: hidden;
}


.raining_operator {
    position: absolute;
    --starting-x: 0.2;
    --delay: 0.5s;
    --size: 2;
    --speed: 2000ms;
    font-size: calc(var(--size) * 1vw);
    color: var(--main-color);
    top: -10vw;
    left: calc(var(--starting-x) * 20vw);
    animation: fall-down var(--speed) linear;
    animation-delay: var(--delay);
    z-index: 1000000;
}

@keyframes fall-down {
    0% {
        transform: translateY(-10vw);
    }

    100% {
        transform: translateY(20vw);
    }
}

@media only screen and (max-width: 600px) {
    .rain-container {
        --container-width: 50vw;
        position: absolute;
        width: var(--container-width);
        height: 10vw;
        overflow: hidden;
    }
    
    
    .raining_operator {
        position: absolute;
        --starting-x: 0.2;
        --delay: 0.5s;
        --size: 2;
        --speed: 2000ms;
        font-size: calc(var(--size) * 2.5vw);
        top: -5vw;
        left: calc(var(--starting-x) * var(--container-width));
        animation: fall-down calc(0.7 * var(--speed)) linear;
        animation-delay: var(--delay);
        z-index: 1000000;
    }
    
}