.guitar_tuner_outer {
    display: flex;
    max-width: 100vw;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}

.start_tuning_button {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background: linear-gradient(rgb(27, 223, 27),rgb(27, 170, 27), rgb(27, 100, 27));
    border: 0.4vw solid rgb(15, 132, 15);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(15, 70, 15);
    font-size: 1.8vw;
    flex-direction: column;
    text-align: center;
    font-weight: 600;
    text-shadow: rgb(15, 255, 15, 0.3) 1px 1px 1vw;
}

.guitar_tuning_icon {
    font-size: 2.5vw;
}

.stop_tuning_button {
    height: 6vw;
    width: 6vw;
    border-radius: 50%;
    background: linear-gradient(red, rgb(39, 0, 0));
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 33, 33);
    font-size: 1.2vw;
    border: 0.2vw solid rgb(141, 3, 3);
    text-align: center;
    font-weight: 600;
    padding: 0 0 0.2vw 0;
        text-shadow: black 0.1vw 0.1vw 0.2vw;
}



.note_tune_wheel {
    position: relative;
    width: 40.4vw;
    height: 20.4vw;
    border-radius: 20vw 20vw 0 0;
    background: linear-gradient(90deg,rgb(255, 19, 19),rgb(255, 161, 10) 43%, rgb(0, 255, 0) , orange 57%, red);
    overflow: hidden;
    border: 0.2vw solid white;
}

.note_tune_wheel_center {
    position: absolute;
    width: 2vw;
    height: 2vw;
    background-color: black;
    border: 0.2vw solid white;
    border-radius: 50%;
    bottom: -1vw;
    left: 19vw;
    z-index: 10;

}

.note_tune_wheel_arrow {
    --cents: 0;
    position: absolute;
    height: 17.5vw; 
    width: 0.2vw;
    background-color: rgba(255, 255, 255, 0.561);
    bottom: 0;
    left:19.9vw;
    transform-origin: bottom center;
    transform: rotate(calc((var(--cents) / 50)* 90deg));
    transition: 200ms ease-in-out;
}

.note_tune_wheel_arrow_head {
    border-bottom: 1vw solid rgba(255,
        255,
        255, 1);
    position: absolute;
    top: -1vw;
    left: -0.40vw;
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
}
.note_tune_wheel_lines {
    --deg: 0;
    position: absolute;
    height: 20.4vw;
    width: 2vw;
    bottom: 0vw;
    left: 19vw;
    display: flex;
    justify-content: center;
    color: white;
    transform: rotate(calc((var(--deg) / 50)* 90deg));
    transform-origin: bottom center;
    z-index: 100;
    font-weight: 1000;
    font-size: 1vw;
}

.tune_direction {
        display: flex;
            gap: 1vw;
            font-size: 1vw;
            align-items: center;
            color: white;
            position: absolute;
            bottom: 0;
            right: 4vw;
            font-weight: 600;
}

.tune_direction.down {
    bottom: 0;
    right: 4vw;
}
.tune_direction.up {
    bottom: 0;
    left: 4vw;
}
.note_tune_wheel_lines.small {
    font-size: 0.6vw;
}

.note_tune_wheel_number {
    --cents: 0;
    position: absolute;
    height: 22vw;
    width: 2vw;
    bottom: 2vw;
    left: 21vw;
    display: flex;
    justify-content: center;
    color: white;
    transform: rotate(calc((var(--cents) / 50)* 90deg));
    transform-origin: bottom center;
    font-size: 1vw;
    font-weight: 500;
}

.note_tune_wheel_number.line {
    font-size: 0.7vw;
    padding-top: 0.2vw;
    font-weight: 1000;
}

.note_tune_wheel_outer {
    position: relative;
    width: 44vw;
    height: 24vw;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;
    border-radius: 22vw 22vw 0 0;
    
}

.current_note_display{
    position: relative;
    height: 6vw;
    width: 6vw;
    border-radius: 50%;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;   
    font-size: 3vw;
    z-index: 2;
    overflow: hidden;
    box-shadow: 0vw 0vw 1vw white;
    padding: 0 0 0.4vw 0;
    font-weight: 600;
    margin-bottom: 2vw;
}

.current_note_display_colors {
    --x: 0;
    position: absolute;
    left: calc(-46vw - var(--x) * 0.94vw);
    width: 100vw;
    height: 6vw;
    bottom: 0;
    background-color: green;
    z-index: -2;
        background: linear-gradient(90deg, red, orange 40%, rgb(0, 236, 0), orange 60%, red);
}

.current_note_decomp {
    display: flex;
    flex-direction: row;
    margin-left: 1vw;
}

.current_note_letter {
    width: 2.3vw;
}

.current_note_right_side {
    display: flex;
    flex-direction: column;
    width: 1.5vw;
    justify-content: space-between;
}

.current_note_sharp {
    height: 2vw;
    font-size: 2vw;
    margin-right: 0.5vw;
}

.current_note_octave {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 1vw;
    font-size: 1vw;
    margin-bottom: 0.3vw;
    padding: 0 0.1vw;
}

@media only screen and (max-width: 600px) {
.guitar_tuner_outer {
        display: flex;
        max-width: 100vw;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow-x: hidden;
    }

    .start_tuning_button {
        width: 25vw;
        height: 25vw;
        border-radius: 50%;
        background: linear-gradient(rgb(27, 223, 27), rgb(27, 170, 27), rgb(27, 100, 27));
        border: 1vw solid rgb(15, 132, 15);
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(15, 70, 15);
        font-size: 4vw;
        flex-direction: column;
        text-align: center;
        font-weight: 600;
        text-shadow: rgb(15, 255, 15, 0.3) 0.2vw 0.2vw 1vw;
    }

    .guitar_tuning_icon {
        font-size: 6vw;
    }

    .stop_tuning_button {
        height: 15vw;
        width: 15vw;
        border-radius: 50%;
        background: linear-gradient(red, rgb(39, 0, 0));
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 33, 33);
        font-size: 3vw;
        border: 0.5vw solid rgb(141, 3, 3);
        text-align: center;
        font-weight: 600;
        padding: 0 0 0.5vw 0;
        text-shadow: black 0.3vw 0.3vw 0.6vw;
    }



    .note_tune_wheel {
        position: relative;
        width: 94vw;
        height: 47vw;
        border-radius: 47vw 47vw 0 0;
        background: linear-gradient(90deg, rgb(255, 19, 19), rgb(255, 161, 10) 43%, rgb(0, 255, 0), orange 57%, red);
        overflow: hidden;
        border: 0.5vw solid white;
    }

    .note_tune_wheel_center {
        position: absolute;
        width: 4vw;
        height: 4vw;
        background-color: black;
        border: 0.5vw solid white;
        border-radius: 50%;
        bottom: -2vw;
        left: 44.5vw;
        z-index: 10;

    }

    .note_tune_wheel_arrow {
        --cents: 0;
        position: absolute;
        height: 39vw;
        width: 0.5vw;
        background-color: rgba(255, 255, 255, 0.561);
        bottom: 0;
        left: 46.25vw;
        transform-origin: bottom center;
        transform: rotate(calc((var(--cents) / 50)* 90deg));
        transition: 200ms ease-in-out;
    }

    .note_tune_wheel_arrow_head {
        border-bottom: 2vw solid rgba(255,
                255,
                255, 1);
        position: absolute;
        top: -2vw;
        left: -0.8vw;
        border-left: 1vw solid transparent;
        border-right: 1vw solid transparent;
    }

    .note_tune_wheel_lines {
        --deg: 0;
        position: absolute;
        height: 47vw;
        width: 2vw;
        bottom: 0vw;
        left: 45.5vw;
        display: flex;
        justify-content: center;
        color: white;
        transform: rotate(calc((var(--deg) / 50)* 90deg));
        transform-origin: bottom center;
        z-index: 100;
        font-weight: 1000;
        font-size: 3vw;
    }

    .tune_direction {
        display: flex;
        gap: 2vw;
        font-size: 3vw;
        align-items: center;
        color: white;
        position: absolute;
        right: 4vw;
        font-weight: 600;
    }

    .tune_direction.down {
        bottom: -3vw;
        right: 6vw;
    }

    .tune_direction.up {
        bottom: -3vw;
        left: 6vw;
    }

    .note_tune_wheel_lines.small {
        font-size: 1.5vw;
    }

    .note_tune_wheel_number {
        --cents: 0;
        position: absolute;
        height: 52vw;
        width: 2vw;
        bottom: 2vw;
        left: 49vw;
        display: flex;
        justify-content: center;
        color: white;
        transform: rotate(calc((var(--cents) / 50)* 90deg));
        transform-origin: bottom center;
        font-size: 3vw;
        font-weight: 500;
    }

    .note_tune_wheel_number.line {
        font-size: 0.7vw;
        padding-top: 0.2vw;
        font-weight: 1000;
    }

    .note_tune_wheel_outer {
        position: relative;
        width: 100vw;
        height: 50vw;
        display: flex;
        flex-direction: center;
        align-items: center;
        justify-content: center;
        border-radius: 50vw 50vw 0 0;
        margin-bottom: 4vw;

    }

    .current_note_display {
        position: relative;
        height: 15vw;
        width: 15vw;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6vw;
        z-index: 2;
        overflow: hidden;
        box-shadow: 0vw 0vw 2vw white;
        padding: 0 0 0.4vw 0;
        font-weight: 600;
        margin-bottom: 8vw;
    }

    .current_note_display_colors {
        --x: 0;
        position: absolute;
        left: calc(-117.5vw - var(--x) * 2.125vw);
        width: 250vw;
        height: 15vw;
        bottom: 0;
        background-color: green;
        z-index: -2;
        background: linear-gradient(90deg, red, orange 40%, rgb(0, 236, 0), orange 60%, red);
    }

    .current_note_decomp {
        display: flex;
        flex-direction: row;
        margin-left: 2.5vw;
    }

    .current_note_letter {
        width: 5vw;
    }

    .current_note_right_side {
        display: flex;
        flex-direction: column;
        width: 4vw;
        justify-content: space-between;
    }

    .current_note_sharp {
        height: 2vw;
        font-size: 4vw;
        margin-right: 0.5vw;
    }

    .current_note_octave {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 2vw;
        font-size: 2vw;
        margin-bottom: 0vw;
        padding: 0 0.1vw;
    }
}