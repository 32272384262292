.quiz-master-screen {
  width: 400px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.quiz-master-screen-inner {
  width: 400px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.quiz-master-screen-name {
  font-size: 60px;
  font-weight: 800;
  color: rgb(29, 185, 84);
}

.quiz-master-screen-smaller-text {
  margin-top: 20px;
  font-size: 30px;
}

@media only screen and (max-width: 400px) {
  .quiz-master-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 10vw;
    color: white;
    font-weight: 600;
  }

  .quiz-master-screen-inner {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10vw;
    color: white;
    font-weight: 600;
  }

  .quiz-master-screen-name {
    font-size: 15vw;
    font-weight: 800;
    color: rgb(29, 185, 84);
  }

  .quiz-master-screen-smaller-text {
    margin-top: 5vw;
    font-size: 7.5vw;
  }
}
