.modal-outer {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 3;
    background-color: rgba(0,0,0,0.6);
}

.modal {
    position: fixed;
    width: fit-content;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    overflow:hidden;
    background-color: black;
}

.modal-close {
    position: fixed;
    width: fit-content;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    overflow:hidden;
    background-color: black;
}
