.zandloper-outer {
  position: relative;
  width: 22vw;
  height: 32vw;
  overflow: hidden;
  --zand-kleur: #dbb48f;
  --zandloper-width: 20vw;
  --zandloper-height: 30vw;
}
.zandloper-frame.top {
  position: absolute;
  width: 22vw;
  height: 1.02vw;
  background-color: rgb(146, 63, 25);
  border: 0.2vw solid rgb(139, 58, 20);
  border-right: 0.2vw solid rgb(163, 71, 29);
  border-bottom: 0.2vw solid rgb(163, 71, 29);
  border-radius: 0.25vw;
  z-index: 10;
}

.zandloper-frame.bottom {
  position: absolute;
  bottom: 0;
  width: 22vw;
  height: 1.02vw;
  background-color: rgb(146, 63, 25);
  border: 0.2vw solid rgb(139, 58, 20);
  border-right: 0.2vw solid rgb(163, 71, 29);
  border-bottom: 0.2vw solid rgb(163, 71, 29);
  border-radius: 0.25vw;
  z-index: 10;
}
.zandloper-top {
  position: absolute;
  left: 50%;
  top: 1vw;
  transform: translateX(-50%);
  width: var(--zandloper-width);
  height: calc(var(--zandloper-height) / 2);
  clip-path: polygon(47% 100%, 53% 100%, 100% 0, 0 0);
  background: linear-gradient(
    90deg,
    rgb(150, 148, 148) 0%,
    rgb(29, 28, 28) 50%,
    rgb(150, 148, 148) 100%
  );
}

.zandloper-top-zand {
  transform: translateY(15vw);
  height: 20vw;
  width: 20vw;
  background-color: var(--zand-kleur);
  animation: 30s linear lower-sand forwards;
  overflow: hidden;
}

@keyframes lower-sand {
  0% {
    transform: translateY(1vw);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(8.5vw);
    animation-timing-function: linear;
  }
  99.6% {
    transform: translateY(15vw);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(15vw);
    animation-timing-function: ease-out;
  }
}

.zandloper-bottom {
  position: absolute;
  bottom: 1.01vw;
  left: 50%;
  transform: translateX(-50%);
  width: var(--zandloper-width);
  height: calc(var(--zandloper-height) / 2);
  clip-path: polygon(47% 0, 53% 0, 100% 100%, 0 100%);
  background: linear-gradient(
    90deg,
    rgb(150, 148, 148) 0%,
    rgb(29, 28, 28) 50%,
    rgb(150, 148, 148) 100%
  );
}

.zandloper-bottom-zand {
  position: absolute;
  overflow: hidden;
  transform: translateY(2vw);
  height: 30vw;
  left: -10vw;
  width: 40vw;
  border-radius: 50%;
  animation: 30s raise-sand forwards;
  background-color: var(--zand-kleur);
}
.sand-blur {
  height: 100%;
  width: 100%;
  background: repeating-conic-gradient(
      #0003 0.000001%,
      #fff0 0.00005%,
      #fff0 0.00035%,
      #fff0 0.00005%
    ),
    repeating-conic-gradient(
      #fff2 0.00002%,
      #fff0 0.00008%,
      #fff0 0.0008%,
      #fff0 0.00008%
    );
  opacity: 0.9;
  filter: blur(1px);
}

@keyframes raise-sand {
  0% {
    transform: translateY(15vw);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(8vw);
    animation-timing-function: linear;
  }
  100% {
    transform: translateY(2vw);
    animation-timing-function: ease-out;
  }
}

.zandloper-lopend-zand {
  height: 100%;
  width: 1.2vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 5vw);
  background-color: var(--zand-kleur);
  animation: 30s linear running-sand forwards;
}

.no-animation {
  animation: none;
}

@keyframes running-sand {
  0% {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transform: translate(-50%, -15vw);
  }

        2% {
                border-bottom-left-radius: 50%;
                    border-bottom-right-radius: 50%;
            border-radius: 0%;
            transform: translate(-50%, 0vw);
        }
  99.49% {
    border-radius: 0%;
    transform: translate(-50%, 0vw);
  }
  99.5% {
    border-radius: 30%;
    transform: translate(-50%, 0vw);
  }
  100% {
    border-radius: 30%;
    transform: translate(-50%, 5vw);
  }
}

.zandloper-start-button {
  height: 10vw;
  width: 10vw;
  background-color: red;
}
