.player-screen {
    display: flex;
    width: 400px;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
  }
  
  .player-selection {
    width: 100%;
    min-height: 80vh;
    color: white;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  
  .player-screen-title {
    color: white;
    padding: 20px 0;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
  .player {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    border: 2px solid rgb(29, 185, 84);
    padding: 10px;
    gap: 20px;
    border-radius: 10px;
    background-color: rgb(29, 185, 84);
    font-size: 25px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 10px;
    border: 2px solid white;
  }
  
  .player-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 3px solid white;
    color: white;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .player-edit-buttons {
    position: absolute;
    right: 0;
    width: 70px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .add-player-button {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(29, 185, 84);
    border: 2px solid white;
    border-radius: 10px;
    padding: 5px 10px;
  }
  
  .player-name-input {
    width: 300px;
    height: 100%;
    font-size: 25px;
    background-color: rgb(29, 185, 84);
    color: white;
    border: 2px solid white;
    padding: 0 0 0 10px;
  }

  @media only screen and (max-width: 400px) {

    .player-screen {
        display: flex;
        width: 100vw;
        min-height: 100vh;
        flex-direction: column;
        align-items: center;
      }
    
      .player-selection {
        width: 100%;
        min-height: 80vh;
        color: white;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
    
      .player-screen-title {
        color: white;
        padding: 20px 0;
        font-size: 9vw;
        font-weight: 600;
        text-align: center;
      }
      .player {
        width: 95vw;
        position: relative;
        display: flex;
        flex-direction: row;
        color: white;
        height: 16vw;
        display: flex;
        align-items: center;
        border: 2px solid rgb(29, 185, 84);
        padding: 10px;
        gap: 4vw;
        border-radius: 2.5vw;
        background-color: rgb(29, 185, 84);
        font-size: 6vw;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2.5vw 17.5vw 2.5vw 2.5vw;
        border: 2px solid white;
      }
    
      .player-icon {
        height: 10vw;
        width: 10vw;
        border-radius: 50%;
        border: 3px solid white;
        color: white;
        font-size: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .add-player-button {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2.5vw;
        font-size: 5vw;
        font-weight: 600;
        background-color: rgb(29, 185, 84);
        border: 2px solid white;
        border-radius: 2.5vw;
        padding: 1vw 2vw;
      }
    
      .player-name-input {
        width: 80%;
        height: 100%;
        font-size: 25px;
        background-color: rgb(29, 185, 84);
        color: white;
        border: 2px solid white;
        padding: 0 0 0 10px;
      }
  }