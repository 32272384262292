:root {
    --present-color: rgb(204, 176, 19);
    --keyboard-height-desktop: 176px;
    --keyboard-height-mobile: 30vh;
    --incorrect-key-color: rgb(51, 50, 50);
    --correct-color: green;
    --incorrect-color: grey;
    --max-board-hight: calc(100% - var(var(--keyboard-height-desktop)));
}

.matthijsle-outer-outer {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto ;
}

.matthijsle-outer {
    width: 100vw;
    min-height: calc(100dvh - var(--top-bar-height));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.matthijsle-inner {
    position: relative;
    width: 100vw;
    height: calc(100vh - var(--top-bar-height));
    max-height: calc(100dvh - var(--top-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@keyframes slide-in-error {
    0% {
        top: -300px;
        display: block !important;
    }

    25% {
        top: 50px;
        display: block !important;

    }

    75% {
        top: 50px;
        display: block !important;

    }

    100% {
        top: -300px;
        display: block !important;

    }
}

.incorrect-word {
    animation: 2s slide-in-error;
    position: absolute;
    background-color: rgb(46, 43, 43);
    border: 2px solid red;
    color: red;
    font-weight: 1000;
    padding: 30px;
    top: -300px;
}

.keyboard {
    /* position: absolute; */
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    cursor: pointer;
    margin: 1vw 0 0.5vw 0;
}

.keyboard-row {
    display: flex;
    flex-direction: row;
    gap:  0.5vw;
    justify-content: center;
}

.keyboard-key {
    width: 3.2vw;
    min-width: 45px;
    height: 3.7vw;
    min-height: 50px;
    background-color: rgb(145, 145, 145);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vw;
    font-weight: bold;
    color: white;
    font-size: calc(10px + 0.8vw);
}

.keyboard-key.incorrect {
    background-color: var(--incorrect-key-color);
}

.keyboard-key.present {
    background-color: var(--present-color);
}

.keyboard-key.correct {
    background-color: var(--correct-color);
}

.keyboard-key.special {
    width: 5.5vw;
    height: 3.8vw;
    min-width: 70px;
}

.keyboard-key.special.delete {
    font-size: calc(16px + 1vw);
}

.board-outer {
    /* width: 600px; */
    /* height: calc(100% - var(--keyboard-height-desktop)); */
    margin-top: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.board {
    display: flex;
    flex-direction: column;
    gap: 0.4vw;

    /* padding: 40px; */
}

.word-row {
    display: flex;
    flex-direction: row;
    gap: 0.4vw;
}

.word-tile {
    width: 4vw;
    min-width: 50px;
    aspect-ratio: 1;
    border: 0.1vw solid var(--main-border-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(20px + 1vw);
    font-weight: bold;
    border-radius: 3px;
    vertical-align: center;
}

@keyframes tile-flip {
    0% {
        transform: rotateX(0deg);
    }

    50% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

.word-tile:nth-child(1) {
    animation-delay: 200ms !important;
}

.word-tile:nth-child(2) {
    animation-delay: 400ms !important;
}

.word-tile:nth-child(3) {
    animation-delay: 600ms !important;
}

.word-tile:nth-child(4) {
    animation-delay: 800ms !important;
}

.word-tile:nth-child(5) {
    animation-delay: 1000ms !important;
}

.word-tile.absent {
    background-color: gray;
}

.word-tile.present {
    background-color: var(--present-color);
}

.word-tile.correct {
    background-color: var(--correct-color);
}

.word-tile.correct.animation {
    -webkit-animation-fill-mode: forwards;
    /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;
    /* FF 5+ */
    -o-animation-fill-mode: forwards;
    /* Not implemented yet */
    -ms-animation-fill-mode: forwards;
    /* IE 10+ */
    animation-fill-mode: forwards;
    /* When the spec is finished */
    background-color: transparent;
    animation: 3s tile-flip-correct;
    border: 0.1vw solid var(--main-border-color);

    
}

.word-tile.present.animation {
    -webkit-animation-fill-mode: forwards;
    /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;
    /* FF 5+ */
    -o-animation-fill-mode: forwards;
    /* Not implemented yet */
    -ms-animation-fill-mode: forwards;
    /* IE 10+ */
    animation-fill-mode: forwards;
    /* When the spec is finished */
    background-color: transparent;
    animation: 3s tile-flip-present;
}

.word-tile.absent.animation {
    -webkit-animation-fill-mode: forwards;
    /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;
    /* FF 5+ */
    -o-animation-fill-mode: forwards;
    /* Not implemented yet */
    -ms-animation-fill-mode: forwards;
    /* IE 10+ */
    animation-fill-mode: forwards;
    /* When the spec is finished */
    background-color: transparent;
    animation: 3s tile-flip-absent;
}

@keyframes tile-flip-absent {
    0% {
        transform: scale(0.9);
    }

    10% {
        transform: scale(1.1);
        background-color: var(--incorrect-color);
        border: none;
    }

    20% {
        transform: scale(1);
        background-color: var(--incorrect-color);
        border: none;
    }

    100% {
        transform: scale(1);
        background-color: var(--incorrect-color);
        border: none;
    }
}

@keyframes tile-flip-correct {
    0% {
        transform: scale(0.9);
    }

    10% {
        transform: scale(1.1);
        background-color: var(--correct-color);
        border: none;
    }

    20% {
        transform: scale(1);
        background-color: var(--correct-color);
        border: none;
    }

    100% {
        transform: scale(1);
        background-color: var(--correct-color);
        border: none;
    }
}

@keyframes tile-flip-present {
    0% {
        transform: scale(0.9);

    }

    10% {
        transform: scale(1.1);
        background-color: var(--present-color);
        border: none;
    }

    20% {
        transform: scale(1);
        background-color: var(--present-color);
        border: none;
    }

    100% {
        transform: scale(1);
        background-color: var(--present-color);
        border: none;
    }
}

.score-modal {
    height: fit-content;
    width: fit-content;
    padding: 20px;
    border: 1px solid rgb(46, 43, 43);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.score-title {
    color: white;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.score-subtitle {
    color: white;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.score-items {
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 15px;
}

.score-item {
    display: flex;
    flex-direction: column;
    width: 50px;
    color: white;
    align-items: center;
    text-align: center;
}

.score-item .value {
    font-size: 24px;
    font-weight: 700;
}
.score-item .label {
    font-size: 13px;
    font-weight: 300;
}

.guess-distribution {
    margin-top: 20px;
}
.guess-distribution-title {
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 700;
}

.guess-distribution-chart {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
}

.guess-distribution-chart-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.guess-distribution-chart-row .label {
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.guess-distribution-chart .bar-outer {
    width: 230px;
    height: 20px;
}

.guess-distribution-chart .bar-outer .bar {
    width: 10%;
    height: 100%;
    background-color: rgb(64, 180, 189);
    font-size: 13px;
    font-weight: 700;
    padding-right: 5px;
    color: white;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.next-matthijsle-timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
}

.timer-title {
    font-size: 18px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.timer {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 30px;
    justify-content: center;

}

.new-game-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    border: 1px solid white;
    color: white;
    width: 200px;
    height: 50px;
    font-size: 18px;
    font-weight: 1000;
    border-radius: 5px;
    align-self: center;
    margin-top: 10px;
}

.completed-message {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: white;
}

.completed-word {
    font-size: 28px;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
}
@media only screen and (max-width: 600px) {

    
    @keyframes slide-in-error {
        0% {
            top: -300px;
            display: block !important;
        }
    
        25% {
            top: 50px;
            display: block !important;
    
        }
    
        75% {
            top: 50px;
            display: block !important;
    
        }
    
        100% {
            top: -300px;
            display: block !important;
    
        }
    }
    
    .incorrect-word {
        animation: 2s slide-in-error;
        position: absolute;
        background-color: rgb(46, 43, 43);
        border: 2px solid red;
        color: red;
        font-weight: 1000;
        padding: 30px;
        top: -300px;
    }
    
    .board-outer {
        width: 100%;
        margin-top: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .keyboard {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        padding: 0;
        margin: 2vw 0;
    
    }
    
    .keyboard-row {
        display: flex;
        flex-direction: row;
        gap: 1vw;
        justify-content: center;
    }
    
    .keyboard-key {
        width: 9vw;
        min-width: 0;
        height: 8vh;
        background-color: rgb(145, 145, 145);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5vw;
        font-weight: bold;
        color: white;
        font-size: 4vw;
    }
    
    .keyboard-key.incorrect {
        background-color: var(--incorrect-key-color);
    }
    
    .keyboard-key.present {
        background-color: var(--present-color);
    }
    
    .keyboard-key.correct {
        background-color: var(--correct-color);
    }
    
    .keyboard-key.special {
        width: 13.5vw;
        height: 8vh;
    }
    
    .keyboard-key.special.delete {
        font-size: 5vw;
    }
    
    .board {
        display: flex;
        flex-direction: column;
        gap: 1.5vw;
    }
    
    .word-row {
        display: flex;
        flex-direction: row;
        gap: 1.5vw;
    }
    
    .word-tile {
        width: 16vw;
        aspect-ratio: 1;
        border: 0.2vw solid var(--main-border-color);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 7vw;
        font-weight: bold;
        border-radius: 1vw;
        vertical-align: center;
    }
    
 
    

}