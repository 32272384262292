@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@900&display=swap");

* {
  --kaartje-blue-outer: rgb(9, 154, 224);
  --kaartje-blue-inner: rgb(161, 218, 248);
  --kaartje-yellow-outer: rgb(246, 210, 10);
  --kaartje-yellow-inner: rgb(249, 234, 180);
}

.kaartje-outer {
  position: relative;
  width: 30vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.5s linear kaartje-ease-in ;
}

@keyframes kaartje-ease-in {
    0% {
        transform: translateY(20vw);
    }
    100% {
        transform: translateY(0vw);
    }
}
.kaartje-outer.blue {
    background-color: var(--kaartje-blue-outer);
}
.kaartje-outer.yellow {
    background-color: var(--kaartje-yellow-outer);
}


.kaartje-inner {
  width: 75%;
  height: 100%;
  border-left: 0.2vw solid white;
  border-right: 0.2vw solid white;
}

.kaartje-inner.blue {
    background-color: var(--kaartje-blue-inner);
}

.kaartje-inner.yellow {
    background-color: var(--kaartje-yellow-inner);
}
.kaartje-rand-text {
  font-family: "Yantramanav", sans-serif;
  position: absolute;
  font-size: 2vw;
  color: white;
  font-weight: 700;
}
.kaartje-rand-text.links {
  transform: rotate(-90deg);
  left: -4.5vw;
}

.kaartje-rand-text.rechts {
  transform: rotate(90deg);
  right: -4.5vw;
}

.kaartje-woorden {
    height: 100%;
    width: 100%;
    padding: 1.5vw 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.3vw;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .kaartje-outer {
      transform: scale(3);
    }

        @keyframes kaartje-ease-in {
          0% {
            transform: translateY(60vw) scale(3);
          }
    
          100% {
            transform: translateY(0vw) scale(3);
          }
        }
}