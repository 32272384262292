.choose_playlist_screen {
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.playlist_edit_button {
    position: absolute;
    right: 3vw;
    font-size: 3vw;
  }

  @media only screen and (max-width: 400px) {
    .playlist_edit_button {
        position: absolute;
        right: 3vw;
        font-size: 8vw;
      }
  }