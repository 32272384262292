
@font-face {
  font-family: "Digital-Clock";
  src: url("../assets/DS-DIGIB.TTF");
}

* {
  --minesweeper-main-color: rgb(144, 144, 144);
  --minesweeper-semi-dark-shade: rgb(136, 135, 135);
  --minesweeper-darker-shade: rgb(97, 97, 97);
  --minesweeper-semi-light-shade: rgb(167, 167, 167);
  --minesweeper-lighter-shade: gainsboro;
}
.minesweeper-outer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.minesweeper-inner {
  width: 600px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minesweeper-widget {
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: silver;
  padding: 10px;
  border-left: 3px solid var(--minesweeper-lighter-shade);
  border-top: 3px solid var(--minesweeper-lighter-shade);
  border-right: 3px solid var(--minesweeper-darker-shade);
  border-bottom: 3px solid var(--minesweeper-darker-shade);
}

.minesweeper-widget .minesweeper-topbar {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-left: 3px solid var(--minesweeper-darker-shade);
  border-top: 3px solid var(--minesweeper-darker-shade);
  border-right: 3px solid var(--minesweeper-lighter-shade);
  border-bottom: 3px solid var(--minesweeper-lighter-shade);
}

.minesweeper-grid-outer {
  background-color: silver;
}
.minesweeper-grid {
  display: flex;
  flex-direction: row;
  background-color: silver;
  border-left: 3px solid var(--minesweeper-darker-shade);
  border-top: 3px solid var(--minesweeper-darker-shade);
  border-right: 3px solid var(--minesweeper-lighter-shade);
  border-bottom: 3px solid var(--minesweeper-lighter-shade);
}

.minesweeper-grid-column {
  display: flex;
  flex-direction: column;
}
.minesweeper-tile {
  height: 30px;
  width: 30px;
  background-color: var(--minesweeper-main-color);
  font-family: "Roboto Mono";
  font-weight: 1000;
  font-size: 25px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minesweeper-tile.not-revealed {
  color: red;
  border-left: 3px solid var(--minesweeper-lighter-shade);
  border-top: 3px solid var(--minesweeper-lighter-shade);
  border-right: 3px solid var(--minesweeper-darker-shade);
  border-bottom: 3px solid var(--minesweeper-darker-shade);
}

.minesweeper-tile.revealed {
  background-color: var(--minesweeper-semi-dark-shade);
  border: 1px solid var(--minesweeper-darker-shade);
}

.minesweeper-tile.revealed[value="flag"] {
  color: red;
}

.minesweeper-tile.revealed.losing {
  background-color: red;
  border: none;
}

.minesweeper-tile.revealed[value="1"] {
  color: rgb(0, 0, 217);
}
.minesweeper-tile.revealed[value="2"] {
  color: rgb(1, 113, 1);
}
.minesweeper-tile.revealed[value="3"] {
  color: rgb(218, 0, 0);
}
.minesweeper-tile.revealed[value="4"] {
  color: rgb(0, 0, 111);
}
.minesweeper-tile.revealed[value="5"] {
  color: rgb(147, 41, 41);
}
.minesweeper-tile.revealed[value="6"] {
  color: rgb(20, 152, 152);
}
.minesweeper-tile.revealed[value="7"] {
  color: black;
}
.minesweeper-tile.revealed[value="8"] {
  color: rgb(71, 71, 71);
}

.test {
  font-family: "Digital-Clock";
  color: red;
  font-size: 50px;
}

.emoji-button {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid var(--minesweeper-lighter-shade);
  border-top: 2px solid var(--minesweeper-lighter-shade);
  border-right: 2px solid var(--minesweeper-darker-shade);
  border-bottom: 2px solid var(--minesweeper-darker-shade);
}

.emoji-face {
  font-size: 30px;
  margin-bottom: 0.4vw;
}

.placed-flags-count {
  position: absolute;
  left: 5px;
  color: red;
  background-color: black;
  width: 72px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Digital-Clock";
  font-size: 40px;
  border-left: 2px solid var(--minesweeper-darker-shade);
  border-top: 2px solid var(--minesweeper-darker-shade);
  border-right: 2px solid var(--minesweeper-lighter-shade);
  border-bottom: 2px solid var(--minesweeper-lighter-shade);
}

.time-counter {
  position: absolute;
  height: 45px;
  width: 72px;
  right: 5px;
  background-color: black;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Digital-Clock";
  font-size: 40px;
  border-left: 2px solid var(--minesweeper-darker-shade);
  border-top: 2px solid var(--minesweeper-darker-shade);
  border-right: 2px solid var(--minesweeper-lighter-shade);
  border-bottom: 2px solid var(--minesweeper-lighter-shade);
}

.restart-modal {
  background-color: black;
  width: 280px;
  height: fit-content;
  color: white;
  padding: 10px;
  text-align: center;
  border: 1px solid rgb(82, 82, 82);
  font-size: 20px;
  padding: 20px;
}

.restart-modal-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

.restart-modal-button {
  width: 45%;
  height: fit-content;
  background-color: black;
  border: 2px solid rgb(82, 82, 82);
  border-radius: 3px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.restart-modal-button.yes {
  width: 45%;
  height: fit-content;
  background-color: rgb(82, 82, 82);
  border: 2px solid rgb(82, 82, 82);
  border-radius: 3px;
  font-size: 18px;
  color: black;
}

.minesweeper-options {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.minesweeper-option-small-button {
  height: 35px;
  width: 35px;
  border-left: 2px solid var(--minesweeper-lighter-shade);
  border-top: 2px solid var(--minesweeper-lighter-shade);
  border-right: 2px solid var(--minesweeper-darker-shade);
  border-bottom: 2px solid var(--minesweeper-darker-shade);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--minesweeper-darker-shade);
  font-size: 25px;
  cursor: pointer;
}

.how-to-play-modal {
  max-height: 100vh;
  overflow-y: auto;
  width: 300px;
  color: white;
  font-size: 16px;
  padding: 20px;
  font-weight: 200;
  border: 1px solid rgb(82, 82, 82);
}

.how-to-play-title {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.how-to-play-flag {
  height: 30px;
  width: 30px;
  color: red;
  float: left;
  margin: 5px 10px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: var(--minesweeper-main-color);
  border-left: 3px solid var(--minesweeper-lighter-shade);
  border-top: 3px solid var(--minesweeper-lighter-shade);
  border-right: 3px solid var(--minesweeper-darker-shade);
  border-bottom: 3px solid var(--minesweeper-darker-shade);
}

.how-to-play-bomb {
  height: 30px;
  width: 30px;
  float: left;
  margin: 5px 10px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: red;
  color: black;
}
.how-to-play-number {
  height: 30px;
  width: 30px;
  float: left;
  margin: 5px 10px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: var(--minesweeper-semi-dark-shade);
  font-family: "Roboto Mono";
  font-weight: 1000;
  font-size: 25px;
  color: rgb(1, 113, 1);
  border: 1px solid var(--minesweeper-darker-shade);
}

.minesweeper-score-modal {
  width: 280px;
  padding: 20px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minesweeper-score-title {
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.minesweeper-score-row {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.minesweeper-score-field {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minesweeper-score-field-value {
  font-size: 25px;
  font-weight: bold;
}

.minesweeper-score-field-label {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 200;
}

.minesweeper-fastest-time {
  text-align: center;
  margin-top: 20px;
}
.minesweeper-fastest-time-title {
  font-size: 18px;
  font-weight: bold;
}

.minesweeper-fastest-time-timer {
  margin-top: 5px;
  font-family: "Digital-Clock";
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .minesweeper-tile {
    height: 7.7vw;
    width: 7.7vw;
    background-color: rgb(121, 121, 121);
    font-family: "Roboto Mono";
    font-weight: 1000;
    font-size: 5.5vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .minesweeper-outer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }


  .minesweeper-widget {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: silver;
    padding: 2.4vw;
    border-left: 0.8vw solid var(--minesweeper-lighter-shade);
    border-top: 0.8vw solid var(--minesweeper-lighter-shade);
    border-right: 0.8vw solid var(--minesweeper-darker-shade);
    border-bottom: 0.8vw solid var(--minesweeper-darker-shade);
  }

  .minesweeper-widget .minesweeper-topbar {
    position: relative;
    padding: 1vw;
    margin-bottom: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-left: 0.8vw solid var(--minesweeper-darker-shade);
    border-top: 0.8vw solid var(--minesweeper-darker-shade);
    border-right: 0.8vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.8vw solid var(--minesweeper-lighter-shade);
  }

  .minesweeper-grid-outer {
    background-color: silver;
  }
  .minesweeper-grid {
    display: flex;
    flex-direction: row;
    background-color: silver;
    border-left: 0.8vw solid var(--minesweeper-darker-shade);
    border-top: 0.8vw solid var(--minesweeper-darker-shade);
    border-right: 0.8vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.8vw solid var(--minesweeper-lighter-shade);
  }

  .minesweeper-grid-column {
    display: flex;
    flex-direction: column;
  }

  .minesweeper-tile.not-revealed {
    color: red;
    border-left: 0.8vw solid var(--minesweeper-lighter-shade);
    border-top: 0.8vw solid var(--minesweeper-lighter-shade);
    border-right: 0.8vw solid var(--minesweeper-darker-shade);
    border-bottom: 0.8vw solid var(--minesweeper-darker-shade);
  }

  .minesweeper-tile.revealed {
    background-color: var(--minesweeper-semi-dark-shade);
    border: 0.2vw solid var(--minesweeper-darker-shade);
  }

  .minesweeper-tile.revealed[value="flag"] {
    color: red;
  }

  .minesweeper-tile.revealed.losing {
    background-color: red;
    border: none;
  }

  .minesweeper-tile.revealed[value="1"] {
    color: rgb(0, 0, 217);
  }
  .minesweeper-tile.revealed[value="2"] {
    color: rgb(1, 113, 1);
  }
  .minesweeper-tile.revealed[value="3"] {
    color: rgb(218, 0, 0);
  }
  .minesweeper-tile.revealed[value="4"] {
    color: rgb(0, 0, 111);
  }
  .minesweeper-tile.revealed[value="5"] {
    color: rgb(147, 41, 41);
  }
  .minesweeper-tile.revealed[value="6"] {
    color: rgb(20, 152, 152);
  }
  .minesweeper-tile.revealed[value="7"] {
    color: black;
  }
  .minesweeper-tile.revealed[value="8"] {
    color: rgb(71, 71, 71);
  }

  .test {
    font-family: "Digital-Clock";
    color: red;
    font-size: 50px;
  }

  .placed-flags-count {
    position: absolute;
    left: 2vw;
    color: red;
    background-color: black;
    width: 16vw;
    height: 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Digital-Clock";
    font-size: 9vw;
    border-left: 0.4vw solid var(--minesweeper-darker-shade);
    border-top: 0.4vw solid var(--minesweeper-darker-shade);
    border-right: 0.4vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.4vw solid var(--minesweeper-lighter-shade);
  }

  .time-counter {
    position: absolute;
    width: 16vw;
    height: 9vw;
    right: 2vw;
    background-color: black;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Digital-Clock";
    font-size: 9vw;
    border-left: 0.4vw solid var(--minesweeper-darker-shade);
    border-top: 0.4vw solid var(--minesweeper-darker-shade);
    border-right: 0.4vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.4vw solid var(--minesweeper-lighter-shade);
  }

  .restart-modal {
    background-color: black;
    width: 280px;
    height: fit-content;
    color: white;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(82, 82, 82);
    font-size: 20px;
    padding: 20px;
  }

  .restart-modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .restart-modal-button {
    width: 45%;
    height: fit-content;
    background-color: black;
    border: 2px solid rgb(82, 82, 82);
    border-radius: 3px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
  .restart-modal-button.yes {
    width: 45%;
    height: fit-content;
    background-color: rgb(82, 82, 82);
    border: 2px solid rgb(82, 82, 82);
    border-radius: 3px;
    font-size: 18px;
    color: black;
  }

  .minesweeper-options {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .minesweeper-option-small-button {
    height: 8vw;
    width: 8vw;
    border-left: 2px solid var(--minesweeper-lighter-shade);
    border-top: 2px solid var(--minesweeper-lighter-shade);
    border-right: 2px solid var(--minesweeper-darker-shade);
    border-bottom: 2px solid var(--minesweeper-darker-shade);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--minesweeper-darker-shade);
    font-size: 25px;
    cursor: pointer;
  }

  .how-to-play-modal {
    max-height: 100vh;
    overflow-y: auto;
    width: 300px;
    color: white;
    font-size: 16px;
    padding: 20px;
    font-weight: 200;
    border: 1px solid rgb(82, 82, 82);
  }

  .how-to-play-title {
    text-align: center;
    width: 100%;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .how-to-play-flag {
    height: 30px;
    width: 30px;
    color: red;
    float: left;
    margin: 5px 10px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: var(--minesweeper-main-color);
    border-left: 3px solid var(--minesweeper-lighter-shade);
    border-top: 3px solid var(--minesweeper-lighter-shade);
    border-right: 3px solid var(--minesweeper-darker-shade);
    border-bottom: 3px solid var(--minesweeper-darker-shade);
  }

  .how-to-play-bomb {
    height: 30px;
    width: 30px;
    float: left;
    margin: 5px 10px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: red;
    color: black;
  }
  .how-to-play-number {
    height: 30px;
    width: 30px;
    float: left;
    margin: 5px 10px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: var(--minesweeper-semi-dark-shade);
    font-family: "Roboto Mono";
    font-weight: 1000;
    font-size: 25px;
    color: rgb(1, 113, 1);
    border: 1px solid var(--minesweeper-darker-shade);
  }

  .minesweeper-score-modal {
    width: 280px;
    padding: 20px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .minesweeper-score-title {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .minesweeper-score-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .minesweeper-score-field {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .minesweeper-score-field-value {
    font-size: 25px;
    font-weight: bold;
  }

  .minesweeper-score-field-label {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 200;
  }

  .minesweeper-fastest-time {
    text-align: center;
    margin-top: 20px;
  }
  .minesweeper-fastest-time-title {
    font-size: 18px;
    font-weight: bold;
  }

  .minesweeper-fastest-time-timer {
    margin-top: 5px;
    font-family: "Digital-Clock";
    font-size: 25px;
  }

  .emoji-button {
    height: 9vw;
    width: 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid var(--minesweeper-lighter-shade);
    border-top: 2px solid var(--minesweeper-lighter-shade);
    border-right: 2px solid var(--minesweeper-darker-shade);
    border-bottom: 2px solid var(--minesweeper-darker-shade);
  }

  .emoji-face {
    font-size: 5.8vw;
    margin-bottom: 0.4vw;
  }
}
