/* * {
  overflow-x: hidden;
  overflow-y: auto;
} */

.playlist_search_screen {
    min-height: 100vh;
    align-items: center;
    padding: 0;
  }
  
  .playlist-searchbar {
    width: 100vw;
    height: fit-content;
    z-index: 1;
    padding: 2vw;
  }
  

  .playlist-search-input {
    width: 95%;
    background: transparent;
    border: 1px solid white;
    height: 30px;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    padding: 0 10px;
  }

  .playlist-search-input:focus {
    border: 2px solid rgb(29, 185, 84);
  }

  input:focus {
    outline: none;
  }
  
  .playlist-search-row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .playlist-search-icon-outer {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
  }
  .temp {
    width: 100%;
  }
  
  .extend-list-row {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .extend-list-button {
    color: white;
    font-size: 40px;
  }
  .playlist-search-placeholder-outer {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;
  }
  
  .playlist-search-placeholder {
    width: fit-content;
    padding: 3vw;
    color:rgb(29, 185, 84);
    border: 2px solid rgb(29, 185, 84);
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 1.5vw;
  }

  .playlist-edit-save-button {
    position: fixed;
    background-color: rgb(29, 185, 84);
    bottom: 50px;
    right: 50px;
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    color: white;
    border: 2px solid white;
  }

  .playlist-manage-topbar {
    position: relative;
     width: 100vw;
     padding: 0.5vw 0;
     font-size: 2vw;
     border-bottom: 2px solid white;
     text-align: center;
     color: white;
     font-weight: 1000;
     display: flex;
     justify-content: center;
     align-items: center;
  }

  .back-button {
    position: absolute;
    left: 1vw;
    font-size: 2.6vw;
  }

  @media only screen and (max-width: 400px) {
    .playlist-edit-save-button {
      position: fixed;
      background-color: rgb(29, 185, 84);
      bottom: 50px;
      right: 50px;
      width: 20vw;
      height: 20vw;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8vw;
      color: white;
      border: 2px solid white;
    }

    .playlist-manage-topbar {
      position: relative;
       width: 100vw;
       padding: 2vw 0;
       font-size: 6vw;
       border-bottom: 2px solid white;
       text-align: center;
       color: white;
       font-weight: 1000;
       display: flex;
       justify-content: center;
       align-items: center;
    }
  
    .back-button {
      position: absolute;
      left: 1vw;
      font-size: 8vw;
      color: white;
    }

    .playlist_search_screen {
      min-height: 100vh;
      max-width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .playlist-searchbar {
      width: 95vw;
      height: fit-content;
      margin-top: 20px;
    }
    
    .playlist-search-input {
      width:85vw;
      background: transparent;
      border: 1px solid white;
      font-size: 5vw;
      color: white;
      border-radius: 3vw;
      padding: 0 3vw;
    }
    
    .playlist-search-row {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .playlist-search-icon-outer {
      width: 10vw;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 5vw;
    }

    .playlist-search-placeholder {
      width: 80vw;
      padding: 5vw;
      color:rgb(29, 185, 84);
      border: 2px solid rgb(29, 185, 84);
      border-radius: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 5vw;
    }
  }