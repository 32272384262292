
.topbar {
    position: relative;
    z-index: 100;
    background-color: black;
    width: 100vw;
    max-width: 100vw;
    height: var(--top-bar-height);
    font-size: 2.2vw;
    border-bottom: 1px solid var(--main-border-color);
    text-align: center;
    color: var(--main-color);
    font-weight: 900;
    font-family: "Big Shoulders Inline Text";
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0 2vw;
}

.topbar-small-text {
    position: relative;
    z-index: 100;
    background-color: black;
    width: 100vw;
    max-width: 100vw;
    height: var(--top-bar-height);
    font-size: 2.2vw;
    border-bottom: 1px solid var(--main-border-color);
    background-color: var(--background-color);
    text-align: center;
    color: var(--main-color);
    font-weight: 1000;
    font-family: "Big Shoulders Inline Text";
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0 2vw;
}

.top-bar-icon {
    width: 2vw;
    height: var(--top-bar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    filter: drop-shadow(0px 0px 0.1vw var(--icon-shadow-color));
}

.top-bar-icon-visual {
    font-size: 2.2vw !important;
}
@media only screen and (max-width: 600px) {
    .topbar {
        width: 100vw;
        max-width: 100vw;
        height: var(--top-bar-height-mobile);
        font-size: 7vw;
        font-weight: 1000;
        padding: 0 4vw;
    }

    .topbar-small-text {
        position: relative;
        width: 100vw;
        max-width: 100vw;
        height: var(--top-bar-height-mobile);
        font-size: 5.5vw;
        font-weight: 1000;
        padding: 0 4vw;

    }

    .top-bar-icon {
        width: 6vw;
        height: var(--top-bar-height);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .top-bar-icon-visual {
        font-size: 5.5vw !important;
    }
}