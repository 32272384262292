* {
    user-select: none;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}



.piano-screen-outer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
}

.piano-options {
    box-sizing: border-box;
    width: 100vw;
    color: rgb(168, 145, 66);
    font-size: 1.8vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2vw;
}

.piano {
    width: 100%;
    aspect-ratio: 5/1;
    padding: 2px 0;
    border-top: 3px solid rgb(168, 145, 66);
    border-bottom: 3px solid rgb(168, 145, 66);
}

.note-select-button {
    height: 4vw;
    background-color: rgb(168, 145, 66);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    font-weight: bold;
    cursor: pointer;

}

.octave {
    font-size: 3vw;
    padding: 0 1vw;
    background-color: black;
    color: rgb(168, 145, 66);
    border: 2px solid rgb(168, 145, 66);
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.note-select-button.left {
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
}

.note-select-button.right {
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
}

.note-select-widget {
    display: flex;
    flex-direction: row;

}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    font-size: 3vw;
    padding: 0 1vw;
    background-color: black;
    color: rgb(168, 145, 66);
    border: 2px solid rgb(168, 145, 66);
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.piano-note-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.piano-note-selection.left {
    margin-left: 2vw;
}

.piano-note-selection.right {
    margin-right: 2vw;
}

.rotate-screen {
    width: 100vw;
    height: 100vh;
    font-size: 6vw;
    font-weight: bold;
    color: rgb(168, 145, 66);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}

@keyframes rotate {
    0% {
        transform: rotate(45deg);
    }

    50% {
        transform: rotate(-45deg);
    }

    100% {
        transform: rotate(-45deg);
    }
}

.rotate-icon {
    font-size: 9vw;
    animation: 3s linear rotate infinite;
}

.piano-bottom-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.instrument-outer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2.5vw;
    color: rgb(168, 145, 66);
    gap: 1vw;
    margin-top: 2vw;
    margin-left: 2vw;
}

.instrument-select {
    border-radius: 1vw;
    border: 2px solid rgb(168, 145, 66);
}

.piano-loading {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-indicator {
    height: 3vw;
    width: 3vw;
    border-radius: 50%;
    border: 0.3vw solid rgba(168, 145, 66, 0.1);
    border-top: 0.3vw solid rgb(168, 145, 66);
    animation: 1s linear infinite spin-animation;
}

.record-button {
    position: relative;
    display: flex;
    float: right;
    height: 5vw;
    width: 5vw;
    border-radius: 50%;
    border: 2px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    background-color: black;
    color: var(--main-color);
    cursor: pointer;
}


.record-button:hover, .play-button:hover {
    background-color: rgb(168, 145, 66, 0.1);
    box-shadow: var(--shadow);
}

@keyframes pulsing {

    0% {
        border: 2px solid rgba(255, 0, 0,0.2);
    }
    50% {
        border: 2px solid rgba(255, 0, 0,1);
    }
    100% {
        border: 2px solid rgba(255, 0, 0,0.2);
    }
}

.record-button.active {
    border: 2px solid black;
    animation: 1s linear infinite pulsing;
}
.recording-indicator {
    position: absolute;
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
    background-color: red;
    bottom: 3.1vw;
    right: 0.7vw;
    animation: 1.5s infinite pulsing;
}

.play-button {
    display: flex;
    float: right;
    height: 5vw;
    width: 5vw;
    border-radius: 50%;
    border: 2px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3vw;
    background-color: black;
    color: var(--main-color);
    cursor: pointer;

}

.playback-options-outer-outer {
    border: 3px solid var(--main-color);
    margin-top: 1vw;
    margin-right: 2vw;
}

.playback-options-outer {
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    border: 3px solid black;
}


.playback-options-inner {
    
    display: flex;
    flex-direction: row;
    gap: 2vw;
    padding: 0.4vw 2vw;

}



.recorder-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4vw 0;
}

.recorder-inner-outer {
    border: 3px solid black;
    border-radius: 0.2vw;
}

.recorder-title-inner {
    background-color: black;
    color: var(--main-color);
    padding: 0.1vw 1.5vw 0.2vw 1.5vw;
    border-radius: 0.2vw;
    font-size: 1.2vw;
    font-weight: 100;
    border: 1px solid var(--main-color);
}

.recorder-button-outer {
    border-radius: 50%;
    border: 3px solid black;
    background-color: black;
}

.note-change-background {
    position: relative;
    width: 100vw;
    height: 8vw;
    background-color: black;
    margin-bottom: 0.2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.screw {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 0.6vw;
    box-sizing: border-box;
    padding-bottom: 0.1vw;
    position: absolute;

}

.screw.top-left {
    top: 0.3vw;
    left: 0.3vw;
}
.screw.top-right {
    top: 0.3vw;
    right: 0.3vw;
}
.screw.bottom-left {
    bottom: 0.3vw;
    left: 0.3vw;
}
.screw.bottom-right {

    bottom: 0.3vw;
    right: 0.3vw;
}

.recorded-notes-outer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1vw;
}

.recorded-notes-inner {
    position: relative;
    padding: 0 0 2vw 0;
    min-height: 10vw;
    border: 2px solid var(--main-color);
    margin-bottom: 2vw;
}

.edit-button {
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.5vw;
}

.recorded-notes-title {
    text-align: center;
    color: var(--main-color);
    font-size: 2.5vw;
    font-weight: bold;
    margin-bottom: 2vw;
}

.recorded-notes-note {
    color: var(--main-color);
    font-size: 1.4vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5vw 1vw;
}

.recorded-notes-note:hover {
    border: 1px solid var(--main-color);
    cursor: pointer;
}

.recorded-notes-note .recorded-notes-save-button {
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    background-color: var(--main-color);
    color: black;
    display: none;
}

.recorded-notes-note:hover .recorded-notes-save-button {
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    background-color: var(--main-color);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3vw;
}

.recorded-notes-note .time {
    width: 12vw;
    font-size: 2vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recorded-notes-note .duration {
    width: 10vw;
    font-size: 2vw;
    text-align: center;
}
.recorded-notes-note .note {
    width: 10vw;
    font-size: 2vw;
    text-align: center;
}

.recorded-notes-note .note-input {
    width: 10vw;
    font-size: 2vw;
    background-color: black;
    border: 1px solid var(--main-color);
    color: var(--main-color);
}