.playlist-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  justify-content: space-between;
  row-gap: 1vw;
  padding: 2vw;
}

.playlist-search-result.inactive {
  background: linear-gradient(90deg, rgb(36, 36, 36) 0%, rgb(67, 67, 67) 50%, rgb(36, 36, 36) 100%) !important;
}

.playlist-search-result {
  width: 23vw;
  background: linear-gradient(90deg, rgba(168, 145, 66, 1) 0%, rgb(101, 87, 42) 50%, rgba(168, 145, 66, 1) 100%);
  box-shadow: 0px 0px 0.2vw 0.1vw rgba(188, 183, 168, 0.5);
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw 0 0 0;
  border: 2px solid black;
}

.playlist-search-image {
  width: 20vw;
  height: 20vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(29, 185, 84);
  font-size: 4vw;
}

.playlist-search-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 1.6vw;
  height: 5vw;
  width: 100%;
  padding: 0 4vw;
  font-weight: 500;
  text-align: center;
  margin-top: 1vw;
  text-shadow: black 0px 0px 2px;
}

@media only screen and (max-width: 400px) {
  .playlist-grid {
    margin-top: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 6vw;
  }

  .playlist-search-result {
    width: 90vw;
    height: 108vw;
  background: linear-gradient(90deg, rgba(168, 145, 66, 1) 0%, rgb(101, 87, 42) 50%, rgba(168, 145, 66, 1) 100%);    color: white;
    box-shadow: 0px 0px 2vw 0.5vw rgba(188, 183, 168, 0.5);
  border-radius: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4vw 0 0 0;
  }

  .playlist-search-image {
    width: 75vw;
    height: 75vw;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(29, 185, 84);
    font-size: 4vw;
  }

  .playlist-search-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    font-size: 6.5vw;
    width: 100%;
    height: 18vw;
    padding: 0 4vw;
    font-weight: 500;
    text-align: center;
    margin-top: 5vw;
  }
}
