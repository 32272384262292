.socials-outer {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.topbar-socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: rgb(15, 15, 15);
  font-size: 30px;
  font-weight: 1000;
  color: white;
  border-bottom: 1px solid white;
}

.back-icon-socials {
  position: absolute;
  left: 20px;
  font-size: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.socials-inner {
  width: 400px;
  min-height: 100vh;
}

.socials-inner-inner {
  border: 2px solid white;
  border-top: none;
}
.back-button {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.actual-button {
  height: 27px;
  width: 80px;
  border: 2px solid white;
  box-shadow: var(--shadow);
  background-color: var(--background-color);
  border-radius: 10px;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.socials-top-row {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socials-profile-picture {
  height: 120px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.socials-profile-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0.95;
}

.socials-profile-picture .name {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.socials-stats {
  width: calc(100% - 20px);
  height: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 10px;
}

.socials-stats .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.item .number {
  font-size: 25px;
  font-weight: 700;
}

.item .label {
  font-size: 13px;
  font-weight: 100;
}

.socials-bio {
  font-size: 14px;
  color: white;
  padding: 0 19px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.socials-bio .indent {
  padding-left: 10px;
}

.socials-playlists {
  margin-top: 15px;
}

.socials-playlists .header {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 4px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}

.socials-playlists .playlists {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px 0;
  border-bottom: 2px solid white;
}

.playlists .item-outer {
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.playlists .item-outer .logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(29, 185, 84);
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: white;
}

.playlists .item-outer .label {
  width: 100px;
  font-size: 13px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.socials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  aspect-ratio: 1/ 1;
  padding: 1px;
}

.socials-grid .item {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 68px;
}

[data="facebook"] {
  background: #3a5795;
  color: #1c3977;
  background: linear-gradient(to bottom, #3a5795 0%, #2b4886 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #3a5795),
    color-stop(100%, #2b4886)
  );
  background: -webkit-linear-gradient(top, #3a5795 0%, #2b4886 100%);
  background: -moz-linear-gradient(top, #3a5795 0%, #2b4886 100%);
  background: -o-linear-gradient(top, #3a5795 0%, #2b4886 100%);
  background: -ms-linear-gradient(top, #3a5795 0%, #2b4886 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3A5795', endColorstr='#2B4886', GradientType=0);
  text-shadow: 0 1px 0 #4966a4;
  -webkit-text-shadow: 0 1px 0 #4966a4;
  -moz-text-shadow: 0 1px 0 #4966a4;
  box-shadow: inset 0 1px 0 #4966a4;
  -webkit-box-shadow: inset 0 1px 0 #4966a4;
  -moz-box-shadow: inset 0 1px 0 #4966a4;
}

[data="instagram"] {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

[data="whatsapp"] {
  background: linear-gradient(45deg, #1ebea5, #00e676, #e5ffcc);
}

[data="email"] {
  /* background-color: #990000; */
  background-image: linear-gradient(45deg, #990000 0%, #ff0000 74%);
}

[data="linkedin"] {
  background: linear-gradient(45deg, #0077b5, #00a0dc, #caccce);
}

[data="phone"] {
  background-color: #ff4e00;
  background-image: linear-gradient(45deg, #ff4e00 0%, #ec9f05 74%);
}

[data="spotify"] {
    background: linear-gradient(45deg, #1db954, #14ec60, #1db954);
}

[data="github"] {
    background: linear-gradient(
    45deg,
    #4078c0,
    #6e5494,
    #bd2c00,
    #c9510c,
    #6cc644,
    #fafafa
  );
}

[data="paypal"] {
    background: linear-gradient(-120deg, #003087, #009cde, #012169);
}

@media only screen and (max-width: 400px) {
  .socials-inner {
    width: 100%;
    min-height: 100vh;
  }

  .topbar-socials {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 12vw;
    background-color: rgb(15, 15, 15);
    font-size: 7vw;
    font-weight: 1000;
    color: white;
    border-bottom: 2px solid white;
  }
  
  .back-icon-socials {
    position: absolute;
    left: 3vw;
    font-size: 6vw;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .socials-top-row {
    width: 100%;
    height: 42vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .socials-profile-picture {
    height: 34vw;
    width: 38vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    gap: 1vw;
  }

  .socials-profile-picture img {
    width: 29vw;
    height: 29vw;
    border-radius: 50%;
  }

  .socials-profile-picture .name {
    color: white;
    font-size: 3.4vw;
    font-weight: bold;
  }

  .socials-stats {
    width: calc(100% - 6vw);
    height: 38vw;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 3vw;
  }

  .socials-stats .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .item .number {
    font-size: 7vw;
    font-weight: 700;
  }

  .item .label {
    font-size: 3.8vw;
    font-weight: 100;
  }

  .socials-bio {
    font-size: 4vw;
    color: white;
    padding: 0 3vw;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }

  .socials-bio .indent {
    padding-left: 3vw;
  }

  .socials-playlists {
    margin-top: 5vw;
  }

  .socials-playlists .header {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 1vw;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 4vw;
  }

  .socials-playlists .playlists {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4.5vw 0;
  }

  .playlists .item-outer {
    width: 26vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }
  .playlists .item-outer .logo {
    width: 26vw;
    height: 26vw;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16vw;
    color: white;
  }

  .playlists .item-outer .label {
    width: 26vw;
    font-size: 3.5vw;
    color: white;
    font-weight: bold;
    text-align: center;
  }
}
