@import url(https://fonts.googleapis.com/css2?family=Yantramanav:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Text:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap);
@import url(https://fonts.cdnfonts.com/css/magneto);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  background-color: var(--main-background-color);
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color:black;
background-image:
radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
background-position: 0 0, 40px 60px, 130px 270px, 70px 100px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.socials-outer {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.topbar-socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: rgb(15, 15, 15);
  font-size: 30px;
  font-weight: 1000;
  color: white;
  border-bottom: 1px solid white;
}

.back-icon-socials {
  position: absolute;
  left: 20px;
  font-size: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.socials-inner {
  width: 400px;
  min-height: 100vh;
}

.socials-inner-inner {
  border: 2px solid white;
  border-top: none;
}
.back-button {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.actual-button {
  height: 27px;
  width: 80px;
  border: 2px solid white;
  box-shadow: var(--shadow);
  background-color: var(--background-color);
  border-radius: 10px;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.socials-top-row {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socials-profile-picture {
  height: 120px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.socials-profile-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0.95;
}

.socials-profile-picture .name {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.socials-stats {
  width: calc(100% - 20px);
  height: 120px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 10px;
}

.socials-stats .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.item .number {
  font-size: 25px;
  font-weight: 700;
}

.item .label {
  font-size: 13px;
  font-weight: 100;
}

.socials-bio {
  font-size: 14px;
  color: white;
  padding: 0 19px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  grid-gap: 3px;
  gap: 3px;
}

.socials-bio .indent {
  padding-left: 10px;
}

.socials-playlists {
  margin-top: 15px;
}

.socials-playlists .header {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 4px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}

.socials-playlists .playlists {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px 0;
  border-bottom: 2px solid white;
}

.playlists .item-outer {
  width: 100px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.playlists .item-outer .logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(29, 185, 84);
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: white;
}

.playlists .item-outer .label {
  width: 100px;
  font-size: 13px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.socials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  aspect-ratio: 1/ 1;
  padding: 1px;
}

.socials-grid .item {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 68px;
}

[data="facebook"] {
  background: #3a5795;
  color: #1c3977;
  background: linear-gradient(to bottom, #3a5795 0%, #2b4886 100%);
  background: -ms-linear-gradient(top, #3a5795 0%, #2b4886 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3A5795', endColorstr='#2B4886', GradientType=0);
  text-shadow: 0 1px 0 #4966a4;
  -webkit-text-shadow: 0 1px 0 #4966a4;
  -moz-text-shadow: 0 1px 0 #4966a4;
  box-shadow: inset 0 1px 0 #4966a4;
  -webkit-box-shadow: inset 0 1px 0 #4966a4;
  -moz-box-shadow: inset 0 1px 0 #4966a4;
}

[data="instagram"] {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

[data="whatsapp"] {
  background: linear-gradient(45deg, #1ebea5, #00e676, #e5ffcc);
}

[data="email"] {
  /* background-color: #990000; */
  background-image: linear-gradient(45deg, #990000 0%, #ff0000 74%);
}

[data="linkedin"] {
  background: linear-gradient(45deg, #0077b5, #00a0dc, #caccce);
}

[data="phone"] {
  background-color: #ff4e00;
  background-image: linear-gradient(45deg, #ff4e00 0%, #ec9f05 74%);
}

[data="spotify"] {
    background: linear-gradient(45deg, #1db954, #14ec60, #1db954);
}

[data="github"] {
    background: linear-gradient(
    45deg,
    #4078c0,
    #6e5494,
    #bd2c00,
    #c9510c,
    #6cc644,
    #fafafa
  );
}

[data="paypal"] {
    background: linear-gradient(-120deg, #003087, #009cde, #012169);
}

@media only screen and (max-width: 400px) {
  .socials-inner {
    width: 100%;
    min-height: 100vh;
  }

  .topbar-socials {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 12vw;
    background-color: rgb(15, 15, 15);
    font-size: 7vw;
    font-weight: 1000;
    color: white;
    border-bottom: 2px solid white;
  }
  
  .back-icon-socials {
    position: absolute;
    left: 3vw;
    font-size: 6vw;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .socials-top-row {
    width: 100%;
    height: 42vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .socials-profile-picture {
    height: 34vw;
    width: 38vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    grid-gap: 1vw;
    gap: 1vw;
  }

  .socials-profile-picture img {
    width: 29vw;
    height: 29vw;
    border-radius: 50%;
  }

  .socials-profile-picture .name {
    color: white;
    font-size: 3.4vw;
    font-weight: bold;
  }

  .socials-stats {
    width: calc(100% - 6vw);
    height: 38vw;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 3vw;
  }

  .socials-stats .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .item .number {
    font-size: 7vw;
    font-weight: 700;
  }

  .item .label {
    font-size: 3.8vw;
    font-weight: 100;
  }

  .socials-bio {
    font-size: 4vw;
    color: white;
    padding: 0 3vw;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    grid-gap: 1vw;
    gap: 1vw;
  }

  .socials-bio .indent {
    padding-left: 3vw;
  }

  .socials-playlists {
    margin-top: 5vw;
  }

  .socials-playlists .header {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 1vw;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 4vw;
  }

  .socials-playlists .playlists {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4.5vw 0;
  }

  .playlists .item-outer {
    width: 26vw;
    display: flex;
    flex-direction: column;
    grid-gap: 2vw;
    gap: 2vw;
  }
  .playlists .item-outer .logo {
    width: 26vw;
    height: 26vw;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16vw;
    color: white;
  }

  .playlists .item-outer .label {
    width: 26vw;
    font-size: 3.5vw;
    color: white;
    font-weight: bold;
    text-align: center;
  }
}

* {
    -webkit-user-select: none;
            user-select: none; 
    box-sizing: border-box; 
}



.piano-screen-outer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
}

.piano-options {
    box-sizing: border-box;
    width: 100vw;
    color: rgb(168, 145, 66);
    font-size: 1.8vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2vw;
}

.piano {
    width: 100%;
    aspect-ratio: 5/1;
    padding: 2px 0;
    border-top: 3px solid rgb(168, 145, 66);
    border-bottom: 3px solid rgb(168, 145, 66);
}

.note-select-button {
    height: 4vw;
    background-color: rgb(168, 145, 66);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    font-weight: bold;
    cursor: pointer;

}

.octave {
    font-size: 3vw;
    padding: 0 1vw;
    background-color: black;
    color: rgb(168, 145, 66);
    border: 2px solid rgb(168, 145, 66);
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.note-select-button.left {
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
}

.note-select-button.right {
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
}

.note-select-widget {
    display: flex;
    flex-direction: row;

}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    font-size: 3vw;
    padding: 0 1vw;
    background-color: black;
    color: rgb(168, 145, 66);
    border: 2px solid rgb(168, 145, 66);
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.piano-note-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.piano-note-selection.left {
    margin-left: 2vw;
}

.piano-note-selection.right {
    margin-right: 2vw;
}

.rotate-screen {
    width: 100vw;
    height: 100vh;
    font-size: 6vw;
    font-weight: bold;
    color: rgb(168, 145, 66);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2vw;
    gap: 2vw;
}

@keyframes rotate {
    0% {
        transform: rotate(45deg);
    }

    50% {
        transform: rotate(-45deg);
    }

    100% {
        transform: rotate(-45deg);
    }
}

.rotate-icon {
    font-size: 9vw;
    animation: 3s linear rotate infinite;
}

.piano-bottom-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.instrument-outer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2.5vw;
    color: rgb(168, 145, 66);
    grid-gap: 1vw;
    gap: 1vw;
    margin-top: 2vw;
    margin-left: 2vw;
}

.instrument-select {
    border-radius: 1vw;
    border: 2px solid rgb(168, 145, 66);
}

.piano-loading {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-indicator {
    height: 3vw;
    width: 3vw;
    border-radius: 50%;
    border: 0.3vw solid rgba(168, 145, 66, 0.1);
    border-top: 0.3vw solid rgb(168, 145, 66);
    animation: 1s linear infinite spin-animation;
}

.record-button {
    position: relative;
    display: flex;
    float: right;
    height: 5vw;
    width: 5vw;
    border-radius: 50%;
    border: 2px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    background-color: black;
    color: var(--main-color);
    cursor: pointer;
}


.record-button:hover, .play-button:hover {
    background-color: rgb(168, 145, 66, 0.1);
    box-shadow: var(--shadow);
}

@keyframes pulsing {

    0% {
        border: 2px solid rgba(255, 0, 0,0.2);
    }
    50% {
        border: 2px solid rgba(255, 0, 0,1);
    }
    100% {
        border: 2px solid rgba(255, 0, 0,0.2);
    }
}

.record-button.active {
    border: 2px solid black;
    animation: 1s linear infinite pulsing;
}
.recording-indicator {
    position: absolute;
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
    background-color: red;
    bottom: 3.1vw;
    right: 0.7vw;
    animation: 1.5s infinite pulsing;
}

.play-button {
    display: flex;
    float: right;
    height: 5vw;
    width: 5vw;
    border-radius: 50%;
    border: 2px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3vw;
    background-color: black;
    color: var(--main-color);
    cursor: pointer;

}

.playback-options-outer-outer {
    border: 3px solid var(--main-color);
    margin-top: 1vw;
    margin-right: 2vw;
}

.playback-options-outer {
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    border: 3px solid black;
}


.playback-options-inner {
    
    display: flex;
    flex-direction: row;
    grid-gap: 2vw;
    gap: 2vw;
    padding: 0.4vw 2vw;

}



.recorder-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4vw 0;
}

.recorder-inner-outer {
    border: 3px solid black;
    border-radius: 0.2vw;
}

.recorder-title-inner {
    background-color: black;
    color: var(--main-color);
    padding: 0.1vw 1.5vw 0.2vw 1.5vw;
    border-radius: 0.2vw;
    font-size: 1.2vw;
    font-weight: 100;
    border: 1px solid var(--main-color);
}

.recorder-button-outer {
    border-radius: 50%;
    border: 3px solid black;
    background-color: black;
}

.note-change-background {
    position: relative;
    width: 100vw;
    height: 8vw;
    background-color: black;
    margin-bottom: 0.2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.screw {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 0.6vw;
    box-sizing: border-box;
    padding-bottom: 0.1vw;
    position: absolute;

}

.screw.top-left {
    top: 0.3vw;
    left: 0.3vw;
}
.screw.top-right {
    top: 0.3vw;
    right: 0.3vw;
}
.screw.bottom-left {
    bottom: 0.3vw;
    left: 0.3vw;
}
.screw.bottom-right {

    bottom: 0.3vw;
    right: 0.3vw;
}

.recorded-notes-outer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1vw;
}

.recorded-notes-inner {
    position: relative;
    padding: 0 0 2vw 0;
    min-height: 10vw;
    border: 2px solid var(--main-color);
    margin-bottom: 2vw;
}

.edit-button {
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.5vw;
}

.recorded-notes-title {
    text-align: center;
    color: var(--main-color);
    font-size: 2.5vw;
    font-weight: bold;
    margin-bottom: 2vw;
}

.recorded-notes-note {
    color: var(--main-color);
    font-size: 1.4vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5vw 1vw;
}

.recorded-notes-note:hover {
    border: 1px solid var(--main-color);
    cursor: pointer;
}

.recorded-notes-note .recorded-notes-save-button {
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    background-color: var(--main-color);
    color: black;
    display: none;
}

.recorded-notes-note:hover .recorded-notes-save-button {
    height: 2vw;
    width: 2vw;
    border-radius: 50%;
    background-color: var(--main-color);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3vw;
}

.recorded-notes-note .time {
    width: 12vw;
    font-size: 2vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recorded-notes-note .duration {
    width: 10vw;
    font-size: 2vw;
    text-align: center;
}
.recorded-notes-note .note {
    width: 10vw;
    font-size: 2vw;
    text-align: center;
}

.recorded-notes-note .note-input {
    width: 10vw;
    font-size: 2vw;
    background-color: black;
    border: 1px solid var(--main-color);
    color: var(--main-color);
}
* {
    user-select: none; /* standard */
  -moz-user-select: none; /* firefox specific */
  -webkit-user-select: none; /* Chrome, Opera and Safari*/
  -ms-user-select: none; /* IE, ms-edge */
  -webkit-tap-highlight-color: transparent;
}

.ReactPiano__Keyboard {
    /* Used for absolute positioning of .ReactPiano__Key--accidental elements */
    position: relative;
    /* Used to lay out .ReactPiano__Key--natural elements */
    display: flex;
  }
  
  .ReactPiano__Key {
    /* Used for flexbox layout of the child .ReactPiano__NoteLabelContainer elements */
    display: flex;
  }
  
  /*
   * Styles of accidental notes (flat or sharp)
   */
  .ReactPiano__Key--accidental {
    background: rgb(0, 0, 0);
    /* border: 1px solid white; */
    border-top: 1px solid transparent;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 0px 2px black;
    cursor: pointer;
    height: 66%;
    /* Overlay on top of natural keys */
    z-index: 4;
    /* Use absolute positioning along with inline styles specified in JS to put keys in correct locations. */
    position: absolute;
    top: 0;
  }
  
  /*
   * Styles of natural notes (white keys)
   */
  .ReactPiano__Key--natural {
    background: #f6f5f3;
    border: 1px solid #888;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    z-index: 0;
    /*
     * Uses flexbox with margin instead of absolute positioning to have more consistent margin rendering.
     * This causes inline styles to be ignored.
     */
    flex: 1 1;
    margin-right: 1px;
    user-select: none; /* standard */
  -moz-user-select: none; /* firefox specific */
  -webkit-user-select: none; /* Chrome, Opera and Safari*/
  -ms-user-select: none; /* IE, ms-edge */
  -webkit-tap-highlight-color: transparent;
  }
  
  .ReactPiano__Key--natural::selection {
        color: none;
        background: none;
    
  }
  .ReactPiano__Key--natural:last-child {
    /* Don't render extra margin on the last natural note */
    margin-right: 0;
  }
  
  /*
   * Styles of "active" or pressed-down keys
   */
  .ReactPiano__Key--active {
    background-color: rgb(168, 145, 66);
    z-index: 1;
  }
  
  .ReactPiano__Key--active.ReactPiano__Key--accidental {
    border: 1px solid #fff;
    border-top: 1px solid rgb(168, 145, 66);
    /* Slight height reduction for "pushed-down" effect */
    height: 65%;
  }
  
  .ReactPiano__Key--active.ReactPiano__Key--natural {
    /* border: 1px solid #3ac8da; */
    /* Slight height reduction for "pushed-down" effect */
    height: 98%;
  }
  
  /*
   * Styles for disabled state
   */
  .ReactPiano__Key--disabled.ReactPiano__Key--accidental {
    background: #ddd;
    border: 1px solid #999;
  }
  
  .ReactPiano__Key--disabled.ReactPiano__Key--natural {
    background: #eee;
    border: 1px solid #aaa;
  }
  
  /*
   * Styles for the note label inside a piano key
   */
  .ReactPiano__NoteLabelContainer {
    flex: 1 1;
    /* Align children .ReactPiano__NoteLabel to the bottom of the key */
    align-self: flex-end;
  }
  
  .ReactPiano__NoteLabel {
    font-size: 12px;
    text-align: center;
    text-transform: capitalize;
    /* Disable text selection */
    -webkit-user-select: none;
            user-select: none;
  }
  
  .ReactPiano__NoteLabel--accidental {
    color: #f8e8d5;
    margin-bottom: 3px;
  }
  
  .ReactPiano__NoteLabel--natural {
    color: #888;
    margin-bottom: 3px;
  }
  
  .ReactPiano__NoteLabel--natural.ReactPiano__NoteLabel--active {
    color: #f8e8d5;
  }
  
:root {
    --present-color: rgb(204, 176, 19);
    --keyboard-height-desktop: 176px;
    --keyboard-height-mobile: 30vh;
    --incorrect-key-color: rgb(51, 50, 50);
    --correct-color: green;
    --incorrect-color: grey;
    --max-board-hight: calc(100% - var(var(--keyboard-height-desktop)));
}

.matthijsle-outer-outer {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto ;
}

.matthijsle-outer {
    width: 100vw;
    min-height: calc(100dvh - var(--top-bar-height));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.matthijsle-inner {
    position: relative;
    width: 100vw;
    height: calc(100vh - var(--top-bar-height));
    max-height: calc(100dvh - var(--top-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@keyframes slide-in-error {
    0% {
        top: -300px;
        display: block !important;
    }

    25% {
        top: 50px;
        display: block !important;

    }

    75% {
        top: 50px;
        display: block !important;

    }

    100% {
        top: -300px;
        display: block !important;

    }
}

.incorrect-word {
    animation: 2s slide-in-error;
    position: absolute;
    background-color: rgb(46, 43, 43);
    border: 2px solid red;
    color: red;
    font-weight: 1000;
    padding: 30px;
    top: -300px;
}

.keyboard {
    /* position: absolute; */
    bottom: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5vw;
    gap: 0.5vw;
    cursor: pointer;
    margin: 1vw 0 0.5vw 0;
}

.keyboard-row {
    display: flex;
    flex-direction: row;
    grid-gap:  0.5vw;
    gap:  0.5vw;
    justify-content: center;
}

.keyboard-key {
    width: 3.2vw;
    min-width: 45px;
    height: 3.7vw;
    min-height: 50px;
    background-color: rgb(145, 145, 145);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vw;
    font-weight: bold;
    color: white;
    font-size: calc(10px + 0.8vw);
}

.keyboard-key.incorrect {
    background-color: var(--incorrect-key-color);
}

.keyboard-key.present {
    background-color: var(--present-color);
}

.keyboard-key.correct {
    background-color: var(--correct-color);
}

.keyboard-key.special {
    width: 5.5vw;
    height: 3.8vw;
    min-width: 70px;
}

.keyboard-key.special.delete {
    font-size: calc(16px + 1vw);
}

.board-outer {
    /* width: 600px; */
    /* height: calc(100% - var(--keyboard-height-desktop)); */
    margin-top: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.board {
    display: flex;
    flex-direction: column;
    grid-gap: 0.4vw;
    gap: 0.4vw;

    /* padding: 40px; */
}

.word-row {
    display: flex;
    flex-direction: row;
    grid-gap: 0.4vw;
    gap: 0.4vw;
}

.word-tile {
    width: 4vw;
    min-width: 50px;
    aspect-ratio: 1;
    border: 0.1vw solid var(--main-border-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(20px + 1vw);
    font-weight: bold;
    border-radius: 3px;
    vertical-align: center;
}

@keyframes tile-flip {
    0% {
        transform: rotateX(0deg);
    }

    50% {
        transform: rotateX(-90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

.word-tile:nth-child(1) {
    animation-delay: 200ms !important;
}

.word-tile:nth-child(2) {
    animation-delay: 400ms !important;
}

.word-tile:nth-child(3) {
    animation-delay: 600ms !important;
}

.word-tile:nth-child(4) {
    animation-delay: 800ms !important;
}

.word-tile:nth-child(5) {
    animation-delay: 1000ms !important;
}

.word-tile.absent {
    background-color: gray;
}

.word-tile.present {
    background-color: var(--present-color);
}

.word-tile.correct {
    background-color: var(--correct-color);
}

.word-tile.correct.animation {
    /* Chrome 16+, Safari 4+ */
    /* FF 5+ */
    /* Not implemented yet */
    /* IE 10+ */
    animation-fill-mode: forwards;
    /* When the spec is finished */
    background-color: transparent;
    animation: 3s tile-flip-correct;
    border: 0.1vw solid var(--main-border-color);

    
}

.word-tile.present.animation {
    /* Chrome 16+, Safari 4+ */
    /* FF 5+ */
    /* Not implemented yet */
    /* IE 10+ */
    animation-fill-mode: forwards;
    /* When the spec is finished */
    background-color: transparent;
    animation: 3s tile-flip-present;
}

.word-tile.absent.animation {
    /* Chrome 16+, Safari 4+ */
    /* FF 5+ */
    /* Not implemented yet */
    /* IE 10+ */
    animation-fill-mode: forwards;
    /* When the spec is finished */
    background-color: transparent;
    animation: 3s tile-flip-absent;
}

@keyframes tile-flip-absent {
    0% {
        transform: scale(0.9);
    }

    10% {
        transform: scale(1.1);
        background-color: var(--incorrect-color);
        border: none;
    }

    20% {
        transform: scale(1);
        background-color: var(--incorrect-color);
        border: none;
    }

    100% {
        transform: scale(1);
        background-color: var(--incorrect-color);
        border: none;
    }
}

@keyframes tile-flip-correct {
    0% {
        transform: scale(0.9);
    }

    10% {
        transform: scale(1.1);
        background-color: var(--correct-color);
        border: none;
    }

    20% {
        transform: scale(1);
        background-color: var(--correct-color);
        border: none;
    }

    100% {
        transform: scale(1);
        background-color: var(--correct-color);
        border: none;
    }
}

@keyframes tile-flip-present {
    0% {
        transform: scale(0.9);

    }

    10% {
        transform: scale(1.1);
        background-color: var(--present-color);
        border: none;
    }

    20% {
        transform: scale(1);
        background-color: var(--present-color);
        border: none;
    }

    100% {
        transform: scale(1);
        background-color: var(--present-color);
        border: none;
    }
}

.score-modal {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px;
    border: 1px solid rgb(46, 43, 43);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.score-title {
    color: white;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.score-subtitle {
    color: white;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.score-items {
    display: flex;
    flex-direction: row;
    padding: 10px;
    grid-gap: 15px;
    gap: 15px;
}

.score-item {
    display: flex;
    flex-direction: column;
    width: 50px;
    color: white;
    align-items: center;
    text-align: center;
}

.score-item .value {
    font-size: 24px;
    font-weight: 700;
}
.score-item .label {
    font-size: 13px;
    font-weight: 300;
}

.guess-distribution {
    margin-top: 20px;
}
.guess-distribution-title {
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 700;
}

.guess-distribution-chart {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding: 10px;
}

.guess-distribution-chart-row {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.guess-distribution-chart-row .label {
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.guess-distribution-chart .bar-outer {
    width: 230px;
    height: 20px;
}

.guess-distribution-chart .bar-outer .bar {
    width: 10%;
    height: 100%;
    background-color: rgb(64, 180, 189);
    font-size: 13px;
    font-weight: 700;
    padding-right: 5px;
    color: white;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.next-matthijsle-timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
}

.timer-title {
    font-size: 18px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.timer {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 30px;
    justify-content: center;

}

.new-game-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    border: 1px solid white;
    color: white;
    width: 200px;
    height: 50px;
    font-size: 18px;
    font-weight: 1000;
    border-radius: 5px;
    align-self: center;
    margin-top: 10px;
}

.completed-message {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: white;
}

.completed-word {
    font-size: 28px;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
}
@media only screen and (max-width: 600px) {

    
    @keyframes slide-in-error {
        0% {
            top: -300px;
            display: block !important;
        }
    
        25% {
            top: 50px;
            display: block !important;
    
        }
    
        75% {
            top: 50px;
            display: block !important;
    
        }
    
        100% {
            top: -300px;
            display: block !important;
    
        }
    }
    
    .incorrect-word {
        animation: 2s slide-in-error;
        position: absolute;
        background-color: rgb(46, 43, 43);
        border: 2px solid red;
        color: red;
        font-weight: 1000;
        padding: 30px;
        top: -300px;
    }
    
    .board-outer {
        width: 100%;
        margin-top: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .keyboard {
        display: flex;
        flex-direction: column;
        grid-gap: 1vw;
        gap: 1vw;
        padding: 0;
        margin: 2vw 0;
    
    }
    
    .keyboard-row {
        display: flex;
        flex-direction: row;
        grid-gap: 1vw;
        gap: 1vw;
        justify-content: center;
    }
    
    .keyboard-key {
        width: 9vw;
        min-width: 0;
        height: 8vh;
        background-color: rgb(145, 145, 145);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5vw;
        font-weight: bold;
        color: white;
        font-size: 4vw;
    }
    
    .keyboard-key.incorrect {
        background-color: var(--incorrect-key-color);
    }
    
    .keyboard-key.present {
        background-color: var(--present-color);
    }
    
    .keyboard-key.correct {
        background-color: var(--correct-color);
    }
    
    .keyboard-key.special {
        width: 13.5vw;
        height: 8vh;
    }
    
    .keyboard-key.special.delete {
        font-size: 5vw;
    }
    
    .board {
        display: flex;
        flex-direction: column;
        grid-gap: 1.5vw;
        gap: 1.5vw;
    }
    
    .word-row {
        display: flex;
        flex-direction: row;
        grid-gap: 1.5vw;
        gap: 1.5vw;
    }
    
    .word-tile {
        width: 16vw;
        aspect-ratio: 1;
        border: 0.2vw solid var(--main-border-color);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 7vw;
        font-weight: bold;
        border-radius: 1vw;
        vertical-align: center;
    }
    
 
    

}
.modal-outer {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 3;
    background-color: rgba(0,0,0,0.6);
}

.modal {
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    overflow:hidden;
    background-color: black;
}

.modal-close {
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    overflow:hidden;
    background-color: black;
}


.topbar {
    position: relative;
    z-index: 100;
    background-color: black;
    width: 100vw;
    max-width: 100vw;
    height: var(--top-bar-height);
    font-size: 2.2vw;
    border-bottom: 1px solid var(--main-border-color);
    text-align: center;
    color: var(--main-color);
    font-weight: 900;
    font-family: "Big Shoulders Inline Text";
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0 2vw;
}

.topbar-small-text {
    position: relative;
    z-index: 100;
    background-color: black;
    width: 100vw;
    max-width: 100vw;
    height: var(--top-bar-height);
    font-size: 2.2vw;
    border-bottom: 1px solid var(--main-border-color);
    background-color: var(--background-color);
    text-align: center;
    color: var(--main-color);
    font-weight: 1000;
    font-family: "Big Shoulders Inline Text";
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0 2vw;
}

.top-bar-icon {
    width: 2vw;
    height: var(--top-bar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    filter: drop-shadow(0px 0px 0.1vw var(--icon-shadow-color));
}

.top-bar-icon-visual {
    font-size: 2.2vw !important;
}
@media only screen and (max-width: 600px) {
    .topbar {
        width: 100vw;
        max-width: 100vw;
        height: var(--top-bar-height-mobile);
        font-size: 7vw;
        font-weight: 1000;
        padding: 0 4vw;
    }

    .topbar-small-text {
        position: relative;
        width: 100vw;
        max-width: 100vw;
        height: var(--top-bar-height-mobile);
        font-size: 5.5vw;
        font-weight: 1000;
        padding: 0 4vw;

    }

    .top-bar-icon {
        width: 6vw;
        height: var(--top-bar-height);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .top-bar-icon-visual {
        font-size: 5.5vw !important;
    }
}
.games-outer {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.back-icon {
  position: absolute;
  left: 2vw;
  font-size: 2.5vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  margin-top: 1vw;
}

.place-holder {
  width: 400px;
  height: 200px;
}

.games-widget {
  position: relative;
  width: 30vw;
  height: 15vw;
  border: 0.3vw solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-radius: 1vw;
  overflow: hidden;
  box-shadow: 0px 0px 0.5vw var(--main-color);
}

.games-widget:hover {
  border: 0.3vw solid var(--main-color);
  box-shadow: 0px 0px 0.9vw var(--main-color);
  cursor: pointer;
}
.games-widget-outer {
  position: relative;
  width: 33.3vw;
  height: 18vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.matthijsle-title {
  display: flex;
  flex-direction: row;
  grid-gap: 0.35vw;
  gap: 0.35vw;
}

.matthijsle-title-tile {
  height: 2.5vw;
  width: 2.5vw;
  font-size: 1.5vw;
  border-radius: 0.5vw;
  color: white;
  font-weight: bold;
  background-color: var(--incorrect-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: black 0px 0px 0.2vw;
}

.matthijsle-title-tile.green {
  background-color: var(--correct-color);
}

.matthijsle-title-tile.yellow {
  background-color: var(--present-color);
}

.matthijsle-subtitle {
  font-size: 2.5vw;
  font-weight: 1000;
  color: white;
  margin-top: 1vw;
  text-shadow: var(--main-color) 0px 0px 1vw;
}

.music_quiz_upper_title {
  font-size: 1.5vw;
  color: white;
  font-weight: 800;
  z-index: 1;
  text-shadow: black 0px 0px 1vw;
}

.music_quiz_lower_title {
  font-size: 4vw;
  color: white;
  margin-bottom: 10px;
  font-weight: 800;
  z-index: 1;
  text-shadow: black 0px 0px 1vw;
}

.matthijs-sweeper-widget-background {
  position: absolute;
  width: 30vw;
  height: 15vw;
  z-index: 0;
  color: black;
  left: 0;
  top: 0;
}

.matthijs-sweeper-widget-background-tiles {
  display: grid;
  height: 15vw;
  width: 30vw;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(8, 1fr);
}

.tile {
  width: 1.875vw;
  height: 1.875vw;
  background-color: var(--minesweeper-main-color);
  border-left: 0.225vw solid var(--minesweeper-lighter-shade);
  border-top: 0.225vw solid var(--minesweeper-lighter-shade);
  border-right: 0.225vw solid var(--minesweeper-darker-shade);
  border-bottom: 0.225vw solid var(--minesweeper-darker-shade);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono";
  font-weight: 1000;
  font-size: 1.4vw;
}

.tile[value]:not([value=""]):not([value="flag"]) {
  background-color: var(--minesweeper-semi-dark-shade);
  border: 0.08vw solid var(--minesweeper-darker-shade);
}

.tile[value="1"] {
  color: rgb(0, 0, 217);
}

.tile[value="flag"] {
  color: red;
}

.tile[value="2"] {
  color: rgb(1, 113, 1);
}

.tile[value="3"] {
  color: rgb(218, 0, 0);
}

.tile[value="4"] {
  color: rgb(0, 0, 111);
}

.matthijs-sweeper-title {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Digital-Clock";
  z-index: 10;
  color: rgb(255, 238, 0);
  font-size: 6vw;
  font-weight: 1000;
  left: 0;
  top: 0;
  text-align: center;
  text-shadow: black 0px 0px 0.4vw;
}

.music_quiz_widget_background {
  position: absolute;
  width: 30vw;
  height: 15vw;
  background-color: rgb(29, 185, 84);
  z-index: 0;
}

.music_note {
  color: black;
  position: absolute;
}

.music_note.a {
  top: 0px;
  left: 0.75vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.b {
  top: 2.25vw;
  left: 3vw;
  font-size: 7.5vw;
  transform: rotate(-20deg);
}

.music_note.c {
  top: 2.25vw;
  right: 1.5vw;
  font-size: 9vw;
  transform: rotate(-10deg);
}

.music_note.d {
  top: 15vw;
  left: 7.5vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.e {
  top: 6vw;
  left: 15vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.f {
  top: 8.25vw;
  left: 23.25vw;
  font-size: 6vw;
  transform: rotate(10deg);
}

.music_note.g {
  top: 8.25vw;
  left: 0px;
  font-size: 6vw;
  transform: rotate(-15deg);
}

.music_note.h {
  top: 8.25vw;
  left: 4.5vw;
  font-size: 6vw;
  transform: rotate(20deg);
}

.music_note.i {
  top: 4.125vw;
  left: 10.125vw;
  font-size: 6vw;
  transform: rotate(-10deg);
}

.music_note.j {
  top: 9.75vw;
  left: 9vw;
  font-size: 4.6vw;
  transform: rotate(-20deg);
}

.music_note.k {
  top: 0px;
  left: 12vw;
  font-size: 4.5vw;
  transform: rotate(-20deg);
}

.music_note.l {
  top: 9.75vw;
  left: 12.75vw;
  font-size: 3.75vw;
  transform: rotate(-20deg);
}

.music_note.m {
  top: 0.75vw;
  right: 0px;
  font-size: 4.5vw;
  transform: rotate(-20deg);
}

.music_note.n {
  top: 1.5vw;
  left: 14.625vw;
  font-size: 6vw;
  transform: rotate(10deg);
}

.music_note.o {
  top: 9.75vw;
  left: 18vw;
  font-size: 4.5vw;
  transform: rotate(-10deg);
}

.music_note.p {
  top: 0px;
  left: 19.5vw;
  font-size: 4.5vw;
  transform: rotate(-10deg);
}

.music_note.q {
  top: 0px;
  left: 7.5vw;
  font-size: 3.75vw;
  transform: rotate(-10deg);
}

.music_note.r {
  top: 9.75vw;
  left: 18vw;
  font-size: 4.5vw;
  transform: rotate(-10deg);
}

.games-widget.music {
  background-color: rgb(29, 185, 84);
}

.games-widget.sweeper {
  background-color: gray;
}

.snake-widget {
  width: 30vw;
  height: 15vw;
  position: relative;
  background-color: var(--screen-color);
}

.snake-body {
  position: absolute;
  width: 10vw;
  height: 5vw;
  border-radius: 5vw 5vw 0 0;
  border: 2.5vw solid var(--snake-color);
  border-bottom: none;
}

.snake-body.a {
  top: 7vw;
  left: 2.5vw;
  transform: rotate(180deg);
}

.snake-body.b {
  top: 2.5vw;
  left: 10vw;
}

.snake-body.c {
  top: 7vw;
  right: 2.5vw;
  transform: rotate(180deg);
}

.snake-tail {
  position: absolute;
  top: 5.7vw;
  left: 2.5vw;
  width: 2.5vw;
  height: 1.5vw;
  background-color: var(--snake-color);
  border-radius: 2.5vw 2.5vw 0 0;
}

.snake-head {
  position: absolute;
  width: 8vw;
  top: 1.9vw;
  right: -0.2vw;
}

.snake-title {
  position: absolute;
  font-size: 5vw;
  font-weight: 1000;
  z-index: 100;
  color: red;
  width: 30vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: rgb(77, 0, 0) 0px 0px 0.5vw;
  padding: 0 3vw 0 0;
}

.games-widget.snake {
  background-color: var(--screen-color);
}

.snake-tongue {
  position: absolute;
  top: 7.3vw;
  right: 3.3vw;
  height: 2vw;
  width: 0.5vw;
  background-color: red;
  z-index: 100;
  transform: rotate(-10deg);
}

.snake-tongue-tip {
  position: absolute;
  bottom: -1vw;
  left: -0.1vw;
  z-index: 1000;
  border-top: 1.5vw solid red;
  border-left: 0.2vw solid transparent;
  border-right: 0.2vw solid transparent;
  transform: rotate(10deg);
}

.snake-tongue-tip-right {
  position: absolute;
  bottom: -1vw;
  right: -0.1vw;
  z-index: 1000;
  border-top: 1.5vw solid red;
  border-left: 0.2vw solid transparent;
  border-right: 0.2vw solid transparent;
  transform: rotate(-10deg);
}

.tetris_widget_background {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.space-image {
  position: absolute;
  width: 30vw;
  height: 20vw;
  z-index: 0;
}

.tetris_letter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: 7vw;
  width: 4.2vw;
}

.tetris_letter .pixel {
  width: 1.4vw;
  height: 1.4vw;
  z-index: 10;
}

.tetris_letter.t .pixel.filled {
  background-color: rgb(0, 240, 240);
  border-top: 0.4vw solid rgb(96, 246, 246);
  border-left: 0.4vw solid rgb(49, 243, 243);
  border-bottom: 0.4vw solid rgb(0, 123, 123);
  border-right: 0.4vw solid rgb(0, 167, 167);
}

.tetris_letter.e .pixel.filled {
  background-color: rgb(0, 240, 0);
  border-top: 0.4vw solid rgb(96, 246, 96);
  border-left: 0.4vw solid rgb(49, 243, 49);
  border-bottom: 0.4vw solid rgb(0, 123, 0);
  border-right: 0.4vw solid rgb(0, 167, 0);
}

.tetris_letter.t2 .pixel.filled {
  background-color: rgb(160, 0, 240);
  border-top: 0.4vw solid rgb(196, 96, 246);
  border-left: 0.4vw solid rgb(178, 49, 243);
  border-bottom: 0.4vw solid rgb(82, 0, 123);
  border-right: 0.4vw solid rgb(111, 0, 167);
}

.tetris_letter.r .pixel.filled {
  background-color: rgb(240, 0, 0);
  border-top: 0.4vw solid rgb(246, 96, 96);
  border-left: 0.4vw solid rgb(243, 49, 49);
  border-bottom: 0.4vw solid rgb(123, 0, 0);
  border-right: 0.4vw solid rgb(167, 0, 0);
}

.tetris_letter.i .pixel.filled {
  background-color: rgb(240, 240, 0);
  border-top: 0.4vw solid rgb(246, 246, 96);
  border-left: 0.4vw solid rgb(243, 243, 49);
  border-bottom: 0.4vw solid rgb(123, 123, 0);
  border-right: 0.4vw solid rgb(167, 167, 0);
}

.tetris_letter.s .pixel.filled {
  background-color: rgb(0, 0, 240);
  border-top: 0.4vw solid rgb(96, 96, 246);
  border-left: 0.4vw solid rgb(49, 49, 243);
  border-bottom: 0.4vw solid rgb(0, 0, 123);
  border-right: 0.4vw solid rgb(0, 0, 167);
}

.ttfe_widget {
  height: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transform: scale(1);
}

.ttfe_row {
  width: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ttfe_widget_tile {
  width: 4.5vw;
  height: 4.5vw;
  background-color: #eee4da;
  border-radius: 0.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
  font-weight: 600;
  color: rgb(90, 86, 86);
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: rgb(54, 54, 54, 0.5) 0px 0px 1vw;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0.5vw;
}

.ttfe_title {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0.8vw;
  gap: 0.8vw;
  background-color: rgb(0, 0, 0, 0.2);
}

.ttfe_title_tile {
  width: 6.5vw;
  height: 6.5vw;
  background-color: green;
  border-radius: 1vw;
  background-color: #edc22e;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 0px 1vw rgb(0, 0, 0, 0.5);
  border: 0.2vw solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  font-weight: 600;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1vw;
  z-index: 10;
}

.ttfe_widget_tile.tile2 {
  background-color: #eee4da;
}

.ttfe_widget_tile.tile4 {
  background-color: #ede0c8;
}

.ttfe_widget_tile.tile8 {
  color: white;
  background-color: #f2b179;
}

.ttfe_widget_tile.tile16 {
  color: white;
  background-color: #f59563;
}

.ttfe_widget_tile.tile32 {
  color: white;
  background-color: #f67c5f;
}

.ttfe_widget_tile.tile64 {
  color: white;
  background-color: #f63e3b;
}

.ttfe_widget_tile.tile128 {
  color: white;
  background-color: #edcf72;
  font-size: 2.2vw;
}

.ttfe_widget_tile.tile256 {
  color: white;
  background-color: #edcc61;
  font-size: 2.2vw;
}

.ttfe_widget_tile.tile512 {
  color: white;
  background-color: #edc850;
  font-size: 2.2vw;
}

.ttfe_widget_tile.tile1024 {
  color: white;
  background-color: #edc53f;
  font-size: 1.8vw;
}

.ttfe_widget_tile.tile2048 {
  color: white;
  background-color: #edc22e;
  font-size: 2.4vw;
}

.tileSuper {
  color: white;
  background-color: darkblue;
  font-size: 2.1vw;
}

.games-widget.ttfe {
  background-color: rgb(90, 86, 86);
}

@media only screen and (max-width: 600px) {
  


  .games-widget {
    transform: scale(3.1);
    border-radius: 1vw;

  }

  .games-grid {
    display: flex;
    flex-direction: column;
    will-change: transform;

    grid-gap: 3vw;

    gap: 3vw;
  }

  .games-widget-outer {
    position: relative;
    width: 100vw;
    height: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

* {
  --nokia-color: rgb(49, 57, 76);
  --screen-color: rgba(154, 195, 4, 1);
  --snake-color: rgb(53, 53, 53);
}


.snake-outer {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.phone-outer {
  position: relative;
}
.phone-image {
  width: 290px;
  height: 650px;
}

.screen {
  position: absolute;
  z-index: 100;
  top: 170px;
  left: 43px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--screen-color);
  padding: 0 7px 7px 7px;
  border-radius: 5px 5px 10px 10px;
}

.score-bar {
  position: relative;
  width: 100%;
  height: 25px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 2px solid var(--snake-color);
  font-weight: 700;
  color: var(--snake-color);
}

.snake-inner {
  width: 600px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid {
  border: 2px solid var(--snake-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  grid-gap: 1px;
  gap: 1px;
}

.grid-row {
  display: flex;
  flex-direction: row;
  grid-gap: 1px;
  gap: 1px;
}

.empty-tile {
  width: 6px;
  height: 6px;
}

.pixel {
  width: 8px;
  height: 8px;
}

.pixel.snake {
  background-color: var(--snake-color);
}
.pixel.apple {
  background-color: red;
}

.pixel.gameover {
  animation: blinker 0.5s infinite;
  background-color: red;
}

.snake_button {
  position: absolute;
  height: 100px;
  width: 100px;
}

.snake_button.left {
  top: 430px;
}

.snake_button.right {
  top: 430px;
  right: 0;
}

.snake_button.top {
  top: 390px;
  right: 105px;
  width: 80px;
}
.snake_button.bottom {
  top: 500px;
  right: 105px;
  width: 80px;
}

.game_over_screen {
  position: absolute;
  background-color: var(--screen-color);
  top: 202px;
  left: 52px;
  z-index: 100;
  width: 179px;
  height: 107px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Righteous";
  font-weight: 1000;
  color: var(--snake-color);
  font-size: 28px;
  grid-gap: 0;
  gap: 0;
  animation: font-zoom 2s linear;
}

.game_over_screen p {
  font-size: 15px;
  height: 20px;
  margin-top: -3px;
  color: red;
  animation: font-appearing 2s;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes font-zoom {
  0% {
    font-size: 0px;
  }
  100% {
    font-size: 28px;
  }
}

@keyframes font-appearing {
  0% {
    font-size: 0px;
  }
  99% {
    font-size: 0px;
  }
  100% {
    font-size: 15px;
  }
}
.game_over_transition {
  position: absolute;
  animation: blinker 1s infinite;
  background-color: var(--screen-color);
  top: 201px;
  left: 51px;
  z-index: 100;
  width: 180px;
  height: 108px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Righteous";
  font-weight: 1000;
  font-size: 28px;
  grid-gap: 0;
  gap: 0;
}

.snake_settings_button {
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 0px;
  color: var(--snake-color);
  cursor: pointer;
}

.snake_pause_play_button {
  position: absolute;
  font-size: 20px;
  right: 30px;
  top: 0;
  color: var(--snake-color);
  cursor: pointer;
}

.settings_screen {
  position: absolute;
  background-color: var(--screen-color);
  top: 202px;
  left: 52px;
  z-index: 110;
  width: 179px;
  height: 107px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Righteous";
  font-weight: 1000;
  color: var(--snake-color);
  font-size: 15px;
  grid-gap: 0;
  gap: 0;
}

.snake_speed_option {
  font-size: 12px;
}

.snake_speed_option.active {
  color: rgb(251, 255, 39);
}

@media only screen and (max-width: 600px) {
  .snake-outer {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .phone-image {
    height: 100vh;
    width: 46vh;
    overflow-y: hidden;
  }

  .pixel {
    width: 1.33vh;
    height: 1.33vh;
  }

  .screen {
    position: absolute;
    z-index: 100;
    top: 26vh;
    left: 7vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--screen-color);
    padding: 0 1vh 1vh 1vh;
    border-radius: 1vh 1vh 2vh 2vh;
  }

  .grid {
    border: 0.3vh solid var(--snake-color);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    grid-gap: 0.1vh;
    gap: 0.1vh;
  }

  .grid-row {
    display: flex;
    flex-direction: row;
    grid-gap: 0.1vh;
    gap: 0.1vh;
  }

  .game_over_screen {
    position: absolute;
    background-color: var(--screen-color);
    top: 30.3vh;
    left: 8.3vh;
    z-index: 100;
    width: 28.5vh;
    height: 17.1vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Righteous";
    font-weight: 1000;
    font-size: 4.5vh;
    grid-gap: 0;
    gap: 0;
    animation: font-zoom 2s linear;
  }

  .snake_button {
    position: absolute;
    height: 15vh;
    width: 15vh;
  }

  .snake_button.left {
    top: 68vh;
    left: 1vh;
  }

  .snake_button.right {
    top: 68vh;
    right: 1vh;
  }

  .snake_button.top {
    top: 60vh;
    right: 16.5vh;
    width: 13vh;
  }

  .snake_button.bottom {
    top: 77vh;
    right: 16.5vh;
    width: 13vh;
  }

  .game_over_screen p {
    font-size: 2.2vh;
    height: 3vh;
    margin-top: 0;
    color: red;
    animation: font-appearing 2s;
  }

  .score-bar {
    width: 100%;
    height: 3vh;
    font-size: 2vh;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
    border-bottom: 0.3vh solid var(--snake-color);
  }

  @keyframes font-zoom {
    0% {
      font-size: 0px;
    }

    100% {
      font-size: 4.5vh;
    }
  }

  @keyframes font-appearing {
    0% {
      font-size: 0px;
    }

    99% {
      font-size: 0px;
    }

    100% {
      font-size: 2.2vh;
    }
  }

  .settings_screen {
    position: absolute;
    background-color: var(--screen-color);
    top: 30.3vh;
    left: 8.3vh;
    z-index: 110;
    width: 28.5vh;
    height: 17.1vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Righteous";
    font-weight: 1000;
    color: var(--snake-color);
    font-size: 2.5vh;
    grid-gap: 0;
    gap: 0;
  }

  .snake_speed_option {
    font-size: 1.8vh;
  }

  .snake_speed_option.active {
    color: rgb(251, 255, 39);
  }

  .snake_settings_button {
    position: absolute;
    right: 1vh;
    font-size: 2.5vh;
    top: 0vh;
    color: var(--snake-color);
    cursor: pointer;
  }

  .snake_pause_play_button {
    position: absolute;
    font-size: 2.5vh;
    right: 3.5vh;
    top: 0;
    color: var(--snake-color);
    cursor: pointer;
  }
}


@font-face {
  font-family: "Digital-Clock";
  src: url(/static/media/DS-DIGIB.81b4bd92.TTF);
}

* {
  --minesweeper-main-color: rgb(144, 144, 144);
  --minesweeper-semi-dark-shade: rgb(136, 135, 135);
  --minesweeper-darker-shade: rgb(97, 97, 97);
  --minesweeper-semi-light-shade: rgb(167, 167, 167);
  --minesweeper-lighter-shade: gainsboro;
}
.minesweeper-outer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.minesweeper-inner {
  width: 600px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minesweeper-widget {
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: silver;
  padding: 10px;
  border-left: 3px solid var(--minesweeper-lighter-shade);
  border-top: 3px solid var(--minesweeper-lighter-shade);
  border-right: 3px solid var(--minesweeper-darker-shade);
  border-bottom: 3px solid var(--minesweeper-darker-shade);
}

.minesweeper-widget .minesweeper-topbar {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-left: 3px solid var(--minesweeper-darker-shade);
  border-top: 3px solid var(--minesweeper-darker-shade);
  border-right: 3px solid var(--minesweeper-lighter-shade);
  border-bottom: 3px solid var(--minesweeper-lighter-shade);
}

.minesweeper-grid-outer {
  background-color: silver;
}
.minesweeper-grid {
  display: flex;
  flex-direction: row;
  background-color: silver;
  border-left: 3px solid var(--minesweeper-darker-shade);
  border-top: 3px solid var(--minesweeper-darker-shade);
  border-right: 3px solid var(--minesweeper-lighter-shade);
  border-bottom: 3px solid var(--minesweeper-lighter-shade);
}

.minesweeper-grid-column {
  display: flex;
  flex-direction: column;
}
.minesweeper-tile {
  height: 30px;
  width: 30px;
  background-color: var(--minesweeper-main-color);
  font-family: "Roboto Mono";
  font-weight: 1000;
  font-size: 25px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minesweeper-tile.not-revealed {
  color: red;
  border-left: 3px solid var(--minesweeper-lighter-shade);
  border-top: 3px solid var(--minesweeper-lighter-shade);
  border-right: 3px solid var(--minesweeper-darker-shade);
  border-bottom: 3px solid var(--minesweeper-darker-shade);
}

.minesweeper-tile.revealed {
  background-color: var(--minesweeper-semi-dark-shade);
  border: 1px solid var(--minesweeper-darker-shade);
}

.minesweeper-tile.revealed[value="flag"] {
  color: red;
}

.minesweeper-tile.revealed.losing {
  background-color: red;
  border: none;
}

.minesweeper-tile.revealed[value="1"] {
  color: rgb(0, 0, 217);
}
.minesweeper-tile.revealed[value="2"] {
  color: rgb(1, 113, 1);
}
.minesweeper-tile.revealed[value="3"] {
  color: rgb(218, 0, 0);
}
.minesweeper-tile.revealed[value="4"] {
  color: rgb(0, 0, 111);
}
.minesweeper-tile.revealed[value="5"] {
  color: rgb(147, 41, 41);
}
.minesweeper-tile.revealed[value="6"] {
  color: rgb(20, 152, 152);
}
.minesweeper-tile.revealed[value="7"] {
  color: black;
}
.minesweeper-tile.revealed[value="8"] {
  color: rgb(71, 71, 71);
}

.test {
  font-family: "Digital-Clock";
  color: red;
  font-size: 50px;
}

.emoji-button {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid var(--minesweeper-lighter-shade);
  border-top: 2px solid var(--minesweeper-lighter-shade);
  border-right: 2px solid var(--minesweeper-darker-shade);
  border-bottom: 2px solid var(--minesweeper-darker-shade);
}

.emoji-face {
  font-size: 30px;
  margin-bottom: 0.4vw;
}

.placed-flags-count {
  position: absolute;
  left: 5px;
  color: red;
  background-color: black;
  width: 72px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Digital-Clock";
  font-size: 40px;
  border-left: 2px solid var(--minesweeper-darker-shade);
  border-top: 2px solid var(--minesweeper-darker-shade);
  border-right: 2px solid var(--minesweeper-lighter-shade);
  border-bottom: 2px solid var(--minesweeper-lighter-shade);
}

.time-counter {
  position: absolute;
  height: 45px;
  width: 72px;
  right: 5px;
  background-color: black;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Digital-Clock";
  font-size: 40px;
  border-left: 2px solid var(--minesweeper-darker-shade);
  border-top: 2px solid var(--minesweeper-darker-shade);
  border-right: 2px solid var(--minesweeper-lighter-shade);
  border-bottom: 2px solid var(--minesweeper-lighter-shade);
}

.restart-modal {
  background-color: black;
  width: 280px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  padding: 10px;
  text-align: center;
  border: 1px solid rgb(82, 82, 82);
  font-size: 20px;
  padding: 20px;
}

.restart-modal-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

.restart-modal-button {
  width: 45%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: black;
  border: 2px solid rgb(82, 82, 82);
  border-radius: 3px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.restart-modal-button.yes {
  width: 45%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgb(82, 82, 82);
  border: 2px solid rgb(82, 82, 82);
  border-radius: 3px;
  font-size: 18px;
  color: black;
}

.minesweeper-options {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.minesweeper-option-small-button {
  height: 35px;
  width: 35px;
  border-left: 2px solid var(--minesweeper-lighter-shade);
  border-top: 2px solid var(--minesweeper-lighter-shade);
  border-right: 2px solid var(--minesweeper-darker-shade);
  border-bottom: 2px solid var(--minesweeper-darker-shade);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--minesweeper-darker-shade);
  font-size: 25px;
  cursor: pointer;
}

.how-to-play-modal {
  max-height: 100vh;
  overflow-y: auto;
  width: 300px;
  color: white;
  font-size: 16px;
  padding: 20px;
  font-weight: 200;
  border: 1px solid rgb(82, 82, 82);
}

.how-to-play-title {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.how-to-play-flag {
  height: 30px;
  width: 30px;
  color: red;
  float: left;
  margin: 5px 10px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: var(--minesweeper-main-color);
  border-left: 3px solid var(--minesweeper-lighter-shade);
  border-top: 3px solid var(--minesweeper-lighter-shade);
  border-right: 3px solid var(--minesweeper-darker-shade);
  border-bottom: 3px solid var(--minesweeper-darker-shade);
}

.how-to-play-bomb {
  height: 30px;
  width: 30px;
  float: left;
  margin: 5px 10px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: red;
  color: black;
}
.how-to-play-number {
  height: 30px;
  width: 30px;
  float: left;
  margin: 5px 10px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: var(--minesweeper-semi-dark-shade);
  font-family: "Roboto Mono";
  font-weight: 1000;
  font-size: 25px;
  color: rgb(1, 113, 1);
  border: 1px solid var(--minesweeper-darker-shade);
}

.minesweeper-score-modal {
  width: 280px;
  padding: 20px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minesweeper-score-title {
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.minesweeper-score-row {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.minesweeper-score-field {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minesweeper-score-field-value {
  font-size: 25px;
  font-weight: bold;
}

.minesweeper-score-field-label {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 200;
}

.minesweeper-fastest-time {
  text-align: center;
  margin-top: 20px;
}
.minesweeper-fastest-time-title {
  font-size: 18px;
  font-weight: bold;
}

.minesweeper-fastest-time-timer {
  margin-top: 5px;
  font-family: "Digital-Clock";
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .minesweeper-tile {
    height: 7.7vw;
    width: 7.7vw;
    background-color: rgb(121, 121, 121);
    font-family: "Roboto Mono";
    font-weight: 1000;
    font-size: 5.5vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .minesweeper-outer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }


  .minesweeper-widget {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: silver;
    padding: 2.4vw;
    border-left: 0.8vw solid var(--minesweeper-lighter-shade);
    border-top: 0.8vw solid var(--minesweeper-lighter-shade);
    border-right: 0.8vw solid var(--minesweeper-darker-shade);
    border-bottom: 0.8vw solid var(--minesweeper-darker-shade);
  }

  .minesweeper-widget .minesweeper-topbar {
    position: relative;
    padding: 1vw;
    margin-bottom: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-left: 0.8vw solid var(--minesweeper-darker-shade);
    border-top: 0.8vw solid var(--minesweeper-darker-shade);
    border-right: 0.8vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.8vw solid var(--minesweeper-lighter-shade);
  }

  .minesweeper-grid-outer {
    background-color: silver;
  }
  .minesweeper-grid {
    display: flex;
    flex-direction: row;
    background-color: silver;
    border-left: 0.8vw solid var(--minesweeper-darker-shade);
    border-top: 0.8vw solid var(--minesweeper-darker-shade);
    border-right: 0.8vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.8vw solid var(--minesweeper-lighter-shade);
  }

  .minesweeper-grid-column {
    display: flex;
    flex-direction: column;
  }

  .minesweeper-tile.not-revealed {
    color: red;
    border-left: 0.8vw solid var(--minesweeper-lighter-shade);
    border-top: 0.8vw solid var(--minesweeper-lighter-shade);
    border-right: 0.8vw solid var(--minesweeper-darker-shade);
    border-bottom: 0.8vw solid var(--minesweeper-darker-shade);
  }

  .minesweeper-tile.revealed {
    background-color: var(--minesweeper-semi-dark-shade);
    border: 0.2vw solid var(--minesweeper-darker-shade);
  }

  .minesweeper-tile.revealed[value="flag"] {
    color: red;
  }

  .minesweeper-tile.revealed.losing {
    background-color: red;
    border: none;
  }

  .minesweeper-tile.revealed[value="1"] {
    color: rgb(0, 0, 217);
  }
  .minesweeper-tile.revealed[value="2"] {
    color: rgb(1, 113, 1);
  }
  .minesweeper-tile.revealed[value="3"] {
    color: rgb(218, 0, 0);
  }
  .minesweeper-tile.revealed[value="4"] {
    color: rgb(0, 0, 111);
  }
  .minesweeper-tile.revealed[value="5"] {
    color: rgb(147, 41, 41);
  }
  .minesweeper-tile.revealed[value="6"] {
    color: rgb(20, 152, 152);
  }
  .minesweeper-tile.revealed[value="7"] {
    color: black;
  }
  .minesweeper-tile.revealed[value="8"] {
    color: rgb(71, 71, 71);
  }

  .test {
    font-family: "Digital-Clock";
    color: red;
    font-size: 50px;
  }

  .placed-flags-count {
    position: absolute;
    left: 2vw;
    color: red;
    background-color: black;
    width: 16vw;
    height: 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Digital-Clock";
    font-size: 9vw;
    border-left: 0.4vw solid var(--minesweeper-darker-shade);
    border-top: 0.4vw solid var(--minesweeper-darker-shade);
    border-right: 0.4vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.4vw solid var(--minesweeper-lighter-shade);
  }

  .time-counter {
    position: absolute;
    width: 16vw;
    height: 9vw;
    right: 2vw;
    background-color: black;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Digital-Clock";
    font-size: 9vw;
    border-left: 0.4vw solid var(--minesweeper-darker-shade);
    border-top: 0.4vw solid var(--minesweeper-darker-shade);
    border-right: 0.4vw solid var(--minesweeper-lighter-shade);
    border-bottom: 0.4vw solid var(--minesweeper-lighter-shade);
  }

  .restart-modal {
    background-color: black;
    width: 280px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(82, 82, 82);
    font-size: 20px;
    padding: 20px;
  }

  .restart-modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .restart-modal-button {
    width: 45%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: black;
    border: 2px solid rgb(82, 82, 82);
    border-radius: 3px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }
  .restart-modal-button.yes {
    width: 45%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgb(82, 82, 82);
    border: 2px solid rgb(82, 82, 82);
    border-radius: 3px;
    font-size: 18px;
    color: black;
  }

  .minesweeper-options {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .minesweeper-option-small-button {
    height: 8vw;
    width: 8vw;
    border-left: 2px solid var(--minesweeper-lighter-shade);
    border-top: 2px solid var(--minesweeper-lighter-shade);
    border-right: 2px solid var(--minesweeper-darker-shade);
    border-bottom: 2px solid var(--minesweeper-darker-shade);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--minesweeper-darker-shade);
    font-size: 25px;
    cursor: pointer;
  }

  .how-to-play-modal {
    max-height: 100vh;
    overflow-y: auto;
    width: 300px;
    color: white;
    font-size: 16px;
    padding: 20px;
    font-weight: 200;
    border: 1px solid rgb(82, 82, 82);
  }

  .how-to-play-title {
    text-align: center;
    width: 100%;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .how-to-play-flag {
    height: 30px;
    width: 30px;
    color: red;
    float: left;
    margin: 5px 10px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: var(--minesweeper-main-color);
    border-left: 3px solid var(--minesweeper-lighter-shade);
    border-top: 3px solid var(--minesweeper-lighter-shade);
    border-right: 3px solid var(--minesweeper-darker-shade);
    border-bottom: 3px solid var(--minesweeper-darker-shade);
  }

  .how-to-play-bomb {
    height: 30px;
    width: 30px;
    float: left;
    margin: 5px 10px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: red;
    color: black;
  }
  .how-to-play-number {
    height: 30px;
    width: 30px;
    float: left;
    margin: 5px 10px 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: var(--minesweeper-semi-dark-shade);
    font-family: "Roboto Mono";
    font-weight: 1000;
    font-size: 25px;
    color: rgb(1, 113, 1);
    border: 1px solid var(--minesweeper-darker-shade);
  }

  .minesweeper-score-modal {
    width: 280px;
    padding: 20px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .minesweeper-score-title {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .minesweeper-score-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .minesweeper-score-field {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .minesweeper-score-field-value {
    font-size: 25px;
    font-weight: bold;
  }

  .minesweeper-score-field-label {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 200;
  }

  .minesweeper-fastest-time {
    text-align: center;
    margin-top: 20px;
  }
  .minesweeper-fastest-time-title {
    font-size: 18px;
    font-weight: bold;
  }

  .minesweeper-fastest-time-timer {
    margin-top: 5px;
    font-family: "Digital-Clock";
    font-size: 25px;
  }

  .emoji-button {
    height: 9vw;
    width: 9vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid var(--minesweeper-lighter-shade);
    border-top: 2px solid var(--minesweeper-lighter-shade);
    border-right: 2px solid var(--minesweeper-darker-shade);
    border-bottom: 2px solid var(--minesweeper-darker-shade);
  }

  .emoji-face {
    font-size: 5.8vw;
    margin-bottom: 0.4vw;
  }
}

/* * {
    outline: 1px solid green ;
} */

.music-quiz {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spotify-login-button {
  padding: 10px 25px;
  text-decoration: none;
  color: white;
  background-color: rgb(29, 185, 84);
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 600;
  border-radius: 25px;
}

.test {
  background-color: #ffffff;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.playlist-loading-indicator {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid rgba(168, 145, 66, 0.1);
  border-top: 2px solid rgb(168, 145, 66);
  animation: 1s linear infinite spin-animation;
}

.playlist-outer {
  width: 300px;
  height: 300px;
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  border-radius: 20px;
}

.playlist-list {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.playlist-image-outer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playlist-image {
  width: 200px;
  height: 200px;
}

.playlist-title {
  width: 100%;
  color: white;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.back-to-playlists {
  width: 100px;
  height: 200px;
  color: white;
  font-size: 30px;
}

.playlist-button {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.error-modal {
  width: min(350px, 95vw);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  background-color: black;
  border: 1px solid red;
  padding: 10px 20px 30px 20px;
  border-radius: 5px;
  font-size: 20px;
}

.error-modal-title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  color: red;
  margin-bottom: 20px;
  font-weight: 600;
}

.player-edit-button {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px solid white;
}

.player-edit-button.edit {
  background-color: orange;

  font-size: 15px;
}
.player-edit-button.delete {
  background-color: red;
  font-size: 20px;
}

.player-edit-button.save {
  width: 35px !important;
  height: 35px !important;
  background-color: rgb(29, 185, 84);
  border: 2px solid white;
  font-size: 20px;
}

.expire-modal {
  width: 350px;
  font-size: 20px;
  color: white;
  border: 1px solid red;
  padding: 0 20px 30px 20px;
}

.expire-modal-title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: red;
  padding: 20px 0;
}

.expire-modal-buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.expire-modal-button {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 0;
  border: 2px solid white;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 600;
}

.expire-modal-button.relogin {
  background-color: rgb(29, 185, 84);
}

@media only screen and (max-width: 400px) {
  .music-quiz {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spotify-login-button {
    padding: 2vw 5vw;
    text-decoration: none;
    color: white;
    background-color: rgb(29, 185, 84);
    display: flex;
    flex-direction: row;
    grid-gap: 4vw;
    gap: 4vw;
    justify-content: center;
    align-items: center;
    font-size: 8vw;
    font-weight: 600;
    border-radius: 5vw;
  }

  .test {
    background-color: #ffffff;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .playlist-loading-indicator {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid rgba(168, 145, 66, 0.1);
    border-top: 2px solid rgb(168, 145, 66);
    animation: 1s linear infinite spin-animation;
  }

  .playlist-outer {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    color: white;
    padding: 5vw;
    border-radius: 5vw;
  }

  .playlist-list {
    width: 100vw;
    padding: 5vw 5vw;
    display: flex;
    flex-direction: column;
    grid-gap: 5vw;
    gap: 5vw;
  }

  .playlist-image-outer {
    width: 100%;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .playlist-image {
    width: 70vw;
    height: 70vw;
  }

  .playlist-title {
    width: 100%;
    color: white;
    font-size: 8vw;
    font-weight: 600;
    text-align: center;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2.5vw;
  }

  .back-to-playlists {
    width: 100px;
    height: 200px;
    color: white;
    font-size: 30px;
  }

  .playlist-button {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }

  .error-modal {
    width: min(350px, 95vw);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    background-color: black;
    border: 1px solid red;
    padding: 3vw 6vw 9vw 6vw;
    border-radius: 1vw;
    font-size: 6.5vw;
  }

  .error-modal-title {
    width: 100%;
    text-align: center;
    font-size: 8vw;
    color: red;
    margin-bottom: 5vw;
    font-weight: 700;
  }

  .player-edit-buttons {
    position: absolute;
    right: 0;
    width: 17.5vw;
    height: 16vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .player-edit-button {
    height: 5.5vw;
    width: 5.5vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 1px solid white;
  }

  .player-edit-button.edit {
    background-color: orange;

    font-size: 3.5vw;
  }
  .player-edit-button.delete {
    background-color: red;
    font-size: 5vw;
  }

  .player-edit-button.save {
    width: 8vw !important;
    height: 8vw !important;
    background-color: rgb(29, 185, 84);
    border: 2px solid white;
    font-size: 5vw;
  }
}

/* * {
  overflow-x: hidden;
  overflow-y: auto;
} */

.playlist_search_screen {
    min-height: 100vh;
    align-items: center;
    padding: 0;
  }
  
  .playlist-searchbar {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;
    padding: 2vw;
  }
  

  .playlist-search-input {
    width: 95%;
    background: transparent;
    border: 1px solid white;
    height: 30px;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    padding: 0 10px;
  }

  .playlist-search-input:focus {
    border: 2px solid rgb(29, 185, 84);
  }

  input:focus {
    outline: none;
  }
  
  .playlist-search-row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .playlist-search-icon-outer {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
  }
  .temp {
    width: 100%;
  }
  
  .extend-list-row {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .extend-list-button {
    color: white;
    font-size: 40px;
  }
  .playlist-search-placeholder-outer {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;
  }
  
  .playlist-search-placeholder {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3vw;
    color:rgb(29, 185, 84);
    border: 2px solid rgb(29, 185, 84);
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 1.5vw;
  }

  .playlist-edit-save-button {
    position: fixed;
    background-color: rgb(29, 185, 84);
    bottom: 50px;
    right: 50px;
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    color: white;
    border: 2px solid white;
  }

  .playlist-manage-topbar {
    position: relative;
     width: 100vw;
     padding: 0.5vw 0;
     font-size: 2vw;
     border-bottom: 2px solid white;
     text-align: center;
     color: white;
     font-weight: 1000;
     display: flex;
     justify-content: center;
     align-items: center;
  }

  .back-button {
    position: absolute;
    left: 1vw;
    font-size: 2.6vw;
  }

  @media only screen and (max-width: 400px) {
    .playlist-edit-save-button {
      position: fixed;
      background-color: rgb(29, 185, 84);
      bottom: 50px;
      right: 50px;
      width: 20vw;
      height: 20vw;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8vw;
      color: white;
      border: 2px solid white;
    }

    .playlist-manage-topbar {
      position: relative;
       width: 100vw;
       padding: 2vw 0;
       font-size: 6vw;
       border-bottom: 2px solid white;
       text-align: center;
       color: white;
       font-weight: 1000;
       display: flex;
       justify-content: center;
       align-items: center;
    }
  
    .back-button {
      position: absolute;
      left: 1vw;
      font-size: 8vw;
      color: white;
    }

    .playlist_search_screen {
      min-height: 100vh;
      max-width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .playlist-searchbar {
      width: 95vw;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-top: 20px;
    }
    
    .playlist-search-input {
      width:85vw;
      background: transparent;
      border: 1px solid white;
      font-size: 5vw;
      color: white;
      border-radius: 3vw;
      padding: 0 3vw;
    }
    
    .playlist-search-row {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .playlist-search-icon-outer {
      width: 10vw;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 5vw;
    }

    .playlist-search-placeholder {
      width: 80vw;
      padding: 5vw;
      color:rgb(29, 185, 84);
      border: 2px solid rgb(29, 185, 84);
      border-radius: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 5vw;
    }
  }
.playlist-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  justify-content: space-between;
  grid-row-gap: 1vw;
  row-gap: 1vw;
  padding: 2vw;
}

.playlist-search-result.inactive {
  background: linear-gradient(90deg, rgb(36, 36, 36) 0%, rgb(67, 67, 67) 50%, rgb(36, 36, 36) 100%) !important;
}

.playlist-search-result {
  width: 23vw;
  background: linear-gradient(90deg, rgba(168, 145, 66, 1) 0%, rgb(101, 87, 42) 50%, rgba(168, 145, 66, 1) 100%);
  box-shadow: 0px 0px 0.2vw 0.1vw rgba(188, 183, 168, 0.5);
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw 0 0 0;
  border: 2px solid black;
}

.playlist-search-image {
  width: 20vw;
  height: 20vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(29, 185, 84);
  font-size: 4vw;
}

.playlist-search-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 1.6vw;
  height: 5vw;
  width: 100%;
  padding: 0 4vw;
  font-weight: 500;
  text-align: center;
  margin-top: 1vw;
  text-shadow: black 0px 0px 2px;
}

@media only screen and (max-width: 400px) {
  .playlist-grid {
    margin-top: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-gap: 6vw;
    gap: 6vw;
  }

  .playlist-search-result {
    width: 90vw;
    height: 108vw;
  background: linear-gradient(90deg, rgba(168, 145, 66, 1) 0%, rgb(101, 87, 42) 50%, rgba(168, 145, 66, 1) 100%);    color: white;
    box-shadow: 0px 0px 2vw 0.5vw rgba(188, 183, 168, 0.5);
  border-radius: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4vw 0 0 0;
  }

  .playlist-search-image {
    width: 75vw;
    height: 75vw;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(29, 185, 84);
    font-size: 4vw;
  }

  .playlist-search-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    font-size: 6.5vw;
    width: 100%;
    height: 18vw;
    padding: 0 4vw;
    font-weight: 500;
    text-align: center;
    margin-top: 5vw;
  }
}

.save_playlist_modal {
  width: 60vw;
  padding: 2vw;
  border: 3px solid rgb(29, 185, 84);
  font-size: 2vw;
  color: white;
  text-align: center;
  font-weight: 800;
}

.save_playlist_remove_title {
  width: 100%;
  font-size: 2vw;
  font-weight: 1000;
  margin: 1vw 0;
  color: red;
  text-decoration: underline;
}

.save_playlist_add_title {
  width: 100%;
  font-size: 2vw;
  font-weight: 1000;
  margin: 1vw 0;
  color: rgb(29, 185, 84);
  text-decoration: underline;
}

.save_playlist_list_item {
  font-size: 2vw;
  color: white;
  font-weight: 500;
}

.save_playlist_button_row {
  width: 100%;
  margin-top: 4vw;
  display: flex;
  flex-direction: row;
}

.save_playlist_button {
  width: 50%;
  margin: 1vw;
  border: 2px solid white;
  padding: 1vw 0;
  border-radius: 0.5vw;
  font-size: 2vw;
  font-weight: 800;
}

.save_playlist_button.save {
  background-color: rgb(29, 185, 84);
}

.save_playlist_button.cancel {
  background-color: rgb(54, 54, 54);
}

@media only screen and (max-width: 400px) {
  .save_playlist_modal {
    width: 90vw;
    padding: 3vw;
    border: 3px solid rgb(29, 185, 84);
    font-size: 5vw;
    color: white;
    text-align: center;
    font-weight: 800;
  }

  .save_playlist_remove_title {
    width: 100%;
    font-size: 5vw;
    font-weight: 1000;
    margin: 2vw 0;
    color: red;
    text-decoration: underline;
  }

  .save_playlist_add_title {
    width: 100%;
    font-size: 5vw;
    font-weight: 1000;
    margin: 2vw 0;
    color: rgb(29, 185, 84);
    text-decoration: underline;
  }

  .save_playlist_list_item {
    font-size: 5vw;
    color: white;
    font-weight: 500;
  }
  
  .save_playlist_button_row {
    width: 100%;
    margin-top: 4vw;
    display: flex;
    flex-direction: row;
  }
  
  .save_playlist_button {
    width: 50%;
    margin: 2vw;
    border: 2px solid white;
    padding: 2vw 0;
    border-radius: 1vw;
    font-size: 5vw;
    font-weight: 800;
  }
}

.choose_playlist_screen {
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.playlist_edit_button {
    position: absolute;
    right: 3vw;
    font-size: 3vw;
  }

  @media only screen and (max-width: 400px) {
    .playlist_edit_button {
        position: absolute;
        right: 3vw;
        font-size: 8vw;
      }
  }
.song-player {
  min-height: 100vh;
  width: 400px;
  color: white;
}

.wave-outer {
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.wave-inner {
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.wave-item {
  background-color: rgb(29, 185, 84);
  height: 30px;
  width: 10px;
  border-radius: 10px;
}

#wave .wave-item.playing:nth-child(1) {
  animation: 2s linear wave infinite;
  animation-delay: 0.6s;
}

#wave .wave-item.playing:nth-child(2) {
  animation: 1.5s linear wave infinite;
  animation-delay: 0.2s;
}

#wave .wave-item.playing:nth-child(3) {
  animation: 1.5s linear wave infinite;
  animation-delay: 0.4s;
}

#wave .wave-item.playing:nth-child(4) {
  animation: 1.5s linear wave infinite;
  animation-delay: 1s;
}

#wave .wave-item.playing:nth-child(5) {
  animation: 1.5s linear wave infinite;
  animation-delay: 0.2s;
}

#wave .wave-item.playing:nth-child(6) {
  animation: 1.5s linear wave infinite;
}

#wave .wave-item.playing:nth-child(7) {
  animation: 1.5s linear wave infinite;
  animation-delay: 0.6s;
}

#wave .wave-item.playing:nth-child(8) {
  animation: 1.5s linear wave infinite;
  animation-delay: 0.8s;
}

.song-reveal {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes wave {
  0% {
    transform: scaleY(1);
    border-radius: 10px;
  }
  50% {
    transform: scaleY(3);
    border-radius: 100px;
  }
  100% {
    transform: scaleY(1);
    border-radius: 10px;
  }
}

.song-reveal-button {
  width: 80%;
  padding: 10px 0;
  background-color: rgb(29, 185, 84);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 10px;
  font-weight: 600;
  border: 2px solid white;
}

.play-pause-button-outer {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.play-pause-button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgb(29, 185, 84);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
  border: 2px solid white;
}

.next-button {
  font-size: 20px;
  padding: 10px 20px;
  background-color: rgb(29, 185, 84);
  border-radius: 10px;
  font-weight: 600;
  border: 2px solid white;
}

.album-cover {
  width: min(40vh, 100%);
  aspect-ratio: 1;
  align-self: center;
  justify-self: center;
}

.song-reveal-solution {
  height: 40vh;
  width: 100%;
  font-size: 30px;
  color: white;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 400px) {
  .song-player {
    min-height: 100vh;
    width: 100vw;
    color: white;
  }

  .play-pause-button {
    width: 4vw;
    height: 6vw;
    border-radius: 50%;
    background-color: rgb(29, 185, 84);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    color: white;
    border: 2px solid white;
  }

  .song-reveal {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .play-pause-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgb(29, 185, 84);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    border: 2px solid white;
  }

  .song-reveal {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .song-reveal-button {
    width: 80%;
    padding: 10px 0;
    background-color: rgb(29, 185, 84);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8vw;
    border-radius: 10px;
    font-weight: 600;
    border: 2px solid white;
  }

  .wave-outer {
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 7vw;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }

  .wave-inner {
    width: 80vw;
    height: 25vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 2.5vw;
    gap: 2.5vw;
  }

  .play-pause-button-outer {
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  @keyframes wave {
    0% {
      transform: scaleY(1);
      border-radius: 2.5vw;
    }
    50% {
      transform: scaleY(3);
      border-radius: 25vw;
    }
    100% {
      transform: scaleY(1);
      border-radius: 2.5vw;
    }
  }

  .wave-item {
    background-color: rgb(29, 185, 84);
    height: 8vw;
    width: 2.5vw;
    border-radius: 2.5vw;
  }

  #wave .wave-item.playing:nth-child(1) {
    animation: 2s linear wave infinite;
    animation-delay: 0.6s;
  }

  #wave .wave-item.playing:nth-child(2) {
    animation: 1.5s linear wave infinite;
    animation-delay: 0.2s;
  }

  #wave .wave-item.playing:nth-child(3) {
    animation: 1.5s linear wave infinite;
    animation-delay: 0.4s;
  }

  #wave .wave-item.playing:nth-child(4) {
    animation: 1.5s linear wave infinite;
    animation-delay: 1s;
  }

  #wave .wave-item.playing:nth-child(5) {
    animation: 1.5s linear wave infinite;
    animation-delay: 0.2s;
  }

  #wave .wave-item.playing:nth-child(6) {
    animation: 1.5s linear wave infinite;
  }

  #wave .wave-item.playing:nth-child(7) {
    animation: 1.5s linear wave infinite;
    animation-delay: 0.6s;
  }

  #wave .wave-item.playing:nth-child(8) {
    animation: 1.5s linear wave infinite;
    animation-delay: 0.8s;
  }

  .album-cover {
    width: min(40vh, 100%);
    aspect-ratio: 1;
    align-self: center;
    justify-self: center;
  }

  .song-reveal-solution {
    height: 40vh;
    width: 100%;
    font-size: 30px;
    color: white;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .next-button {
    font-size: 20px;
    padding: 10px 20px;
    background-color: rgb(29, 185, 84);
    border-radius: 10px;
    font-weight: 600;
    border: 2px solid white;
  }
}

.score-screen {
    width: 400px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .score-screen-inner {
    width: 400px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
  }
  
  .score-screen-title {
    padding: 20px;
    font-size: 30px;
    color: white;
    font-weight: 600;
  }

  .player-score {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .player-score-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-overflow: clip;
    font-size: 25px;
    font-weight: 600;
  }
  
  .player-score-points {
    width: 20%;
    font-size: 40px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .player-points-buttons {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .player-points-button {
    width: 50px;
    height: 35px;
    background-color: rgb(29, 185, 84);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid white;
    font-weight: 700;
  }
  
  .player-points-button.chosen {
    background-color: orange;
  }
  .player-points-button.disabled {
    background-color: grey;
  }

  .save-and-continue-outer {
    width: 100%;
    font-size: 25px;
    color: white;
    display: flex;
    height: 20vh;
    justify-content: center;
    align-items: center;
  }
  
  .save-and-continue-inner {
    width: 80%;
    background-color: rgb(29, 185, 84);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid white;
    font-weight: 600;
  }

  @media only screen and (max-width: 400px) {
    .score-screen {
        width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    
      .player-score {
        width: 100%;
        padding: 0 3vw;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    
      .score-screen-title {
        padding: 5vw;
        font-size: 7.5vw;
        color: white;
        font-weight: 600;
      }
    
      .player-score-name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50%;
        text-overflow: clip;
        font-size: 7vw;
        font-weight: 600;
      }
    
      .player-score-points {
        width: 20%;
        font-size: 10vw;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .player-points-buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }
    
      .player-points-button {
        width: 12vw;
        height: 9vw;
        background-color: rgb(29, 185, 84);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5vw;
        border-radius: 4vw;
        border: 2px solid white;
        font-weight: 700;
      }
    
      .player-points-button.chosen {
        background-color: orange;
      }
      .player-points-button.disabled {
        background-color: grey;
      }
    
      .score-screen-inner {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        grid-gap: 5vw;
        gap: 5vw;
        align-items: center;
      }

      .save-and-continue-outer {
        width: 100%;
        font-size: 6vw;
        color: white;
        display: flex;
        height: 20vh;
        justify-content: center;
        align-items: center;
      }
    
      .save-and-continue-inner {
        width: 80%;
        background-color: rgb(29, 185, 84);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.5vw;
        border-radius: 2.5vw;
        border: 2px solid white;
        font-weight: 600;
      }
  }
.game-choice-screen {
    width: 400px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
  }
  
  .game-choice-button {
    margin: 20px 0;
    width: 300px;
    padding: 15px 0;
    background-color: rgb(29, 185, 84);
    border: 2px solid white;
    border-radius: 10px;
    font-weight: 700;
  }

  @media only screen and (max-width: 400px) {
    .game-choice-screen {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 7.5vw;
        color: white;
        font-weight: 600;
      }
    
      .game-choice-button {
        margin: 5vw 0;
        width: 75vw;
        padding: 4vw 0;
        background-color: rgb(29, 185, 84);
        border: 2px solid white;
        border-radius: 2.5vw;
        font-weight: 700;
      }
  }
.overview-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 0 0 60px;
  color: white;
  font-size: 30px;
}

.quiz-master-indication {
  position: absolute;
  left: 0;
  width: 60px;
  padding: 0 10px;
  font-size: 10px;
  color: rgb(29, 185, 84);
  text-align: center;
  font-weight: 600;
}

.overview-player-name {
  width: 250px;
  font-size: 30px;
  font-weight: 600;
  padding: 0 0 0 20px;
}

.overview-player-score {
  width: 80px;
  font-size: 40px;
  font-weight: 800;
}

@media only screen and (max-width: 400px) {
  .quiz-master-indication {
    position: absolute;
    left: 0;
    width: 15vw;
    padding: 0 2.5vw;
    font-size: 2.5vw;
    color: rgb(29, 185, 84);
    text-align: center;
    font-weight: 600;
  }

  .overview-player-name {
    width: 57.5vw;
    font-size: 7.5vw;
    font-weight: 600;
    padding: 0 0 0 5vw;
  }

  .overview-player-score {
    width: 20vw;
    font-size: 10vw;
    font-weight: 800;
  }

  .overview-player {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 5vw 0 15vw;
    color: white;
    font-size: 7.5vw;
  }
}

.player-screen {
    display: flex;
    width: 400px;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
  }
  
  .player-selection {
    width: 100%;
    min-height: 80vh;
    color: white;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
  }
  
  .player-screen-title {
    color: white;
    padding: 20px 0;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
  .player {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    border: 2px solid rgb(29, 185, 84);
    padding: 10px;
    grid-gap: 20px;
    gap: 20px;
    border-radius: 10px;
    background-color: rgb(29, 185, 84);
    font-size: 25px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 10px;
    border: 2px solid white;
  }
  
  .player-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 3px solid white;
    color: white;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .player-edit-buttons {
    position: absolute;
    right: 0;
    width: 70px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .add-player-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(29, 185, 84);
    border: 2px solid white;
    border-radius: 10px;
    padding: 5px 10px;
  }
  
  .player-name-input {
    width: 300px;
    height: 100%;
    font-size: 25px;
    background-color: rgb(29, 185, 84);
    color: white;
    border: 2px solid white;
    padding: 0 0 0 10px;
  }

  @media only screen and (max-width: 400px) {

    .player-screen {
        display: flex;
        width: 100vw;
        min-height: 100vh;
        flex-direction: column;
        align-items: center;
      }
    
      .player-selection {
        width: 100%;
        min-height: 80vh;
        color: white;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        align-items: center;
      }
    
      .player-screen-title {
        color: white;
        padding: 20px 0;
        font-size: 9vw;
        font-weight: 600;
        text-align: center;
      }
      .player {
        width: 95vw;
        position: relative;
        display: flex;
        flex-direction: row;
        color: white;
        height: 16vw;
        display: flex;
        align-items: center;
        border: 2px solid rgb(29, 185, 84);
        padding: 10px;
        grid-gap: 4vw;
        gap: 4vw;
        border-radius: 2.5vw;
        background-color: rgb(29, 185, 84);
        font-size: 6vw;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2.5vw 17.5vw 2.5vw 2.5vw;
        border: 2px solid white;
      }
    
      .player-icon {
        height: 10vw;
        width: 10vw;
        border-radius: 50%;
        border: 3px solid white;
        color: white;
        font-size: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .add-player-button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-gap: 2.5vw;
        gap: 2.5vw;
        font-size: 5vw;
        font-weight: 600;
        background-color: rgb(29, 185, 84);
        border: 2px solid white;
        border-radius: 2.5vw;
        padding: 1vw 2vw;
      }
    
      .player-name-input {
        width: 80%;
        height: 100%;
        font-size: 25px;
        background-color: rgb(29, 185, 84);
        color: white;
        border: 2px solid white;
        padding: 0 0 0 10px;
      }
  }
.quiz-master-screen {
  width: 400px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.quiz-master-screen-inner {
  width: 400px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.quiz-master-screen-name {
  font-size: 60px;
  font-weight: 800;
  color: rgb(29, 185, 84);
}

.quiz-master-screen-smaller-text {
  margin-top: 20px;
  font-size: 30px;
}

@media only screen and (max-width: 400px) {
  .quiz-master-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 10vw;
    color: white;
    font-weight: 600;
  }

  .quiz-master-screen-inner {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10vw;
    color: white;
    font-weight: 600;
  }

  .quiz-master-screen-name {
    font-size: 15vw;
    font-weight: 800;
    color: rgb(29, 185, 84);
  }

  .quiz-master-screen-smaller-text {
    margin-top: 5vw;
    font-size: 7.5vw;
  }
}

* {
  --tetris-block-border-width: 0.3vw;
  --tetris-font: "Silkscreen"; /* Russo One */
}

.tetris_outer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.tetris_inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@keyframes text-slide-in {
    0% {
      margin-top: -50vw;
    }
    100% {
       margin-top: -5vw;
    }
}

.tetris_start_screen {
  position: absolute;
  height: 30vw;
  width: 15vw;
  background-color: rgb(115, 165, 122);
  display: flex;
  font-family: var(--tetris-font);
  flex-direction: column;
  align-items: center;
  padding: 2vw 0;
  color: white;
  font-weight: 1000;
  font-size: 1.5vw;
  text-align: center;
  text-shadow: black 0px 0px 0.5vw;
}

@keyframes pulsing-message {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.congratulation_message {
  position: absolute;
  top: 1vw;
  font-size: 1vw;
  color: rgb(0, 255, 0);
  text-shadow: black 0px 0px 0.1vw;
  animation: pulsing-message 2s infinite;
  text-align: center;
  width: 100%;
}
.tetris_start_screen .highscores{
  width: 10vw;
  margin-top: 1vw;
  border: 0.2vw solid white;
} 

.tetris_start_screen .highscores div {
  width: 100%;
  height: 3vw;
  display: flex;
  align-items: center;
  font-size: 1.5vw;
  padding: 0 1vw;
}

.tetris_start_screen .highscores div:nth-child(even) {
  background-color: rgb(94, 136, 100) ;
}
.tetris_start_screen .highscores div:nth-child(odd) {
  background-color: rgb(133, 192, 141) ;
}

.tetris_start_screen .play_button {
  margin-top: 2vw;
  background-color: rgb(17, 201, 17);
  width: 10vw;
  height: 4vw;
  border-radius: 1vw;
  border: 0.4vw solid rgb(20, 110, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 1000;
  font-size: 2vw;
  text-shadow: rgb(20, 110, 20) 0px 0px 0.5vw;
  box-shadow: 0px 0px 1vw rgb(20, 110, 20);
}

.tetris_gameover_screen {
  font-family: var(--tetris-font);
  position: absolute;
  top: 0;
  left: 0;
  height: 30vw;
  width: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 1000;
  color: white;
    text-shadow: red 0.1vw 0.1vw 0.3vw;
  font-size: 2.5vw;
  background-color: rgba(97, 96, 96, 0.8);
  text-align: center;
  overflow: hidden;
}

@keyframes restart-appear {
0% {
  opacity: 0;
  visibility: hidden;
}
50% {
  opacity: 0;
}
    100% {
      opacity: 1;
      visibility: visible;
    }
}
.tetris_gameover_screen .restart-button {
  position: absolute;
  width: 9vw;
  top: 17vw;
  border: 0.2vw solid red;
  border-radius: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1vw;
  color: red;
      text-shadow: rgb(255, 175, 175) 0.05vw 0.05vw 0.1vw;
      background-color: white;
      padding: 0.3vw;
      visibility: hidden;
      animation: restart-appear 4s forwards;
}

.restart-icon {
  background-color: red;
  width: 1.5vw;
  height: 1.5vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.3vw;
}

.tetris-gameover-slide-in {
  position: absolute;
  animation: text-slide-in 2s linear;
  margin-top: -5vw;
}


.tetris_screen {
  outline: 0.2vw solid white;
  position: relative;
  margin-top: 2vw;
  height: 30vw;
  background-color: rgb(115, 165, 122);
  width: 15vw;
  display: flex;
  flex-direction: column;
}

.next_blocks {
  position: absolute;
  right: -6.2vw;
  display: flex;
  width: 6vw;
  height: 14vw;
  background-color: rgb(115, 165, 122);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vw;
  border: 0.2vw solid white;
  border-left: none;
  border-radius: 0 0.5vw 0.5vw 0;
  top: 2vw;
}

.new_block .pixel {
  width: 1vw;
  height: 1vw;
}

.next_blocks .pixel.filled {
  background-color: red;
}

.new_block {
  display: grid;
}

.new_block.long {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.new_block.square {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.new_block.other {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.new_block .pixel.filled[value="1"] {
  background-color: rgb(0, 240, 240);
  border-top: var(--tetris-block-border-width) solid rgb(96, 246, 246);
  border-left: var(--tetris-block-border-width) solid rgb(49, 243, 243);
  border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 123);
  border-right: var(--tetris-block-border-width) solid rgb(0, 167, 167);
}

.new_block .pixel.filled[value="2"] {
  background-color: rgb(0, 0, 240);
  border-top: var(--tetris-block-border-width) solid rgb(96, 96, 246);
  border-left: var(--tetris-block-border-width) solid rgb(49, 49, 243);
  border-bottom: var(--tetris-block-border-width) solid rgb(0, 0, 123);
  border-right: var(--tetris-block-border-width) solid rgb(0, 0, 167);
}

.new_block .pixel.filled[value="3"] {
  background-color: rgb(240, 160, 0);
  border-top: var(--tetris-block-border-width) solid rgb(246, 196, 96);
  border-left: var(--tetris-block-border-width) solid rgb(243, 178, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(123, 82, 0);
  border-right: var(--tetris-block-border-width) solid rgb(167, 111, 0);
}

.new_block .pixel.filled[value="4"] {
  background-color: rgb(240, 240, 0);
  border-top: var(--tetris-block-border-width) solid rgb(246, 246, 96);
  border-left: var(--tetris-block-border-width) solid rgb(243, 243, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(123, 123, 0);
  border-right: var(--tetris-block-border-width) solid rgb(167, 167, 0);
}

.new_block .pixel.filled[value="5"] {
  background-color: rgb(0, 240, 0);
  border-top: var(--tetris-block-border-width) solid rgb(96, 246, 96);
  border-left: var(--tetris-block-border-width) solid rgb(49, 243, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 0);
  border-right: var(--tetris-block-border-width) solid rgb(0, 167, 0);
}

.new_block .pixel.filled[value="6"] {
  background-color: rgb(160, 0, 240);
  border-top: var(--tetris-block-border-width) solid rgb(196, 96, 246);
  border-left: var(--tetris-block-border-width) solid rgb(178, 49, 243);
  border-bottom: var(--tetris-block-border-width) solid rgb(82, 0, 123);
  border-right: var(--tetris-block-border-width) solid rgb(111, 0, 167);
}

.new_block .pixel.filled[value="7"] {
  background-color: rgb(240, 0, 0);
  border-top: var(--tetris-block-border-width) solid rgb(246, 96, 96);
  border-left: var(--tetris-block-border-width) solid rgb(243, 49, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(123, 0, 0);
  border-right: var(--tetris-block-border-width) solid rgb(167, 0, 0);
}

.tetris_scores {
  font-family: var(--tetris-font);
  height: 14vw;
  position: absolute;
  left: -6.1vw;
  top: 2vw;
  color: white;
  border: 0.2vw solid white;
  border-right: none;
  background-color: rgb(115, 165, 122);
  width: 6vw;
  text-align: center;
  font-size: 1.2vw;
  border-radius: 0.5vw 0 0 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5vw 0;
}

.scores_title {
  font-weight: 500;
    text-shadow: black 0px 0px 0.4vw;
}

.scores_value {
  margin: 0.2vw;
  background-color: black;
  border: 1px solid white;
  font-weight: 100;
  font-size: 1.2vw;
  width: 5.3vw
}

.tetris-row {
  display: flex;
  flex-direction: row;
  height: 1.5vw;
}

.tetris_pixel {
  font-size: 0.5vw;
  width: 1.5vw;
  height: 1.5vw;
}

.tetris_pixel.filled {
  background-color: red;
}
@keyframes test {
  0% {
    background-color: black !important;
  }
  100% {
    background-color: white !important;
  }
}

/* .tetris-row.filled .tetris_pixel {
  animation: test 1s;
} */

.tetris-row.filled .tetris_pixel {
  background-color: white !important;
}

/* .tetris-row.filled .tetris_pixel:nth-child(0){
  background-color: black !important;
  transition: background-color 1000ms linear;
} */
.tetris-row.filled .tetris_pixel:nth-child(1) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
}
.tetris-row.filled .tetris_pixel:nth-child(2) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  transition-delay: 100ms;
  border: none;
}
.tetris-row.filled .tetris_pixel:nth-child(3) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 200ms;
}
.tetris-row.filled .tetris_pixel:nth-child(4) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 300ms;
}
.tetris-row.filled .tetris_pixel:nth-child(5) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 400ms;
}
.tetris-row.filled .tetris_pixel:nth-child(6) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 500ms;
}
.tetris-row.filled .tetris_pixel:nth-child(7) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 600ms;
}
.tetris-row.filled .tetris_pixel:nth-child(8) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 700ms;
}
.tetris-row.filled .tetris_pixel:nth-child(9) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 800ms;
}
.tetris-row.filled .tetris_pixel:nth-child(10) {
  background-color: rgb(115, 165, 122) !important;
  transition: background-color 100ms linear;
  border: none;
  transition-delay: 900ms;
}

.tetris_pixel.filled[value="1"] {
  background-color: rgb(0, 240, 240);
  border-top: var(--tetris-block-border-width) solid rgb(96, 246, 246);
  border-left: var(--tetris-block-border-width) solid rgb(49, 243, 243);
  border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 123);
  border-right: var(--tetris-block-border-width) solid rgb(0, 167, 167);
}
.tetris_pixel.filled[value="2"] {
  background-color: rgb(0, 0, 240);
  border-top: var(--tetris-block-border-width) solid rgb(96, 96, 246);
  border-left: var(--tetris-block-border-width) solid rgb(49, 49, 243);
  border-bottom: var(--tetris-block-border-width) solid rgb(0, 0, 123);
  border-right: var(--tetris-block-border-width) solid rgb(0, 0, 167);
}
.tetris_pixel.filled[value="3"] {
  background-color: rgb(240, 160, 0);
  border-top: var(--tetris-block-border-width) solid rgb(246, 196, 96);
  border-left: var(--tetris-block-border-width) solid rgb(243, 178, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(123, 82, 0);
  border-right: var(--tetris-block-border-width) solid rgb(167, 111, 0);
}
.tetris_pixel.filled[value="4"] {
  background-color: rgb(240, 240, 0);
  border-top: var(--tetris-block-border-width) solid rgb(246, 246, 96);
  border-left: var(--tetris-block-border-width) solid rgb(243, 243, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(123, 123, 0);
  border-right: var(--tetris-block-border-width) solid rgb(167, 167, 0);
}
.tetris_pixel.filled[value="5"] {
  background-color: rgb(0, 240, 0);
  border-top: var(--tetris-block-border-width) solid rgb(96, 246, 96);
  border-left: var(--tetris-block-border-width) solid rgb(49, 243, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 0);
  border-right: var(--tetris-block-border-width) solid rgb(0, 167, 0);
}
.tetris_pixel.filled[value="6"] {
  background-color: rgb(160, 0, 240);
  border-top: var(--tetris-block-border-width) solid rgb(196, 96, 246);
  border-left: var(--tetris-block-border-width) solid rgb(178, 49, 243);
  border-bottom: var(--tetris-block-border-width) solid rgb(82, 0, 123);
  border-right: var(--tetris-block-border-width) solid rgb(111, 0, 167);
}
.tetris_pixel.filled[value="7"] {
  background-color: rgb(240, 0, 0);
  border-top: var(--tetris-block-border-width) solid rgb(246, 96, 96);
  border-left: var(--tetris-block-border-width) solid rgb(243, 49, 49);
  border-bottom: var(--tetris-block-border-width) solid rgb(123, 0, 0);
  border-right: var(--tetris-block-border-width) solid rgb(167, 0, 0);
}

.tetris_buttons {
  margin-top: 1vw;
  position: relative;
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  background-color: rgb(221, 37, 37);
  margin-top: 2vw;
  outline: 0.3vw solid rgb(163, 28, 28);
}

.tetris_button {
  position: absolute;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  background-color: rgb(207, 39, 39);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5vw;
  color: white;
  cursor: pointer;
  border: 0.3vw solid rgb(163, 28, 28);
}

.tetris_button.up {
  top: 0.5vw;
  left: 4vw;
  font-size: 3vw;
}
.tetris_button.down {
  bottom: 0.5vw;
  left: 4vw;
  padding-top: 0.2vw;
}
.tetris_button.left {
  top: 4vw;
  left: 0.5vw;
  padding-right: 0.2vw;
}
.tetris_button.right {
  top: 4vw;
  right: 0.5vw;
  padding-left: 0.2vw;
}


.pause_button {
  background-color: black;
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  display: flex;
  font-size: 2vw;
  color: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  margin-top: 0.3vw;
}

@media only screen and (max-width: 600px) {
  * {
    --tetris-block-border-width: 1.2vw;
  }


  .tetris_screen {
    outline: 1vw solid white;
    margin-top: 2vw;
    height: 110vw;
    width: 55vw;
  }

  .next_blocks {
    right: -20.8vw;
    width: 20vw;
    height: 50vw;
    padding: 3vw 0;
    border: 1vw solid white;
    border-left: none;
    border-radius: 0 2vw 2vw 0;
    top: 10vw;
  }

  .new_block .pixel {
    width: 4vw;
    height: 4vw;
  }


  .new_block .pixel.filled[value="1"] {
    background-color: rgb(0, 240, 240);
    border-top: var(--tetris-block-border-width) solid rgb(96, 246, 246);
    border-left: var(--tetris-block-border-width) solid rgb(49, 243, 243);
    border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 123);
    border-right: var(--tetris-block-border-width) solid rgb(0, 167, 167);
  }

  .new_block .pixel.filled[value="2"] {
    background-color: rgb(0, 0, 240);
    border-top: var(--tetris-block-border-width) solid rgb(96, 96, 246);
    border-left: var(--tetris-block-border-width) solid rgb(49, 49, 243);
    border-bottom: var(--tetris-block-border-width) solid rgb(0, 0, 123);
    border-right: var(--tetris-block-border-width) solid rgb(0, 0, 167);
  }

  .new_block .pixel.filled[value="3"] {
    background-color: rgb(240, 160, 0);
    border-top: var(--tetris-block-border-width) solid rgb(246, 196, 96);
    border-left: var(--tetris-block-border-width) solid rgb(243, 178, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(123, 82, 0);
    border-right: var(--tetris-block-border-width) solid rgb(167, 111, 0);
  }

  .new_block .pixel.filled[value="4"] {
    background-color: rgb(240, 240, 0);
    border-top: var(--tetris-block-border-width) solid rgb(246, 246, 96);
    border-left: var(--tetris-block-border-width) solid rgb(243, 243, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(123, 123, 0);
    border-right: var(--tetris-block-border-width) solid rgb(167, 167, 0);
  }

  .new_block .pixel.filled[value="5"] {
    background-color: rgb(0, 240, 0);
    border-top: var(--tetris-block-border-width) solid rgb(96, 246, 96);
    border-left: var(--tetris-block-border-width) solid rgb(49, 243, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 0);
    border-right: var(--tetris-block-border-width) solid rgb(0, 167, 0);
  }

  .new_block .pixel.filled[value="6"] {
    background-color: rgb(160, 0, 240);
    border-top: var(--tetris-block-border-width) solid rgb(196, 96, 246);
    border-left: var(--tetris-block-border-width) solid rgb(178, 49, 243);
    border-bottom: var(--tetris-block-border-width) solid rgb(82, 0, 123);
    border-right: var(--tetris-block-border-width) solid rgb(111, 0, 167);
  }

  .new_block .pixel.filled[value="7"] {
    background-color: rgb(240, 0, 0);
    border-top: var(--tetris-block-border-width) solid rgb(246, 96, 96);
    border-left: var(--tetris-block-border-width) solid rgb(243, 49, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(123, 0, 0);
    border-right: var(--tetris-block-border-width) solid rgb(167, 0, 0);
  }

  .tetris_scores {
    height: 50vw;
    left: -20.8vw;
    top: 10vw;
    border: 1vw solid white;
    border-right: none;
    background-color: rgb(115, 165, 122);
    width: 20vw;
    font-size: 4vw;
    border-radius: 2vw 0 0 2vw;
    padding: 0;
  }

  .scores_title {
    font-weight: 800;
    text-shadow: black 0px 0px 1vw;
  }

  .scores_value {
    margin: 0.5vw;
    font-size: 4vw;
    border: 0.3vw solid white;
    width: 18vw
  }

  .tetris-row {
    height: 5.5vw;
  }

  .tetris_pixel {
    font-size: 0.5vw;
    width: 5.5vw;
    height: 5.5vw;
  }


  .tetris-row.filled .tetris_pixel {
    background-color: white !important;
  }


  .tetris-row.filled .tetris_pixel:nth-child(1) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
  }

  .tetris-row.filled .tetris_pixel:nth-child(2) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    transition-delay: 100ms;
    border: none;
  }

  .tetris-row.filled .tetris_pixel:nth-child(3) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 200ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(4) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 300ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(5) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 400ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(6) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 500ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(7) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 600ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(8) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 700ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(9) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 800ms;
  }

  .tetris-row.filled .tetris_pixel:nth-child(10) {
    background-color: rgb(115, 165, 122) !important;
    transition: background-color 100ms linear;
    border: none;
    transition-delay: 900ms;
  }

  .tetris_pixel.filled[value="1"] {
    background-color: rgb(0, 240, 240);
    border-top: var(--tetris-block-border-width) solid rgb(96, 246, 246);
    border-left: var(--tetris-block-border-width) solid rgb(49, 243, 243);
    border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 123);
    border-right: var(--tetris-block-border-width) solid rgb(0, 167, 167);
  }

  .tetris_pixel.filled[value="2"] {
    background-color: rgb(0, 0, 240);
    border-top: var(--tetris-block-border-width) solid rgb(96, 96, 246);
    border-left: var(--tetris-block-border-width) solid rgb(49, 49, 243);
    border-bottom: var(--tetris-block-border-width) solid rgb(0, 0, 123);
    border-right: var(--tetris-block-border-width) solid rgb(0, 0, 167);
  }

  .tetris_pixel.filled[value="3"] {
    background-color: rgb(240, 160, 0);
    border-top: var(--tetris-block-border-width) solid rgb(246, 196, 96);
    border-left: var(--tetris-block-border-width) solid rgb(243, 178, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(123, 82, 0);
    border-right: var(--tetris-block-border-width) solid rgb(167, 111, 0);
  }

  .tetris_pixel.filled[value="4"] {
    background-color: rgb(240, 240, 0);
    border-top: var(--tetris-block-border-width) solid rgb(246, 246, 96);
    border-left: var(--tetris-block-border-width) solid rgb(243, 243, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(123, 123, 0);
    border-right: var(--tetris-block-border-width) solid rgb(167, 167, 0);
  }

  .tetris_pixel.filled[value="5"] {
    background-color: rgb(0, 240, 0);
    border-top: var(--tetris-block-border-width) solid rgb(96, 246, 96);
    border-left: var(--tetris-block-border-width) solid rgb(49, 243, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(0, 123, 0);
    border-right: var(--tetris-block-border-width) solid rgb(0, 167, 0);
  }

  .tetris_pixel.filled[value="6"] {
    background-color: rgb(160, 0, 240);
    border-top: var(--tetris-block-border-width) solid rgb(196, 96, 246);
    border-left: var(--tetris-block-border-width) solid rgb(178, 49, 243);
    border-bottom: var(--tetris-block-border-width) solid rgb(82, 0, 123);
    border-right: var(--tetris-block-border-width) solid rgb(111, 0, 167);
  }

  .tetris_pixel.filled[value="7"] {
    background-color: rgb(240, 0, 0);
    border-top: var(--tetris-block-border-width) solid rgb(246, 96, 96);
    border-left: var(--tetris-block-border-width) solid rgb(243, 49, 49);
    border-bottom: var(--tetris-block-border-width) solid rgb(123, 0, 0);
    border-right: var(--tetris-block-border-width) solid rgb(167, 0, 0);
  }

  .tetris_buttons {
    margin-top: 1vw;
    position: relative;
    width: 55vw;
    height: 55vw;
    border-radius: 50%;
    background-color: rgb(221, 37, 37);
    margin-top: 5vw;
    outline: 1.5vw solid rgb(163, 28, 28);
  }

  .tetris_button {
    position: absolute;
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    background-color: rgb(207, 39, 39);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10vw;
    color: white;
    border: 1.5vw solid rgb(163, 28, 28);
  }

  .tetris_button.up {
    top: 2vw;
    left: 17.5vw;
    font-size: 13vw;
  }

  .tetris_button.down {
    bottom: 2vw;
    left: 17.5vw;
    padding-top: 0.2vw;
  }

  .tetris_button.left {
    top: 17.5vw;
    left: 2vw;
    padding-right: 1vw;
  }

  .tetris_button.right {
    top: 17.5vw;
    right: 2vw;
    padding-left: 1vw;
  }

    @keyframes text-slide-in {
      0% {
        margin-top: -200vw;
      }
  
      100% {
        margin-top: -20vw;
      }
    }
  
    .tetris_start_screen {
      position: absolute;
      height: 110vw;
      width: 55vw;
      background-color: rgb(115, 165, 122);
      display: flex;
      font-family: var(--tetris-font);
      flex-direction: column;
      align-items: center;
      padding: 5vw 0;
      color: white;
      font-size: 5vw;
      text-shadow: black 0px 0px 0.5vw;
    }
  
    @keyframes pulsing-message {
      0% {
        opacity: 0;
      }
  
      25% {
        opacity: 1;
      }
  
      75% {
        opacity: 1;
      }
  
      100% {
        opacity: 0;
      }
    }
  
    .congratulation_message {
      position: absolute;
      top: 4vw;
      font-size: 3.5vw;
      color: rgb(0, 255, 0);
      text-shadow: black 0px 0px 0.1vw;
      animation: pulsing-message 2s infinite;
      text-align: center;
      width: 100%;
    }
  
    .tetris_start_screen .highscores {
      width: 45vw;
      margin-top: 3vw;
      border: 0.5vw solid white;
    }
  
    .tetris_start_screen .highscores div {
      width: 100%;
      height: 10vw;
      display: flex;
      align-items: center;
      font-size: 5vw;
      padding: 0 4vw;
    }
  
    .tetris_start_screen .highscores div:nth-child(even) {
      background-color: rgb(94, 136, 100);
    }
  
    .tetris_start_screen .highscores div:nth-child(odd) {
      background-color: rgb(133, 192, 141);
    }
  
    .tetris_start_screen .play_button {
      margin-top: 15vw;
      background-color: rgb(17, 201, 17);
      width: 35vw;
      height: 15vw;
      border-radius: 4vw;
      border: 1vw solid rgb(20, 110, 20);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 1000;
      font-size: 7vw;
      text-shadow: rgb(20, 110, 20) 0px 0px 1vw;
      box-shadow: 0px 0px 2vw rgb(20, 110, 20);
    }
  
    .tetris_gameover_screen {
      font-family: var(--tetris-font);
      position: absolute;
      top: 0;
      left: 0;
      height: 110vw;
      width: 55vw;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 1000;
      color: white;
      text-shadow: red 0.5vw 0.5vw 2vw;
      font-size: 10vw;
      background-color: rgba(97, 96, 96, 0.8);
      text-align: center;
      overflow: hidden;
    }
  
    @keyframes restart-appear {
      0% {
        opacity: 0;
        visibility: hidden;
      }
  
      50% {
        opacity: 0;
      }
  
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
  
    .tetris_gameover_screen .restart-button {
      position: absolute;
      width: 30vw;
      top: 70vw;
      border: 0.5vw solid red;
      border-radius: 5vw;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      font-size: 3.5vw;
      color: red;
      text-shadow: rgb(255, 175, 175) 0.05vw 0.05vw 0.5vw;
      background-color: white;
      padding: 1vw;
      visibility: hidden;
      animation: restart-appear 4s forwards;
    }
  
    .restart-icon {
      background-color: red;
      width: 5vw;
      height: 5vw;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 4vw;
    }
  
    .tetris-gameover-slide-in {
      position: absolute;
      animation: text-slide-in 2s linear;
      margin-top: -20vw;
    }

        .pause_button {
          background-color: black;
          width: 5vw;
          height: 5vw;
          border-radius: 50%;
          display: flex;
          font-size: 5vw;
          color: white;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 1.4vw rgba(0, 0, 0, 0.5);
          margin-top: 0.3vw;
        }
}

.ttfe_outer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  flex-direction: column;
}

.ttfe_grid {
  position: relative;
  width: 30vw;
  height: 30vw;
  background-color: rgb(90, 86, 86);
  border-radius: 1vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 1vw;
  padding: 1vw;
}

.ttfe_grid .ttfe_tile_temp {
  background-color: rgb(104, 104, 104);
  border-radius: 0.5vw;
}

.test-button {
  height: 2vw;
  width: 2vw;
  background-color: red;
}

.tiles-container {
  position: absolute;
  padding: 1vw;
}

.ttfe_tile {
  --x: 3;
  --y: 1;
  --cell-gap: 1vw;
  height: 6.25vw;
  width: 6.25vw;
  background-color: red;
  z-index: 10;
  position: absolute;
  left: calc((var(--x) - 1) * 6.25vw + var(--x) * var(--cell-gap));
  top: calc((var(--y) - 1) * 6.25vw + var(--y) * var(--cell-gap));
  transition: 100ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2vw;
  font-weight: 600;
  animation: appear 200ms;
  color: rgb(90, 86, 86);
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0.5vw;
  box-shadow: rgb(54, 54, 54, 0.5) 0px 0px 1vw;
}

.ttfe_tile.no_animation {
  animation: none;
}

.undo_bar {
  width: 30vw;
  height: 4vw;
  display: flex;
        flex-direction: row-reverse;
  align-items: center;
}

.undo_button {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-gap: 0.8vw;
  gap: 0.8vw;
  font-size: 1.5vw;
  align-items: center;
  justify-content: center;
  background-color: rgb(54, 53, 53);
  padding: 0.2vw 1vw;
  border-radius: 0.7vw;
  color: rgba(218, 209, 209, 0.6);
  border: 0.2vw solid rgb(90, 86, 86);
  font-weight: 700;
  width: 10vw;
}

.undos_left_indicator {
  position: absolute;
  padding: 0 0 0.2vw 0;
  right: -0.75vw;
  top: -0.75vw;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  background-color: rgb(0, 207, 0);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9vw;
  box-shadow: 0px 0px 0.4vw rgba(0, 0, 0, 0.3);
}

.undos_left_indicator.medium {
  background-color: orange;
}

.undos_left_indicator.empty {
  background-color: red;
}

.undo_button.inactive {
  background-color: rgba(54, 53, 53, 0.2);
}
.undo_icon {
  font-size: 1.1vw;
  margin-top: 0.2vw;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  80% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ttfe_tile.tile2 {
  background-color: #eee4da;
}
.ttfe_tile.tile4 {
  background-color: #ede0c8;
}
.ttfe_tile.tile8 {
  color: white;
  background-color: #f2b179;
}
.ttfe_tile.tile16 {
  color: white;
  background-color: #f59563;
}
.ttfe_tile.tile32 {
  color: white;
  background-color: #f67c5f;
}
.ttfe_tile.tile64 {
  color: white;
  background-color: #f63e3b;
}
.ttfe_tile.tile128 {
  color: white;
  background-color: #edcf72;
  font-size: 3vw;
}
.ttfe_tile.tile256 {
  color: white;
  background-color: #edcc61;
  font-size: 3vw;
}
.ttfe_tile.tile512 {
  color: white;
  background-color: #edc850;
  font-size: 3vw;
}
.ttfe_tile.tile1024 {
  color: white;
  background-color: #edc53f;
  font-size: 2.4vw;
}
.ttfe_tile.tile2048 {
  color: white;
  background-color: #edc22e;
  font-size: 2.4vw;
}

.tileSuper {
  color: white;
  background-color: darkblue;
  font-size: 2.1vw;
}

@keyframes gameover-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ttfe_gameover_screen {
  position: absolute;
  width: 30vw;
  height: 30vw;
  background-color: rgba(218, 209, 209, 0.6);
  z-index: 100;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.2vw;
  color: rgb(54, 53, 53);
  font-weight: 1000;
  font-family: Arial, Helvetica, sans-serif;
  animation: gameover-appear 2s;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
}

.ttfe_try_again {
  color: rgb(54, 53, 53);
  font-size: 1.5vw;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5vw 1vw;
  border-radius: 2vw;
  grid-gap: 1vw;
  gap: 1vw;
  border: 0.2vw solid rgb(54, 53, 53);
  margin-top: 1vw;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 1vw;
}
.ttfe_score_bar {
  width: 30vw;
  height: 6vw;
  grid-gap: 0.5vw;
  gap: 0.5vw;
  display: flex;
  flex-direction: row-reverse;
}

.ttfe_score_title {
  color: rgb(90, 86, 86);
  font-size: 1.2vw;
  font-weight: 700;
  margin-bottom: 0.2vw;
}

.ttfe_score_value {
  font-size: 2vw;
  font-weight: 800;
  color: white;
}
.ttfe_score_display {
  position: relative;
  height: 6vw;
  width: 10vw;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgb(54, 53, 53);
  border-radius: 1vw;
  border: 0.2vw solid rgb(90, 86, 86);
}

@keyframes pop-up {
  0% {
    top: 1vw;
    opacity: 1;
  }
  90% {
    top: 4vw;
  }
  100% {
    opacity: 0;
  }
}

.ttfe_score_popup {
  position: absolute;
  top: 1vw;
  left: 0;
  opacity: 0;
  font-size: 2vw;
  color: rgb(90, 86, 86);
  font-weight: 1000;
  animation: 1.5s pop-up;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
}

.ttfe_reached_screen {
  position: absolute;
  width: 30vw;
  height: 30vw;
  background-color: rgba(218, 209, 209, 0.6);
  z-index: 100;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  color: rgb(54, 53, 53);
  font-weight: 1000;
  font-family: Arial, Helvetica, sans-serif;
  animation: gameover-appear 2s;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
}

.ttfe_reached_subtext {
  color: rgb(54, 53, 53);
  font-size: 1.5vw;
  text-align: center;
}

.ttfe_reached_button {
  color: white;
  background-color: rgb(54, 53, 53);
  font-size: 1.5vw;
  width: 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
  border-radius: 1vw;
  margin: 1vw;
  border: 0.2vw solid rgb(90, 86, 86);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 1vw;
}
.ttfe_choice_screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(36, 36, 36);
}

.ttfe_choice_title {
  font-size: 2vw;
  font-weight: 700;
  margin: 1vw;
}

.ttfe_choice_explanation {
  margin: 1vw;
  font-size: 1.5vw;
  text-align: center;
}
.ttfe_game_choice_option {
  width: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vw;
  background-color: red;
  border-radius: 1vw;
  border: 0.2vw solid rgb(90, 86, 86);
  color: white;
  font-weight: 700;
  font-size: 2.5vw;
  box-shadow: 0px 0px 1vw rgba(0, 0, 0, 0.6);
  text-shadow: black 0.15vw 0.15vw;
  padding: 0 0 0.5vw 0;
}

.ttfe_game_choice_option.regular {
  background-color: orange;
}

.ttfe_game_choice_option.unlimited {
  background-color: rgb(0, 207, 0);
}
@media only screen and (max-width: 600px) {
  .ttfe_outer {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
  }

  .ttfe_grid {
    position: relative;
    width: 98vw;
    height: 98vw;
    background-color: rgb(90, 86, 86);
    border-radius: 2vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 2.5vw;
    padding: 2.5vw;
  }

  .ttfe_grid .ttfe_tile_temp {
    background-color: rgb(104, 104, 104);
    border-radius: 1vw;
  }

  .test-button {
    height: 2vw;
    width: 2vw;
    background-color: red;
  }

  .tiles-container {
    position: absolute;
    padding: 2.5vw;
  }

  .ttfe_tile {
    --x: 3;
    --y: 1;
    --cell-gap: 2vw;
    height: 22vw;
    width: 22vw;
    background-color: red;
    z-index: 10;
    position: absolute;
    left: calc((var(--x) - 1) * 22vw + var(--x) * var(--cell-gap));
    top: calc((var(--y) - 1) * 22vw + var(--y) * var(--cell-gap));
    transition: 100ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vw;
    font-weight: 600;
    animation: appear 200ms;
    color: rgb(90, 86, 86);
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 1.5vw;
    box-shadow: rgb(54, 54, 54, 0.5) 0px 0px 2vw;
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    80% {
      opacity: 1;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .ttfe_tile.tile2 {
    background-color: #eee4da;
  }

  .ttfe_tile.tile4 {
    background-color: #ede0c8;
  }

  .ttfe_tile.tile8 {
    color: white;
    background-color: #f2b179;
  }

  .ttfe_tile.tile16 {
    color: white;
    background-color: #f59563;
  }

  .ttfe_tile.tile32 {
    color: white;
    background-color: #f67c5f;
  }

  .ttfe_tile.tile64 {
    color: white;
    background-color: #f63e3b;
  }

  .ttfe_tile.tile128 {
    color: white;
    background-color: #edcf72;
    font-size: 9vw;
  }

  .ttfe_tile.tile256 {
    color: white;
    background-color: #edcc61;
    font-size: 9vw;
  }

  .ttfe_tile.tile512 {
    color: white;
    background-color: #edc850;
    font-size: 9vw;
  }

  .ttfe_tile.tile1024 {
    color: white;
    background-color: #edc53f;
    font-size: 7.5vw;
  }

  .ttfe_tile.tile2048 {
    color: white;
    background-color: #edc22e;
    font-size: 7.5vw;
  }

  .tileSuper {
    color: white;
    background-color: darkblue;
    font-size: 7vw;
  }
  .ttfe_gameover_screen {
    position: absolute;
    width: 98vw;
    height: 98vw;
    background-color: rgba(218, 209, 209, 0.6);
    z-index: 100;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14vw;
    color: rgb(54, 53, 53);
    font-weight: 1000;
    font-family: Arial, Helvetica, sans-serif;
    animation: gameover-appear 2s;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2vw;
  }

  .ttfe_try_again {
    color: rgb(54, 53, 53);
    font-size: 5vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5vw 3vw;
    border-radius: 4.5vw;
    grid-gap: 3vw;
    gap: 3vw;
    border: 0.2vw solid rgb(54, 53, 53);
    margin-top: 1vw;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2.5vw;
  }

  .ttfe_score_bar {
    width: 98vw;
    height: 20vw;

    margin-bottom: 1vw;
  }

  .ttfe_score_title {
    color: rgb(90, 86, 86);
    font-size: 4vw;
    font-weight: 700;
    margin-bottom: 0.6vw;
  }

  .ttfe_score_value {
    font-size: 7vw;
    font-weight: 800;
    color: white;
  }

  .ttfe_score_display {
    height: 20vw;
    width: 40vw;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgb(54, 53, 53);
    border-radius: 2vw;
    border: 1vw solid rgb(90, 86, 86);
  }

  @keyframes pop-up {
    0% {
      top: 4vw;
      opacity: 1;
    }

    90% {
      top: 10vw;
    }

    100% {
      opacity: 0;
    }
  }

  .ttfe_score_popup {
    position: absolute;
    top: 1vw;
    left: 0;
    opacity: 0;
    font-size: 6vw;
    color: rgb(90, 86, 86);
    font-weight: 1000;
    animation: 1.5s pop-up;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5vw;
  }

  .ttfe_reached_screen {
    position: absolute;
    width: 98vw;
    height: 98vw;
    background-color: rgba(218, 209, 209, 0.6);
    z-index: 100;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9.5vw;
    color: rgb(54, 53, 53);
    font-weight: 1000;
    font-family: Arial, Helvetica, sans-serif;
    animation: gameover-appear 2s;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2vw;
  }

  .ttfe_reached_subtext {
    color: rgb(54, 53, 53);
    font-size: 5vw;
    text-align: center;
  }

  .ttfe_reached_button {
    color: white;
    background-color: rgb(54, 53, 53);
    font-size: 4vw;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    border-radius: 3vw;
    margin: 1vw;
    border: 0.6vw solid rgb(90, 86, 86);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 2vw;
  }

  .ttfe_reached_button {
    color: white;
    background-color: rgb(54, 53, 53);
    font-size: 1.5vw;
    width: 16vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    border-radius: 1vw;
    margin: 1vw;
    border: 0.2vw solid rgb(90, 86, 86);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 1vw;
  }

  .ttfe_choice_screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(36, 36, 36);
  }

  .ttfe_choice_title {
    font-size: 7vw;
    font-weight: 700;
    margin: 5vw;
    text-align: center;
  }

  .ttfe_choice_explanation {
    margin: 3vw;
    font-size: 4vw;
    text-align: center;
  }

  .ttfe_game_choice_option {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    background-color: red;
    border-radius: 3vw;
    border: 0.5vw solid rgb(90, 86, 86);
    color: white;
    font-weight: 700;
    font-size: 7vw;
    box-shadow: 0px 0px 3vw rgba(0, 0, 0, 0.3);
    text-shadow: black 0.4vw 0.4vw;
    padding: 0 0 1vw 0;
  }

  .ttfe_game_choice_option.regular {
    background-color: orange;
  }

  .ttfe_game_choice_option.unlimited {
    background-color: rgb(0, 207, 0);
  }

    .undo_bar {
      width: 97vw;
      height: 12vw;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  
    .undo_button {
      position: relative;
      display: flex;
      flex-direction: row;
      grid-gap: 3vw;
      gap: 3vw;
      font-size: 5vw;
      align-items: center;
      justify-content: center;
      background-color: rgb(54, 53, 53);
      padding: 0.2vw 1vw;
      border-radius: 1.5vw;
      color: rgba(218, 209, 209, 0.6);
      border: 0.6vw solid rgb(90, 86, 86);
      font-weight: 700;
      width: 32vw;
    }
  
    .undos_left_indicator {
      position: absolute;
      padding: 0 0 0.5vw 0;
      right: -2vw;
      top: -2vw;
      height: 4vw;
      width: 4vw;
      border-radius: 50%;
      background-color: rgb(0, 207, 0);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5vw;
      box-shadow: 0px 0px 1vw rgba(0, 0, 0, 0.3);
    }
  
    .undos_left_indicator.medium {
      background-color: orange;
    }
  
    .undos_left_indicator.empty {
      background-color: red;
    }
  
    .undo_button.inactive {
      background-color: rgba(54, 53, 53, 0.2);
    }
  
    .undo_icon {
      font-size: 3vw;
      margin-top: 0.2vw;
    }
}

*{
      --font: "Noto Serif";
}

@keyframes slide-in {
  0% {
    margin-bottom: 200vw;
  }
  100% {
    margin-bottom: 0vw;
  }
}

.home_screen_outer {
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--top-bar-height));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home_screen_abs {
  position: absolute;
  width: 100vw;
  height: calc(100vh - var(--top-bar-height));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow-y: hidden;

}


.home_screen_qr_code_outer {
  position: absolute;
  z-index: 2;
  width: 35vw;
  height: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 0.5vw;
  gap: 0.5vw;
  color: white;
  font-family: "Roboto";
  font-size: 2vw;
  font-weight: 300;
  background-color: var(--main-background-color);
}

.buttons-helper {
  position: absolute;
  width: 30vw;
  height: 15vw;
  display: flex;
  justify-content: center;
}
.home_screen_qr_pic {
  width: 10vw;
  height: 10vw;
}

.home_screen_card_outer {
  position: relative;
  width: 35.3vw;
  height: 20.3vw;
  border-radius: 1vw;
  animation: slide-in 2s;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 0px 1vw var(--main-color-op3);
  background-color: var(--main-color-op2);
}

.home_screen_card_inner {
  position: absolute;
  top: 0.15vw;
  left: 0.15vw;
  width: 35vw;
  height: 20vw;
  border-radius: 1vw;
  background-color: var(--background-color);
  background: repeating-linear-gradient(
    -135deg,
    var(--background-color),
    var(--background-color) 0.3vw,
    var(--main-background-color),
    var(--main-background-color) 0.4vw
  );
  animation: slide-in 2s;
  z-index: 2;
  border: 0.1vw solid  var(--main-border-color);
}

.home_screen_titles {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--main-color);
  font-family: var(--font);
  top: 1.5vw;
  left: 3vw;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
}

.home_screen_name {
  font-size: 3vw;
  font-weight: 400;
}

.home_screen_jobtitle {
  margin-top: 0vw;
  font-size: 1.5vw;
  margin-left: 0.1vw;
}
.home_screen_jobtitle.small {
  font-size: 1.2vw;
  margin-top: 0vw;
}

.home_screen_signature {
  position: absolute;
  width: 10vw;
  bottom: 1vw;
  right: -1vw;
  opacity: 0.75;
}

.home_screen_contacts {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--main-color);
  bottom: 2vw;
  left: 3vw;
  font-size: 1.3vw;
  text-shadow:   var(--text-shadow-color) 0px 0px 0.1vw;
  font-family: var(--font);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.home_screen_contact {
  color: var(--main-color);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1vw;
  gap: 1vw;
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(-60deg);
  }

  25% {
    transform: rotate(60deg);
  }

  50% {
    transform: rotate(120deg);
  }

  75% {
    transform: rotate(240deg);
  }

  100% {
    transform: rotate(300deg);
  }
}

@keyframes slidediebitchup {
  0% {
    top: 0vw;
  }

  90% {
   top: -9vw;
   transform: scale(1);
  }
  95% {
      top: -9vw;
    transform: scale(1.1);
  }
  100% {
      top: -9vw;
    transform: scale(1);
  }
}

@keyframes slidediebitchdown {
  0% {
    bottom: 0vw;
  }

  90% {
    bottom: -9vw;
    transform: scale(1);
  }

  95% {
    bottom: -9vw;
    transform: scale(1.1);
  }

  100% {
    bottom: -9vw;
    transform: scale(1);
  }
}
.home_screen_button {
  position: absolute;
  background: repeating-linear-gradient(
    -135deg,
    var(--background-color),
    var(--background-color) 0.3vw,
    var(--main-background-color),
    var(--main-background-color) 0.4vw
  );
  padding: 0 2vw;
  height: 3.5vw;
  color: white;
  z-index: 100;
  color: var(--main-color);
  border-radius: 2vw;
  border: 0.1vw solid  var(--main-border-color);
  box-shadow: 0px 0px 1vw var(--main-color-op3);
  font-family: "Roboto";
  font-weight: 400;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 1vw;
  gap: 1vw;
  z-index: 0;
  font-size: 1.8vw;
}

.test-track {
  width: 20vw;
}
.switch-icon {
  font-size: 1vw;
  height: 1.3vw;
  width: 1.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
}

.home_screen_socials {
  position: absolute;
  width: 30vw;
  height: 5vw;
  animation: slidediebitchdown 2s linear forwards;
  animation-delay: 1.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}


.home_screen_socials_button {
  height: 4vw;
  width: 4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5vw;
  box-shadow: 0px 0px 0.5vw var(--main-border-color);
  cursor: pointer;
}

.home_screen_socials_button.facebook {
  background: linear-gradient(135deg, #3a5795, #2b4886, #3a5795);

}
.home_screen_socials_button.insta {
  background: linear-gradient(
    135deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.home_screen_socials_button.whatsapp {
  background: linear-gradient(135deg, #1ebea5, #00e676, #1ebea5);
}

.home_screen_socials_button.linkedin {
  background: linear-gradient(135deg, #0077b5, #00a0dc, #0077b5);
}

.home_screen_button:hover {
  border: 0.1vw solid var(--main-color);
  box-shadow: 0px 0px 0.5vw var(--main-border-color);
    cursor: pointer;
}

.home_screen_button.games {
  left: 0;
  animation:  slidediebitchup 2s linear forwards;
  animation-delay: 1.5s;
}

.home_screen_button.tools {
  right: 0;
  animation: slidediebitchup 2s linear forwards;
  animation-delay: 1.5s;
}

.home_screen_button.socials {
  animation: slidediebitchdown 2s linear forwards;
  left: 0;
  animation-delay: 1.5s;
}
.home_screen_button.piano_xx {
  animation: slidediebitchdown 2s linear forwards;
  right: 0;
  animation-delay: 1.5s;
}

.home_screen_icon {
  /* font-size: 1.9vw; */
  filter: drop-shadow(0px 0px 0.1vw var(--icon-shadow-color));
}
.card_spinner {
  position: absolute;
  height: 30vw;
  width: 5vw;
  transform-origin: bottom left;
  left: 15.5vw;
  top: -21.2vw;
  z-index: 1;
  animation: rotate-spinner 4s linear infinite;
  background-image: linear-gradient(
    90deg,
    var(--main-border-color),
    var(--main-color)
  );
}



@media only screen and (max-width: 600px) {

.home_screen_card_inner {
  border-width: 0.01vw;
}

.home_screen_button {
  border-width: 0.01vw;
}
  .home_screen_outer {

    height: calc(100vh - var(--top-bar-height));

  }
  
  .home_screen_abs {
    height: calc(100vh - var(--top-bar-height));

  }
  .home_screen_card_outer {
    transform: scale(2.7);
  }

  .buttons-helper {
    transform: scale(2.7);
  }

  .home_screen_qr_code_outer {
    transform: scale(2.7);
  }
}
.switch-outer {
  width: 3.8vw;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.side_bar_icon {
  filter: drop-shadow(0px 0px 0.1vw var(--icon-shadow-color));

}

.switch-track {
  position: relative;
  width: 3vw;
  height: 1.2vw;
  border-radius: 1vw;
  background-color: var(--main-border-color);
}

.switch-thumb {
  position: absolute;
  top: -0.4vw;
  height: 1.8vw;
  width: 1.8vw;
  border-radius: 50%;
  border: 0.1vw solid var(--main-color);
  background-color: var(--main-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  font-size: 1.1vw;

}

@keyframes slide-left {
  from {
    left: 1.6vw
  }

  to {
    left: -0.4vw
  }

}

@keyframes slide-right {
  from {
    left: -0.4vw
  }

  to {
    left: 1.6vw
  }
}

.switch-thumb.left {
  left: -0.4vw;
  animation: slide-left 0.1s linear forwards;
}

.switch-thumb.right {
  right: -0.4vw;
  animation: slide-right 0.1s;
}


.home_side_bar {
  position: fixed;
  left: calc(-1 * var(--side-bar-width) - 1px);
  top: calc(var(--top-bar-height));
  height: max(calc(100dvh - var(--top-bar-height)), calc(100vh - var(--top-bar-height)));
  width: var(--side-bar-width);
  background-color: var(--background-color);
  border-right: 0.1vw solid var(--main-border-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1vw 0.3vw;
  /* overflow: hidden; */
  z-index: 10;

}

.home_side_bar.open {
  animation: 0.15s ease-in sidebar-open forwards;
  left: 0;
}

.home_side_bar.close {
  left: calc(-1 * var(--side-bar-width) - 1px);
  animation: 0.15s ease-out sidebar-close forwards;
}

.home_side_bar_options_outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home_side_bar_option {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.5vw 0.75vw;
  align-items: center;
  color: var(--main-color);
  grid-gap: 1vw;
  gap: 1vw;
  font-size: 1.4vw;
  font-family: "Roboto";
  font-weight: 400;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
}

.home_side_bar_fold_button {
  position: absolute;
  right: 1vw;
  color: var(--main-color);
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_side_bar_option:hover {
  background-color: var(--side-bar-hover-color);
  cursor: pointer;
  border-radius: 0.3vw;
}

.home_side_bar_option.active {
  background-color: var(--side-bar-hover-color) ;
  border-top-left-radius: 0.3vw;
  border-top-right-radius: 0.3vw;
}

.home_side_bar_option_icon {
  margin-top: 0.4vw;
}

.home_side_bar_user_icon {
  align-self: center;
  margin-top: 0.5vw;
  font-size: 2.5vw;
  filter: drop-shadow(0px 0px 0.1vw var(--icon-shadow-color));

}

.sidebar_sub_options {
  background-color: var(--side-bar-hover-color);
  width: 100%;
  /* border-bottom: 0.1vw solid var(--main-border-color); */
  border-bottom-left-radius: 0.3vw;
  border-bottom-right-radius: 0.3vw;
}
.sidebar_sub_options_item {
  height: 3.5vw;
  font-size: 1.2vw;
  padding: 0 1vw;
  border-top: 0.1vw solid var(--main-border-color);
  display: flex;
  flex-direction: row;
  grid-gap: 0.8vw;
  gap: 0.8vw;
  align-items: center;
  color: var(--main-color);
  cursor: pointer;
}

.sidebar_sub_option_icon {
  height: 2.5vw;
  font-size: 1.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_side_bar_theme_switch {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.switch_title {
  width: 100%;
  color: var(--main-color);
  font-size: 1vw;
  text-align: center;
  padding: 0.5vw;
  font-weight: 300;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;

}

.switch_label {
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  color: var(--main-color);
  font-size: 1vw;
  font-weight: 300;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;

}

@keyframes sidebar-open {
  from {
    left: calc(-1 * var(--side-bar-width) - 1px);
  }

  to {
    left: 0;
  }
}

@keyframes sidebar-close {
  from {
    left: 0;
  }

  to {
    left: calc(-1 * var(--side-bar-width) - 1px);
  }
}

.home_side_bar_user_info {
  color: var(--main-color);
  display: flex;
  flex-direction: column;
}

.home_side_bar_user_name {
  font-size: 1vw;
  font-family: "Roboto";
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
  font-weight: 600;
}

.home_side_bar_user_role {
  font-size: 0.8vw;
  font-family: "Roboto";
  font-weight: 300;
  text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;

}

@media only screen and (max-width: 600px) {


  .home_side_bar {
    width: var(--side-bar-width);
    display: flex;
    flex-direction: column;
    padding: 2vw 1vw 4vw 1vw;
    /* overflow: hidden; */
    z-index: 10;
  }



  .home_side_bar_option {
    padding: 1vw 2vw;
    grid-gap: 3vw;
    gap: 3vw;
    font-size: 5vw;
    text-shadow: var(--text-shadow-color) 0px 0px 0.2vw;

  }

  .home_side_bar_option:hover {
    border-radius: 1vw;
  }

  .home_side_bar_option_icon {
    margin-top: 1.2vw;
  }

  .switch-outer {
    width: 11vw;
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }

  .switch-track {
    position: relative;
    width: 10vw;
    height: 3vw;
    border-radius: 3vw;
    background-color: var(--main-border-color);
  }

  .switch-thumb {
    position: absolute;
    top: -1vw;
    height: 5vw;
    width: 5vw;
    border-radius: 50%;
    border: 0.1vw solid var(--main-color);
    background-color: var(--main-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
    font-size: 3.3vw;
  }

  @keyframes slide-left {
    from {
      left: 6vw
    }

    to {
      left: -1vw
    }

  }

  @keyframes slide-right {
    from {
      left: -1vw
    }

    to {
      left: 6vw
    }
  }

  .switch-thumb.left {
    left: -1vw;
    animation: slide-left 0.1s linear forwards;
  }

  .switch-thumb.right {
    right: -1vw;
    animation: slide-right 0.1s linear forwards;
  }

  .switch_title {
    width: 100%;
    color: var(--main-color);
    font-size: 3.5vw;
    text-align: center;
    padding: 2vw;
    font-weight: 300;
  }

  .switch_label {
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    color: var(--main-color);
    font-size: 3vw;
    font-weight: 300;
  }

  .side_bar_icon {
    filter: drop-shadow(0px 0px 0.2vw var(--icon-shadow-color));

  }


  .home_side_bar_user_info {
    color: var(--main-color);
    display: flex;
    flex-direction: column;
  }

  .home_side_bar_user_name {
    font-size: 3.5vw;
    font-family: "Roboto";
    text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
    font-weight: 600;
  }

  .home_side_bar_user_role {
    font-size: 2.5vw;
    font-family: "Roboto";
    font-weight: 300;
    text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;

  }

  .home_side_bar_user_icon {
    align-self: center;
    margin-top: 1.5vw;
    font-size: 8vw;
    filter: drop-shadow(0px 0px 0.2vw var(--icon-shadow-color));

  }

  
.sidebar_sub_options {
  background-color: var(--side-bar-hover-color);
  width: 100%;
  border-bottom-left-radius: 0.3vw;
  border-bottom-right-radius: 0.3vw;
}
.sidebar_sub_options_item {
  height: 9vw;
  font-size: 3.5vw;
  padding: 0 2vw;
  border-top: 0.1vw solid var(--main-border-color);
  display: flex;
  flex-direction: row;
  grid-gap: 2vw;
  gap: 2vw;
  align-items: center;
  color: var(--main-color);
  cursor: pointer;
}

.sidebar_sub_option_icon {
  height: 5vw;
  font-size: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

}
.guitar_tuner_outer {
    display: flex;
    max-width: 100vw;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}

.start_tuning_button {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background: linear-gradient(rgb(27, 223, 27),rgb(27, 170, 27), rgb(27, 100, 27));
    border: 0.4vw solid rgb(15, 132, 15);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(15, 70, 15);
    font-size: 1.8vw;
    flex-direction: column;
    text-align: center;
    font-weight: 600;
    text-shadow: rgb(15, 255, 15, 0.3) 1px 1px 1vw;
}

.guitar_tuning_icon {
    font-size: 2.5vw;
}

.stop_tuning_button {
    height: 6vw;
    width: 6vw;
    border-radius: 50%;
    background: linear-gradient(red, rgb(39, 0, 0));
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 33, 33);
    font-size: 1.2vw;
    border: 0.2vw solid rgb(141, 3, 3);
    text-align: center;
    font-weight: 600;
    padding: 0 0 0.2vw 0;
        text-shadow: black 0.1vw 0.1vw 0.2vw;
}



.note_tune_wheel {
    position: relative;
    width: 40.4vw;
    height: 20.4vw;
    border-radius: 20vw 20vw 0 0;
    background: linear-gradient(90deg,rgb(255, 19, 19),rgb(255, 161, 10) 43%, rgb(0, 255, 0) , orange 57%, red);
    overflow: hidden;
    border: 0.2vw solid white;
}

.note_tune_wheel_center {
    position: absolute;
    width: 2vw;
    height: 2vw;
    background-color: black;
    border: 0.2vw solid white;
    border-radius: 50%;
    bottom: -1vw;
    left: 19vw;
    z-index: 10;

}

.note_tune_wheel_arrow {
    --cents: 0;
    position: absolute;
    height: 17.5vw; 
    width: 0.2vw;
    background-color: rgba(255, 255, 255, 0.561);
    bottom: 0;
    left:19.9vw;
    transform-origin: bottom center;
    transform: rotate(calc((var(--cents) / 50)* 90deg));
    transition: 200ms ease-in-out;
}

.note_tune_wheel_arrow_head {
    border-bottom: 1vw solid rgba(255,
        255,
        255, 1);
    position: absolute;
    top: -1vw;
    left: -0.40vw;
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
}
.note_tune_wheel_lines {
    --deg: 0;
    position: absolute;
    height: 20.4vw;
    width: 2vw;
    bottom: 0vw;
    left: 19vw;
    display: flex;
    justify-content: center;
    color: white;
    transform: rotate(calc((var(--deg) / 50)* 90deg));
    transform-origin: bottom center;
    z-index: 100;
    font-weight: 1000;
    font-size: 1vw;
}

.tune_direction {
        display: flex;
            grid-gap: 1vw;
            gap: 1vw;
            font-size: 1vw;
            align-items: center;
            color: white;
            position: absolute;
            bottom: 0;
            right: 4vw;
            font-weight: 600;
}

.tune_direction.down {
    bottom: 0;
    right: 4vw;
}
.tune_direction.up {
    bottom: 0;
    left: 4vw;
}
.note_tune_wheel_lines.small {
    font-size: 0.6vw;
}

.note_tune_wheel_number {
    --cents: 0;
    position: absolute;
    height: 22vw;
    width: 2vw;
    bottom: 2vw;
    left: 21vw;
    display: flex;
    justify-content: center;
    color: white;
    transform: rotate(calc((var(--cents) / 50)* 90deg));
    transform-origin: bottom center;
    font-size: 1vw;
    font-weight: 500;
}

.note_tune_wheel_number.line {
    font-size: 0.7vw;
    padding-top: 0.2vw;
    font-weight: 1000;
}

.note_tune_wheel_outer {
    position: relative;
    width: 44vw;
    height: 24vw;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;
    border-radius: 22vw 22vw 0 0;
    
}

.current_note_display{
    position: relative;
    height: 6vw;
    width: 6vw;
    border-radius: 50%;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;   
    font-size: 3vw;
    z-index: 2;
    overflow: hidden;
    box-shadow: 0vw 0vw 1vw white;
    padding: 0 0 0.4vw 0;
    font-weight: 600;
    margin-bottom: 2vw;
}

.current_note_display_colors {
    --x: 0;
    position: absolute;
    left: calc(-46vw - var(--x) * 0.94vw);
    width: 100vw;
    height: 6vw;
    bottom: 0;
    background-color: green;
    z-index: -2;
        background: linear-gradient(90deg, red, orange 40%, rgb(0, 236, 0), orange 60%, red);
}

.current_note_decomp {
    display: flex;
    flex-direction: row;
    margin-left: 1vw;
}

.current_note_letter {
    width: 2.3vw;
}

.current_note_right_side {
    display: flex;
    flex-direction: column;
    width: 1.5vw;
    justify-content: space-between;
}

.current_note_sharp {
    height: 2vw;
    font-size: 2vw;
    margin-right: 0.5vw;
}

.current_note_octave {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 1vw;
    font-size: 1vw;
    margin-bottom: 0.3vw;
    padding: 0 0.1vw;
}

@media only screen and (max-width: 600px) {
.guitar_tuner_outer {
        display: flex;
        max-width: 100vw;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow-x: hidden;
    }

    .start_tuning_button {
        width: 25vw;
        height: 25vw;
        border-radius: 50%;
        background: linear-gradient(rgb(27, 223, 27), rgb(27, 170, 27), rgb(27, 100, 27));
        border: 1vw solid rgb(15, 132, 15);
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(15, 70, 15);
        font-size: 4vw;
        flex-direction: column;
        text-align: center;
        font-weight: 600;
        text-shadow: rgb(15, 255, 15, 0.3) 0.2vw 0.2vw 1vw;
    }

    .guitar_tuning_icon {
        font-size: 6vw;
    }

    .stop_tuning_button {
        height: 15vw;
        width: 15vw;
        border-radius: 50%;
        background: linear-gradient(red, rgb(39, 0, 0));
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 33, 33);
        font-size: 3vw;
        border: 0.5vw solid rgb(141, 3, 3);
        text-align: center;
        font-weight: 600;
        padding: 0 0 0.5vw 0;
        text-shadow: black 0.3vw 0.3vw 0.6vw;
    }



    .note_tune_wheel {
        position: relative;
        width: 94vw;
        height: 47vw;
        border-radius: 47vw 47vw 0 0;
        background: linear-gradient(90deg, rgb(255, 19, 19), rgb(255, 161, 10) 43%, rgb(0, 255, 0), orange 57%, red);
        overflow: hidden;
        border: 0.5vw solid white;
    }

    .note_tune_wheel_center {
        position: absolute;
        width: 4vw;
        height: 4vw;
        background-color: black;
        border: 0.5vw solid white;
        border-radius: 50%;
        bottom: -2vw;
        left: 44.5vw;
        z-index: 10;

    }

    .note_tune_wheel_arrow {
        --cents: 0;
        position: absolute;
        height: 39vw;
        width: 0.5vw;
        background-color: rgba(255, 255, 255, 0.561);
        bottom: 0;
        left: 46.25vw;
        transform-origin: bottom center;
        transform: rotate(calc((var(--cents) / 50)* 90deg));
        transition: 200ms ease-in-out;
    }

    .note_tune_wheel_arrow_head {
        border-bottom: 2vw solid rgba(255,
                255,
                255, 1);
        position: absolute;
        top: -2vw;
        left: -0.8vw;
        border-left: 1vw solid transparent;
        border-right: 1vw solid transparent;
    }

    .note_tune_wheel_lines {
        --deg: 0;
        position: absolute;
        height: 47vw;
        width: 2vw;
        bottom: 0vw;
        left: 45.5vw;
        display: flex;
        justify-content: center;
        color: white;
        transform: rotate(calc((var(--deg) / 50)* 90deg));
        transform-origin: bottom center;
        z-index: 100;
        font-weight: 1000;
        font-size: 3vw;
    }

    .tune_direction {
        display: flex;
        grid-gap: 2vw;
        gap: 2vw;
        font-size: 3vw;
        align-items: center;
        color: white;
        position: absolute;
        right: 4vw;
        font-weight: 600;
    }

    .tune_direction.down {
        bottom: -3vw;
        right: 6vw;
    }

    .tune_direction.up {
        bottom: -3vw;
        left: 6vw;
    }

    .note_tune_wheel_lines.small {
        font-size: 1.5vw;
    }

    .note_tune_wheel_number {
        --cents: 0;
        position: absolute;
        height: 52vw;
        width: 2vw;
        bottom: 2vw;
        left: 49vw;
        display: flex;
        justify-content: center;
        color: white;
        transform: rotate(calc((var(--cents) / 50)* 90deg));
        transform-origin: bottom center;
        font-size: 3vw;
        font-weight: 500;
    }

    .note_tune_wheel_number.line {
        font-size: 0.7vw;
        padding-top: 0.2vw;
        font-weight: 1000;
    }

    .note_tune_wheel_outer {
        position: relative;
        width: 100vw;
        height: 50vw;
        display: flex;
        flex-direction: center;
        align-items: center;
        justify-content: center;
        border-radius: 50vw 50vw 0 0;
        margin-bottom: 4vw;

    }

    .current_note_display {
        position: relative;
        height: 15vw;
        width: 15vw;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6vw;
        z-index: 2;
        overflow: hidden;
        box-shadow: 0vw 0vw 2vw white;
        padding: 0 0 0.4vw 0;
        font-weight: 600;
        margin-bottom: 8vw;
    }

    .current_note_display_colors {
        --x: 0;
        position: absolute;
        left: calc(-117.5vw - var(--x) * 2.125vw);
        width: 250vw;
        height: 15vw;
        bottom: 0;
        background-color: green;
        z-index: -2;
        background: linear-gradient(90deg, red, orange 40%, rgb(0, 236, 0), orange 60%, red);
    }

    .current_note_decomp {
        display: flex;
        flex-direction: row;
        margin-left: 2.5vw;
    }

    .current_note_letter {
        width: 5vw;
    }

    .current_note_right_side {
        display: flex;
        flex-direction: column;
        width: 4vw;
        justify-content: space-between;
    }

    .current_note_sharp {
        height: 2vw;
        font-size: 4vw;
        margin-right: 0.5vw;
    }

    .current_note_octave {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 2vw;
        font-size: 2vw;
        margin-bottom: 0vw;
        padding: 0 0.1vw;
    }
}
.calculator_screen_outer {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.calculator_outer {
  width: 30vw;
  background-color: rgb(148, 148, 148);
  border: 0.4vw solid rgb(121, 121, 121);
  height: 40vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vw;
  grid-gap: 2vw;
  gap: 2vw;
  box-shadow: 0 0 1vw 0.2vw rgba(233, 233, 233, 0.2);
}
.calculator_display_outer {
  --calc-lighter-shade: rgb(167, 167, 167);
  --calc-darker-shade: rgb(121, 121, 121);
  border-left: 0.4vw solid var(--calc-darker-shade);
  border-top: 0.4vw solid var(--calc-darker-shade);
  border-right: 0.4vw solid var(--calc-lighter-shade);
  border-bottom: 0.4vw solid var(--calc-lighter-shade);
}
.calculator_display {
  width: 25vw;
  height: 5vw;
  border: none;
  background-color: rgb(151, 182, 151);
  caret-color: transparent;
  font-size: 4vw;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Digital-Clock";
  padding: 0 1vw;
}

.calculator_display.error {
  outline: 1px solid red;
  color: red;
}
.calculator_buttons {
  height: 30vw;
  width: 25vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.calculator_button_outer {
  width: 100%;
  height: 100%;
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculator_button_inner {
  height: 5.5vw;
  width: 5.5vw;
  border-radius: 0.5vw;
  background-color: rgb(20, 20, 20);
  border-left: 0.4vw solid rgb(46, 46, 46);
  border-top: 0.4vw solid rgb(46, 46, 46);
  border-right: 0.4vw solid rgb(0, 0, 0);
  border-bottom: 0.4vw solid rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4vw;
  font-weight: 600;
}

.calculator_button_inner.clicked {
  border-left: 0.4vw solid rgb(0, 0, 0);
  border-top: 0.4vw solid rgb(0, 0, 0);
  border-right: 0.4vw solid rgb(46, 46, 46);
  border-bottom: 0.4vw solid rgb(46, 46, 46);
}

.calculator_button_inner[value="C"] {
  background-color: rgb(226, 0, 0) !important;
  border-left: 0.4vw solid rgb(245, 0, 0) !important;
  border-top: 0.4vw solid rgb(245, 0, 0) !important;
  border-right: 0.4vw solid rgb(206, 0, 0) !important;
  border-bottom: 0.4vw solid rgb(206, 0, 0) !important;
  color: black;
}

.calculator_button_inner.clicked[value="C"] {
  border-left: 0.4vw solid rgb(206, 0, 0) !important;
  border-top: 0.4vw solid rgb(206, 0, 0) !important;
  border-right: 0.4vw solid rgb(245, 0, 0) !important;
  border-bottom: 0.4vw solid rgb(245, 0, 0) !important;
}

.calculator_button_inner.utility {
  font-size: 2.8vw;
  background-color: rgb(98, 98, 98);
  border-left: 0.4vw solid rgb(117, 117, 117);
  border-top: 0.4vw solid rgb(117, 117, 117);
  border-right: 0.4vw solid rgb(80, 80, 80);
  border-bottom: 0.4vw solid rgb(80, 80, 80);
}

.calculator_button_inner.utility.clicked {
  border-left: 0.4vw solid rgb(80, 80, 80);
  border-top: 0.4vw solid rgb(74, 80, 80);
  border-right: 0.4vw solid rgb(117, 117, 117);
  border-bottom: 0.4vw solid rgb(117, 117, 117);
}

.calculator_button_inner[value="."] {
  font-size: 1.5vw;
}

.calculator_button_inner[value="="] {
  background-color: rgb(24, 151, 2) !important;
  border-left: 0.4vw solid rgb(27, 174, 1) !important;
  border-top: 0.4vw solid rgb(27, 174, 1) !important;
  border-right: 0.4vw solid rgb(20, 124, 2) !important;
  border-bottom: 0.4vw solid rgb(20, 124, 2) !important;
  color: black;
}

.calculator_button_inner.clicked[value="="] {
  border-left: 0.4vw solid rgb(20, 124, 2) !important;
  border-top: 0.4vw solid rgb(20, 124, 2) !important;
  border-right: 0.4vw solid rgb(27, 174, 1) !important;
  border-bottom: 0.4vw solid rgb(27, 174, 1) !important;
}

@media only screen and (max-width: 600px) {
  .calculator_screen_outer {
    min-height: 100vh;
    width: 100vw;
  }

  .calculator_outer {
    width: 95vw;
    border: 1.2vw solid rgb(121, 121, 121);
    height: 137vw;
    border-radius: 2vw;
    padding: 5vw;
    grid-gap: 7vw;
    gap: 7vw;
  }

  .calculator_display_outer {
    --calc-lighter-shade: rgb(167, 167, 167);
    --calc-darker-shade: rgb(121, 121, 121);
    border-left: 1.5vw solid var(--calc-darker-shade);
    border-top: 1.5vw solid var(--calc-darker-shade);
    border-right: 1.5vw solid var(--calc-lighter-shade);
    border-bottom: 1.5vw solid var(--calc-lighter-shade);
  }

  .calculator_display {
    width: 80vw;
    height: 16vw;
    font-size: 10vw;
    padding: 0 3vw;
  }

  .calculator_buttons {
    height: 100vw;
    width: 80vw;
  }

  .calculator_button_outer {
    font-size: 6vw;
  }

  .calculator_button_inner {
    height: 18vw;
    width: 18vw;
    border-radius: 1.5vw;
    background-color: rgb(20, 20, 20);
    border-left: 1.2vw solid rgb(46, 46, 46);
    border-top: 1.2vw solid rgb(46, 46, 46);
    border-right: 1.2vw solid rgb(0, 0, 0);
    border-bottom: 1.2vw solid rgb(0, 0, 0);
    font-size: 7vw;
  }

  .calculator_button_inner.clicked {
    border-left: 1.2vw solid rgb(0, 0, 0);
    border-top: 1.2vw solid rgb(0, 0, 0);
    border-right: 1.2vw solid rgb(46, 46, 46);
    border-bottom: 1.2vw solid rgb(46, 46, 46);
  }

  .calculator_button_inner[value="C"] {
    border-left: 1.2vw solid rgb(245, 0, 0) !important;
    border-top: 1.2vw solid rgb(245, 0, 0) !important;
    border-right: 1.2vw solid rgb(206, 0, 0) !important;
    border-bottom: 1.2vw solid rgb(206, 0, 0) !important;
  }

  .calculator_button_inner.clicked[value="C"] {
    border-left: 1.2vw solid rgb(206, 0, 0) !important;
    border-top: 1.2vw solid rgb(206, 0, 0) !important;
    border-right: 1.2vw solid rgb(245, 0, 0) !important;
    border-bottom: 1.2vw solid rgb(245, 0, 0) !important;
  }
  .calculator_button_inner.utility {
    font-size: 8vw;
    border-left: 1.2vw solid rgb(117, 117, 117);
    border-top: 1.2vw solid rgb(117, 117, 117);
    border-right: 1.2vw solid rgb(74, 74, 74);
    border-bottom: 1.2vw solid rgb(74, 74, 74);
  }

  .calculator_button_inner.utility.clicked {
    border-left: 1.2vw solid rgb(80, 80, 80);
    border-top: 1.2vw solid rgb(74, 80, 80);
    border-right: 1.2vw solid rgb(117, 117, 117);
    border-bottom: 1.2vw solid rgb(117, 117, 117);
  }

  .calculator_button_inner[value="."] {
    font-size: 4vw;
  }

  .calculator_button_inner[value="="] {
    background-color: rgb(24, 151, 2) !important;
    border-left: 1.2vw solid rgb(27, 174, 1) !important;
    border-top: 1.2vw solid rgb(27, 174, 1) !important;
    border-right: 1.2vw solid rgb(20, 124, 2) !important;
    border-bottom: 1.2vw solid rgb(20, 124, 2) !important;
  }

  .calculator_button_inner.clicked[value="="] {
    border-left: 1.2vw solid rgb(20, 124, 2) !important;
    border-top: 1.2vw solid rgb(20, 124, 2) !important;
    border-right: 1.2vw solid rgb(27, 174, 1) !important;
    border-bottom: 1.2vw solid rgb(27, 174, 1) !important;
  }
}



* {
  --slider-width: 87vw;
}

.tools_outer_outer {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
}

.tools_outer {
  position: relative;
  width: 100vw;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider {
  display: flex;
  position: relative;
  width: var(--slider-width);
  height: 80vh;
  margin: 2vw;
}



.single-slide {
  position: relative;
  width: var(--slider-width);
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.custom-dots .slick-dots li button:before {
  color: grey;
  font-size: 1.2vw;
}

.custom-dots .slick-dots li.slick-active button:before {
  color: var(--main-color);
}

/* Change the color and size of the arrows */
.custom-dots .slick-prev:before,
.custom-dots .slick-next:before {
  color: var(--main-color);
  font-size: 3vw;
}

.custom-dots .slick-prev {
  left: -3.5vw;
}

/* Change the background color of the arrows on hover */
.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.2);
}


.tools_piano_outer {
  --key-width: 5.5vw;
  --key-height: 22vw;
  --key-border-width: 0.3vw;
  position: absolute;
  width: calc(3 * var(--key-width) - 2 * var(--key-border-width));
  height: var(--key-height);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.tools_piano_key {
  position: absolute;
  height: var(--key-height);
  width: var(--key-width);
  border: var(--key-border-width) solid var(--main-color);
  background-color: rgb(208, 202, 202);
  border-top: none;
  border-bottom-left-radius: 1.5vw;
  border-bottom-right-radius: 1.5vw;
}

.tools_piano_key.first {
  left: 0;
}

.tools_piano_key.second {
  left: calc(var(--key-width) - var(--key-border-width));
}

.tools_piano_key.third {
  left: calc( 2*var(--key-width) - 2* var(--key-border-width));
}

.tool_piano_black_key {
  --black-key-width: 4vw;
  --black-key-height: 18vw;
  position: absolute;
  height: var(--black-key-height);
  width: var(--black-key-width);
  background-color: rgb(0, 0, 0);
  z-index: 1;
  border: var(--key-border-width) solid var(--main-color);
  border-top: none;
  border-bottom-left-radius: 1.5vw;
  border-bottom-right-radius: 1.5vw;
  right: calc(-0.5 * var(--black-key-width));

}

.tool_title {
  position: absolute;
  width: var(--slider-width);
  color: var(--main-color);
  font-size: 2.5vw;
  bottom : 1vw;
  text-align: center;
  font-weight: 300;
  font-family: "Roboto";
}

.tools_guitar_tuner {
  position: absolute;
  overflow: hidden;

  --guitar-tuner-width: 40vw;
  width:var(--guitar-tuner-width);
  height: calc(0.5 * var(--guitar-tuner-width));
  border: 0.2vw solid var(--main-color);
  border-top-left-radius: calc(0.5 * var(--guitar-tuner-width));
  border-top-right-radius: calc(0.5 * var(--guitar-tuner-width));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* left: calc(0.5 * var(--slider-width) - 0.5 * var(--guitar-tuner-width));
  top: 5vw; */
}

.tools_guitar_tuner_line {
  --deg: 49;
  position: absolute;
  height: calc(0.5 * var(--guitar-tuner-width) + 0.4vw);
  width: 2vw;
  bottom: 0vw;
  left: calc(0.5 * var(--guitar-tuner-width) - 1.17vw);
  color: var(--main-color);
  font-size: 1.5vw;
  font-weight: 700;
  transform: rotate(calc((var(--deg) / 50)* 90deg));
  transform-origin: bottom center;
  display: flex;
    justify-content: center;
    font-weight: 1000;
    font-size: 1.5vw;
}

.tools_guitar_tuner_line.small {
  font-size: 1vw;
}

.tools_guitar_tuner_arrow_bottom {
  position: absolute;
  --arrow-bottom-width: 3vw;
  width: var(--arrow-bottom-width);
  height: calc(0.5 * var(--arrow-bottom-width));
  border-top-left-radius: calc(0.5 * var(--arrow-bottom-width));
  border-top-right-radius: calc(0.5 * var(--arrow-bottom-width));
  border: 2px solid var(--main-color);
  bottom: -2px;
  left: calc(0.5 * var(--guitar-tuner-width) - 0.5 * var(--arrow-bottom-width) - 2px);

}

.tools_guitar_tuner_arrow {
  --arrow-height: 15vw;
  position: absolute;
  left: calc(0.5 * var(--arrow-bottom-width) - 0.05vw - 2px);
  width: 0.1vw;
  height: var(--arrow-height);
  top: calc(-1 * var(--arrow-height));
  background-color: var(--main-color);
}

.tools_guitar_tuner_arrow_head {
  border-bottom: 1vw solid var(--main-color);
    position: absolute;
    top: -1vw;
    left: -0.40vw;
    border-left: 0.5vw solid transparent;
    border-right: 0.5vw solid transparent;
}

.tools_calculator_outer {
  --calculator-width: 18vw;
  --calculator-height: 22vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: var(--calculator-height);
  width: var(--calculator-width);
  border-radius: 1vw;
  border: 2px solid var(--main-color);
}

.tools_calculator_screen {
  position: absolute;
  width: calc(var(--calculator-width) - 2vw);
  height: 3.4vw;
  border: 0.2vw solid var(--main-color);
  border-radius: 0.4vw;
  left: 0.8vw;
  top: 1vw;
  overflow: hidden;
  }

.tools_calculator_buttons {
  position: absolute;
  top: 5vw;
  left: 0.8vw;
  height: 20vw;
  width: calc(var(--calculator-width) - 2vw);
  height: calc(var(--calculator-width) - 2vw);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0.5vw;
  column-gap: 0.5vw;
  grid-row-gap: 0.5vw;
  row-gap: 0.5vw;
  padding: 0.5vw 0vw;
}

.tools_calculator_buttons_single {
  border: 0.2vw solid var(--main-color);
  border-radius: 0.4vw;
}

@media only screen and (max-width: 600px) {
* {
  --slider-width: 75vw;
}
.slick-slider {
  display: flex;
  position: relative;
  width: var(--slider-width);
  height: 80vh;
  margin: 2vw;
}
.single-slide {
  position: relative;
  width: var(--slider-width);
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dots .slick-dots li button:before {
  font-size: 3vw;
}

/* Change the color and size of the arrows */
.custom-dots .slick-prev:before,
.custom-dots .slick-next:before {
  font-size: 8vw;
}

.custom-dots .slick-prev {
  left: -10vw;
}

.tools_piano_outer {
  --key-width: 15vw;
  --key-height: 60vw;
  --key-border-width: 1vw;
  position: absolute;
  width: calc(3 * var(--key-width) - 2 * var(--key-border-width));
  height: var(--key-height);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.tools_piano_key {
  position: absolute;
  height: var(--key-height);
  width: var(--key-width);
  border: var(--key-border-width) solid var(--main-color);
  background-color: rgb(208, 202, 202);
  border-top: none;
  border-bottom-left-radius: 4vw;
  border-bottom-right-radius: 4vw;
}

.tools_piano_key.first {
  left: 0;
}

.tools_piano_key.second {
  left: calc(var(--key-width) - var(--key-border-width));
}

.tools_piano_key.third {
  left: calc( 2*var(--key-width) - 2* var(--key-border-width));
}

.tool_piano_black_key {
  --black-key-width: 10vw;
  --black-key-height: 40vw;
  height: var(--black-key-height);
  width: var(--black-key-width);
  border: var(--key-border-width) solid var(--main-color);
  border-top: none;
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  right: calc(-0.5 * var(--black-key-width));
}

.tool_title {
  font-size:7vw;
  bottom : 5vw;

}

.tools_guitar_tuner {
  position: absolute;
  overflow: hidden;

  --guitar-tuner-width: 65vw;
  width:var(--guitar-tuner-width);
  height: calc(0.5 * var(--guitar-tuner-width));
  border: 2px solid var(--main-color);
  border-top-left-radius: calc(0.5 * var(--guitar-tuner-width));
  border-top-right-radius: calc(0.5 * var(--guitar-tuner-width));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tools_guitar_tuner_line {
  --deg: 49;
  position: absolute;
  height: calc(0.5 * var(--guitar-tuner-width) + 0.4vw);
  width: 2vw;
  bottom: 0vw;
  left: calc(0.5 * var(--guitar-tuner-width) - 1.5vw);
  color: var(--main-color);
  font-size: 3vw;
  font-weight: 700;
  transform: rotate(calc((var(--deg) / 50)* 90deg));
  transform-origin: bottom center;
  display: flex;
    justify-content: center;
    font-weight: 1000;
}

.tools_guitar_tuner_line.small {
  font-size: 2.2vw;
}

.tools_guitar_tuner_arrow_bottom {
  position: absolute;
  --arrow-bottom-width: 5vw;
  width: var(--arrow-bottom-width);
  height: calc(0.5 * var(--arrow-bottom-width));
  border-top-left-radius: calc(0.5 * var(--arrow-bottom-width));
  border-top-right-radius: calc(0.5 * var(--arrow-bottom-width));
  border: 2px solid var(--main-color);
  bottom: -2px;
  left: calc(0.5 * var(--guitar-tuner-width) - 0.5 * var(--arrow-bottom-width) - 2px);

}

.tools_guitar_tuner_arrow {
  --arrow-height: 23vw;
  position: absolute;
  left: calc(0.5 * var(--arrow-bottom-width) - 3px);
  width: 2px;
  height: var(--arrow-height);
  top: calc(-1 * var(--arrow-height));
  background-color: var(--main-color);
}

.tools_guitar_tuner_arrow_head {
  border-bottom: 2vw solid var(--main-color);
    position: absolute;
    top: -1.9vw;
    left: calc(-1vw + 1px);
    border-left: 1vw solid transparent;
    border-right: 1vw solid transparent;
}

.tools_calculator_outer {
  --calculator-width: 50vw;
  --calculator-height: 65vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: var(--calculator-height);
  width: var(--calculator-width);
  border-radius: 2vw;
  border: 0.6vw solid var(--main-color);
}

.tools_calculator_screen {
  position: absolute;
  width: calc(var(--calculator-width) - 4vw);
  height: 11.2vw;
  border: 0.6vw solid var(--main-color);
  border-radius: 1vw;
  left: 1.4vw;
  top: 2vw;
  overflow: hidden;
  }

.tools_calculator_buttons {
  position: absolute;
  top: 14vw;
  left: 1.4vw;
  width: calc(var(--calculator-width) - 4vw);
  height: calc(var(--calculator-width) - 1vw);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 1vw;
  column-gap: 1vw;
  grid-row-gap: 1vw;
  row-gap: 1vw;
  padding: 2vw 0vw;
}

.tools_calculator_buttons_single {
  border: 0.6vw solid var(--main-color);
  border-radius: 1vw;
}

}
.rain-container {
    --container-width: 15.5vw;
    position: absolute;
    width: var(--container-width);
    height: 3vw;
    overflow: hidden;
}


.raining_operator {
    position: absolute;
    --starting-x: 0.2;
    --delay: 0.5s;
    --size: 2;
    --speed: 2000ms;
    font-size: calc(var(--size) * 1vw);
    color: var(--main-color);
    top: -10vw;
    left: calc(var(--starting-x) * 20vw);
    animation: fall-down var(--speed) linear;
    animation-delay: var(--delay);
    z-index: 1000000;
}

@keyframes fall-down {
    0% {
        transform: translateY(-10vw);
    }

    100% {
        transform: translateY(20vw);
    }
}

@media only screen and (max-width: 600px) {
    .rain-container {
        --container-width: 50vw;
        position: absolute;
        width: var(--container-width);
        height: 10vw;
        overflow: hidden;
    }
    
    
    .raining_operator {
        position: absolute;
        --starting-x: 0.2;
        --delay: 0.5s;
        --size: 2;
        --speed: 2000ms;
        font-size: calc(var(--size) * 2.5vw);
        top: -5vw;
        left: calc(var(--starting-x) * var(--container-width));
        animation: fall-down calc(0.7 * var(--speed)) linear;
        animation-delay: var(--delay);
        z-index: 1000000;
    }
    
}
.chess_outer {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chess_board {
  position: relative;
  width: 30vw;
  height: 30vw;
  border: 1.5vw solid rgb(66, 35, 14);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
}

.chess_field {
    position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.3vw;
}

.chess_field[value="w"] {
  background-color: rgb(221, 161, 121);
}

.chess_field[value="b"] {
  background-color: rgb(121, 67, 32);
}

.chess_piece[color="black"] {
  filter: drop-shadow(0px 0px 0.1vw rgb(255, 255, 255));
}

.chess_piece[color="white"] {
  filter: drop-shadow(0px 0px 0.1vw rgb(0, 0, 0));
}

.chess_board_outer_row {
  position: absolute;
  font-size: 1.2vw;
  color: white;
  font-weight: 700;
  text-shadow: 0px 0px 0.3vw black;
}

.chess_board_outer_row.vertical div {
  height: 3.375vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chess_board_outer_row.horizontal {
  width: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  left: -1.5vw;
}
.chess_board_outer_row.vertical {
  display: flex;
  flex-direction: column;
}

.chess_board_outer_row.top {
  top: -1.5vw;
}

.chess_board_outer_row.bottom {
  bottom: -1.5vw;
}

.chess_board_outer_row.left {
  left: -1.1vw;
}
.chess_board_outer_row.right {
  right: -1vw;
}

.chess_piece.selected {
    background-color: green;
    width: 3.2vw;
    height: 3.2vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chess_field_highlight {
    position: absolute;
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    background-color: transparent;
}

.chess_field_highlight.selected {
    background-color: rgb(7, 181, 4);
    box-shadow: 0 0 0.75vw rgb(5, 225, 1);
}

.chess_field_highlight.possible {
    height: 1.5vw;
    width: 1.5vw;
    background-color: rgb(203, 203, 203);
    box-shadow: 0 0 0.2vw rgb(219, 219, 219);
}
.thirty-seconds-outer {
    min-height: 100vh;
    height: 48vw;
    position: relative;
    overflow: hidden;
}

.kies-kaartje-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.thirty-seconds-zandloper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18vw;
    margin-top: -3vw;
    transform: scale(0.5);
}

.kaartje-row {
    position: absolute;
    margin-top: 2vw;
    bottom: 3vw;
    width: 100%;
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .thirty-seconds-outer {
        height: 150vw;
    }
    .kies-kaartje-row{
        width: 100%;
        height: 30vw;
        display: flex;
        align-items: center;
    }
    .kaartje-row {
        height: 45vw;
        margin-bottom: 10vw;
    }
        .thirty-seconds-zandloper{
            transform: scale(1.5);
            margin-top: 25vw;
        }
}
* {
  --kaartje-blue-outer: rgb(9, 154, 224);
  --kaartje-blue-inner: rgb(161, 218, 248);
  --kaartje-yellow-outer: rgb(246, 210, 10);
  --kaartje-yellow-inner: rgb(249, 234, 180);
}

.kaartje-outer {
  position: relative;
  width: 30vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 0.5s linear kaartje-ease-in ;
}

@keyframes kaartje-ease-in {
    0% {
        transform: translateY(20vw);
    }
    100% {
        transform: translateY(0vw);
    }
}
.kaartje-outer.blue {
    background-color: var(--kaartje-blue-outer);
}
.kaartje-outer.yellow {
    background-color: var(--kaartje-yellow-outer);
}


.kaartje-inner {
  width: 75%;
  height: 100%;
  border-left: 0.2vw solid white;
  border-right: 0.2vw solid white;
}

.kaartje-inner.blue {
    background-color: var(--kaartje-blue-inner);
}

.kaartje-inner.yellow {
    background-color: var(--kaartje-yellow-inner);
}
.kaartje-rand-text {
  font-family: "Yantramanav", sans-serif;
  position: absolute;
  font-size: 2vw;
  color: white;
  font-weight: 700;
}
.kaartje-rand-text.links {
  transform: rotate(-90deg);
  left: -4.5vw;
}

.kaartje-rand-text.rechts {
  transform: rotate(90deg);
  right: -4.5vw;
}

.kaartje-woorden {
    height: 100%;
    width: 100%;
    padding: 1.5vw 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.3vw;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .kaartje-outer {
      transform: scale(3);
    }

        @keyframes kaartje-ease-in {
          0% {
            transform: translateY(60vw) scale(3);
          }
    
          100% {
            transform: translateY(0vw) scale(3);
          }
        }
}
.zandloper-outer {
  position: relative;
  width: 22vw;
  height: 32vw;
  overflow: hidden;
  --zand-kleur: #dbb48f;
  --zandloper-width: 20vw;
  --zandloper-height: 30vw;
}
.zandloper-frame.top {
  position: absolute;
  width: 22vw;
  height: 1.02vw;
  background-color: rgb(146, 63, 25);
  border: 0.2vw solid rgb(139, 58, 20);
  border-right: 0.2vw solid rgb(163, 71, 29);
  border-bottom: 0.2vw solid rgb(163, 71, 29);
  border-radius: 0.25vw;
  z-index: 10;
}

.zandloper-frame.bottom {
  position: absolute;
  bottom: 0;
  width: 22vw;
  height: 1.02vw;
  background-color: rgb(146, 63, 25);
  border: 0.2vw solid rgb(139, 58, 20);
  border-right: 0.2vw solid rgb(163, 71, 29);
  border-bottom: 0.2vw solid rgb(163, 71, 29);
  border-radius: 0.25vw;
  z-index: 10;
}
.zandloper-top {
  position: absolute;
  left: 50%;
  top: 1vw;
  transform: translateX(-50%);
  width: var(--zandloper-width);
  height: calc(var(--zandloper-height) / 2);
  -webkit-clip-path: polygon(47% 100%, 53% 100%, 100% 0, 0 0);
          clip-path: polygon(47% 100%, 53% 100%, 100% 0, 0 0);
  background: linear-gradient(
    90deg,
    rgb(150, 148, 148) 0%,
    rgb(29, 28, 28) 50%,
    rgb(150, 148, 148) 100%
  );
}

.zandloper-top-zand {
  transform: translateY(15vw);
  height: 20vw;
  width: 20vw;
  background-color: var(--zand-kleur);
  animation: 30s linear lower-sand forwards;
  overflow: hidden;
}

@keyframes lower-sand {
  0% {
    transform: translateY(1vw);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(8.5vw);
    animation-timing-function: linear;
  }
  99.6% {
    transform: translateY(15vw);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(15vw);
    animation-timing-function: ease-out;
  }
}

.zandloper-bottom {
  position: absolute;
  bottom: 1.01vw;
  left: 50%;
  transform: translateX(-50%);
  width: var(--zandloper-width);
  height: calc(var(--zandloper-height) / 2);
  -webkit-clip-path: polygon(47% 0, 53% 0, 100% 100%, 0 100%);
          clip-path: polygon(47% 0, 53% 0, 100% 100%, 0 100%);
  background: linear-gradient(
    90deg,
    rgb(150, 148, 148) 0%,
    rgb(29, 28, 28) 50%,
    rgb(150, 148, 148) 100%
  );
}

.zandloper-bottom-zand {
  position: absolute;
  overflow: hidden;
  transform: translateY(2vw);
  height: 30vw;
  left: -10vw;
  width: 40vw;
  border-radius: 50%;
  animation: 30s raise-sand forwards;
  background-color: var(--zand-kleur);
}
.sand-blur {
  height: 100%;
  width: 100%;
  background: repeating-conic-gradient(
      #0003 0.000001%,
      #fff0 0.00005%,
      #fff0 0.00035%,
      #fff0 0.00005%
    ),
    repeating-conic-gradient(
      #fff2 0.00002%,
      #fff0 0.00008%,
      #fff0 0.0008%,
      #fff0 0.00008%
    );
  opacity: 0.9;
  filter: blur(1px);
}

@keyframes raise-sand {
  0% {
    transform: translateY(15vw);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translateY(8vw);
    animation-timing-function: linear;
  }
  100% {
    transform: translateY(2vw);
    animation-timing-function: ease-out;
  }
}

.zandloper-lopend-zand {
  height: 100%;
  width: 1.2vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 5vw);
  background-color: var(--zand-kleur);
  animation: 30s linear running-sand forwards;
}

.no-animation {
  animation: none;
}

@keyframes running-sand {
  0% {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transform: translate(-50%, -15vw);
  }

        2% {
                border-bottom-left-radius: 50%;
                    border-bottom-right-radius: 50%;
            border-radius: 0%;
            transform: translate(-50%, 0vw);
        }
  99.49% {
    border-radius: 0%;
    transform: translate(-50%, 0vw);
  }
  99.5% {
    border-radius: 30%;
    transform: translate(-50%, 0vw);
  }
  100% {
    border-radius: 30%;
    transform: translate(-50%, 5vw);
  }
}

.zandloper-start-button {
  height: 10vw;
  width: 10vw;
  background-color: red;
}

.kies-kaartje-button-outer {
  position: relative;
  width: 20vw;
  height: 10vw;
  background-color: rgb(216, 213, 213);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-weight: 700;
  border-radius: 0.2vw;
  margin-top: 4vw;
}

.kies-kaartje-button-kaartje {
  position: absolute;
  width: 18vw;
  height: 2vw;
  top: -2vw;
}

.kies-kaartje-button-kaartje.blue {
  background-color: var(--kaartje-blue-outer);
  border-bottom: 0.2vw solid rgba(4, 128, 190, 0.3);
}
.kies-kaartje-button-kaartje.yellow {
  background-color: var(--kaartje-yellow-outer);
  border-bottom: 0.2vw solid rgb(226, 194, 12, 0.3);
}
.kies-kaartje-button-kaartje-inner {
  position: absolute;
  width: 75%;
  height: 2vw;
  left: 12.5%;
  border-left: 0.2vw solid white;
  border-right: 0.2vw solid white;
}

.kies-kaartje-button-kaartje-inner.blue {
  background-color: var(--kaartje-blue-inner);
  border-bottom: 0.2vw solid rgba(147, 202, 231, 0.3);
}
.kies-kaartje-button-kaartje-inner.yellow {
  background-color: var(--kaartje-yellow-inner);
  border-bottom: 0.2vw solid rgb(236, 222, 170, 0.3);
}

@media only screen and (max-width: 600px) {
.kies-kaartje-button-outer {
  transform: scale(1.8);
}
}

/* Merriweather */ /* Big Shoulders Inline Text */  /* Noto Serif */ /* Libre Baskerville */ /* DM Serif Display */ /* Play */ /* Russo One */ /* Righteous */ /* Roboto */ /* Roboto Mono */ /* Silkscreen */ /* Saira Stencil One */  /* Magneto */ /* Poppins */

:root {
    --top-bar-height: 3.5vw;
    --main-color: #a89142;
    --main-background-color: #000000;
    --background-color: #0f0f0f;
    --main-border-color: rgba(168, 145, 66, 0.4);
    --side-bar-hover-color: #1c1c1c;
    --main-color-op3: rgba(168, 145, 66, 0.3);
    --main-color-op2: rgba(168, 145, 66, 0.2);
    --side-bar-width: 12.5vw;
    --text-shadow-color: #000000;
    --icon-shadow-color: #4c3f14;
  

  }
  
  [logged-in="true"] {
    --side-bar-width: 15vw;
  }


  [theme='light'] {
    --main-color: rgb(28, 16, 101);
    --main-border-color: rgba(28, 16, 101, 0.4);
    --main-background-color: #D7E3FC;
    --background-color: #CCDBFD;
    --side-bar-hover-color: rgb(220, 229, 252);
    --main-color-op3: rgba(28, 16, 101, 0.15);
    --main-color-op2: rgba(28, 16, 101, 0.1);
    --text-shadow-color: rgb(117, 104, 201);
    --icon-shadow-color: rgb(216, 209, 253);
  }

  @media only screen and (max-width: 600px) {
    :root {
        --side-bar-width: 40vw;
        --top-bar-height: 13vw;

    }

      
  [logged-in="true"] {
    --side-bar-width: 50vw;
  }
  }
.outer {
  background-color: var(--main-background-color);
}


.main {
  margin: auto;
  margin-top: 4rem;
  width: 100%;
  border-radius: 5px;
  /* padding: 1rem; */
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.imageDiv {
  margin: auto;
  width: 40%;
  padding: 0.4rem;
}

.textDiv {
  margin: auto;
  width: 60%;
  color: whitesmoke;

}
.main img{
  width: 100%;
  border-radius: 100px;
  box-shadow: 1px 1px 10px 1px rgba(245, 245, 245, 0.301);

}
.main h2{
  padding: 0 5rem;
  color: whitesmoke;
  font-weight: bolder;
  font-size: medium;
}

.icons {
  margin: auto;
  margin-top: 10rem;
  width: 100vw;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.prank button {
    width: 100vw;
    height: 20vh;
    background: transparent;
    border: none !important;
    font-size:0;
}
.login_outer {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_inner {
    width: 40vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3vw;
    background-color:var(--background-color);
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 1vw var(--main-color-op3);
    font-family: "Roboto";
    border: 0.1vw solid var(--main-border-color);


}

.login_inner input {
    width: 20vw;
    height: 3vw;
    font-size: 1.5vw;
    font-family: inherit;
    background-color: transparent;
    border: none;
    padding: 0 0.2vw;
    color: var(--main-color);
    font-weight: 400;
}

.login_inner input::placeholder {
    color: var(--main-border-color);    
    font-size: 1.5vw;
    text-justify: center;

}

.login_sub_text {
    color: var(--main-color);
    text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;
    font-weight: 100;
    font-size: 1.3vw;   
    margin-bottom: 1vw;
    


}
.login_welcome_text {
    width: 100%;
    text-align: center;
    margin-top: 2vw;
    font-size: 2.2vw;
    color: var(--main-color);
    font-weight: 400;
    text-shadow: var(--text-shadow-color) 0px 0px 0.1vw;

}
.login_qr_code {
    width: 10vw;
    height: 10vw;
}

.login_input_outer {
    width: 25vw;
    display: flex;
    flex-direction: row;
    background-color: var(--main-background-color);
    border-radius: 5vw;
    box-shadow: 0px 0px 0.2vw var(--main-color-op3);
    border: 0.1vw solid var(--main-border-color);
    margin-top: 1.5vw;
}

.login_input_icon {
    width: 3.2vw;
    margin-left: 1vw;
    color: var(--main-color);
    font-size: 1.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_input_outer.focussed {
    border: 0.1vw solid var(--main-color)
}

.login_input_password {
    width: 17vw !important;
}

.login_input_icon_password_visibility {
    width: 3vw;
    color: var(--main-color);
    font-size: 1.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.login_submit_button {
    height: 3vw;
    width: 20vw;
    background-color: var(--main-color);
    border-radius: 3vw;
    margin: 1vw 0 3vw 0;
    font-size: 2vw;
    color: var(--main-background-color);
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1vw solid var(--main-background-color);
    box-shadow: 0px 0px 0.2vw var(--main-color-op3);
    font-size: 1.3vw;
    font-weight: 800;
    cursor: pointer;

}

.login_error {
    margin-top: 0.5vw;
    text-align: center;
    height: 1.5vw;
    width: 25vw;
    color: red;
    font-family: inherit;
    font-weight: 100;
    font-size: 1vw;
}

@media only screen and (max-width: 600px) {
 .login_inner {
    scale: 2.4;
 }   
}
.energy-outer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.energy-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.energy_bar_chart_outer {
    width: 80vw;
    height: 30vw;
    background-color: var(--background-color);
    padding: 1vw;
    border-radius: 1vw;
    border: 0.1vw solid var(--main-border-color);
}
.solitaire_outer {
    --card-width: 6.3vw;
    --card-height: 8.8vw;
    --card-border-radius: 0.3vw;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: rgb(1, 79, 1);
}

.playing_card {
    position: absolute;
    width: var(--card-width);
    height: var(--card-height);
    background-color: rgb(234, 220, 220);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    border: 0.1vw solid rgba(128, 128, 128, 0.5);
}

.playing_card.red {
    color: red;
}

.playing_card.black {
    color: black;
}

.centered_logo {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
}
.playing_card .suit_middle {
    font-size: 4vw;
}

.playing_card .suit_face_corner {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border:1px solid black; */
}

.suit_face_corner div {
    font-size: 0.8vw;
}

.suit_face_corner p {
    font-size: 0.8vw;
    font-weight: 800;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: -0.2vw;
}

.suit_face_corner.top {
    left: 0.25vw;
    top: 0.25vw;
}

.suit_face_corner.bottom {
    right: 0.25vw;
    bottom: 0.25vw;
    transform: rotate(180deg);
}

.playing_card img {
    height: 100%;
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
}

.pile {
    width:  6.3vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.solitaire_piles {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5vw;
}

.deck {
    position: relative;
    width: var(--card-width);
    height: var(--card-height);
    /* background-color: rgb(234, 220, 220); */
    border-radius: var(--card-border-radius);
    overflow: hidden;
    border: 0.2vw solid white;
    color: white;
    font-size: 3vw;
}


.deck img {
    height: 100%;
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
}
.solitaire_top_bar {
    height: 12vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
}

.solitaire_aflegstapels {
    display: flex;
    flex-direction: row;
    grid-gap: 2vw;
    gap: 2vw;

}

.solitaire_stapel {
    position: relative;
    width: var(--card-width);
    height: var(--card-height);
    border: 2px solid rgba(255, 255, 255, 0.549);
    border-radius: 0.5vw;
}   

.solitaire_stapel {
    position: relative;
    width: var(--card-width);
    height: var(--card-height);
    border: 2px solid rgba(255, 255, 255, 0.549);
}



.solitaire_stapel .suit{
    font-size: 1.5vw;
    z-index: 0;
color:rgba(255, 255, 255, 0.549); }

.solitair_deck_and_deal {
    display: flex;
    flex-direction: row;
    grid-gap: 2vw;
    gap: 2vw;
}

.deal {
    position: relative;
    width: calc(var(--card-width) + 4vw);
    height: var(--card-width);
    animation: deal 0.5s ease-out;

}

@keyframes deal {
    0% {
        transform: translateX(-6vw);

    }
    100% {
        transform: translateX(0);
    }
  }
  
  
  .deal-animation {
    animation: deal 0.2s ease-out;
  }
