.save_playlist_modal {
  width: 60vw;
  padding: 2vw;
  border: 3px solid rgb(29, 185, 84);
  font-size: 2vw;
  color: white;
  text-align: center;
  font-weight: 800;
}

.save_playlist_remove_title {
  width: 100%;
  font-size: 2vw;
  font-weight: 1000;
  margin: 1vw 0;
  color: red;
  text-decoration: underline;
}

.save_playlist_add_title {
  width: 100%;
  font-size: 2vw;
  font-weight: 1000;
  margin: 1vw 0;
  color: rgb(29, 185, 84);
  text-decoration: underline;
}

.save_playlist_list_item {
  font-size: 2vw;
  color: white;
  font-weight: 500;
}

.save_playlist_button_row {
  width: 100%;
  margin-top: 4vw;
  display: flex;
  flex-direction: row;
}

.save_playlist_button {
  width: 50%;
  margin: 1vw;
  border: 2px solid white;
  padding: 1vw 0;
  border-radius: 0.5vw;
  font-size: 2vw;
  font-weight: 800;
}

.save_playlist_button.save {
  background-color: rgb(29, 185, 84);
}

.save_playlist_button.cancel {
  background-color: rgb(54, 54, 54);
}

@media only screen and (max-width: 400px) {
  .save_playlist_modal {
    width: 90vw;
    padding: 3vw;
    border: 3px solid rgb(29, 185, 84);
    font-size: 5vw;
    color: white;
    text-align: center;
    font-weight: 800;
  }

  .save_playlist_remove_title {
    width: 100%;
    font-size: 5vw;
    font-weight: 1000;
    margin: 2vw 0;
    color: red;
    text-decoration: underline;
  }

  .save_playlist_add_title {
    width: 100%;
    font-size: 5vw;
    font-weight: 1000;
    margin: 2vw 0;
    color: rgb(29, 185, 84);
    text-decoration: underline;
  }

  .save_playlist_list_item {
    font-size: 5vw;
    color: white;
    font-weight: 500;
  }
  
  .save_playlist_button_row {
    width: 100%;
    margin-top: 4vw;
    display: flex;
    flex-direction: row;
  }
  
  .save_playlist_button {
    width: 50%;
    margin: 2vw;
    border: 2px solid white;
    padding: 2vw 0;
    border-radius: 1vw;
    font-size: 5vw;
    font-weight: 800;
  }
}
