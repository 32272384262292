* {
  --nokia-color: rgb(49, 57, 76);
  --screen-color: rgba(154, 195, 4, 1);
  --snake-color: rgb(53, 53, 53);
}


.snake-outer {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.phone-outer {
  position: relative;
}
.phone-image {
  width: 290px;
  height: 650px;
}

.screen {
  position: absolute;
  z-index: 100;
  top: 170px;
  left: 43px;
  width: fit-content;
  height: fit-content;
  background-color: var(--screen-color);
  padding: 0 7px 7px 7px;
  border-radius: 5px 5px 10px 10px;
}

.score-bar {
  position: relative;
  width: 100%;
  height: 25px;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 2px solid var(--snake-color);
  font-weight: 700;
  color: var(--snake-color);
}

.snake-inner {
  width: 600px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid {
  border: 2px solid var(--snake-color);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.grid-row {
  display: flex;
  flex-direction: row;
  gap: 1px;
}

.empty-tile {
  width: 6px;
  height: 6px;
}

.pixel {
  width: 8px;
  height: 8px;
}

.pixel.snake {
  background-color: var(--snake-color);
}
.pixel.apple {
  background-color: red;
}

.pixel.gameover {
  animation: blinker 0.5s infinite;
  background-color: red;
}

.snake_button {
  position: absolute;
  height: 100px;
  width: 100px;
}

.snake_button.left {
  top: 430px;
}

.snake_button.right {
  top: 430px;
  right: 0;
}

.snake_button.top {
  top: 390px;
  right: 105px;
  width: 80px;
}
.snake_button.bottom {
  top: 500px;
  right: 105px;
  width: 80px;
}

.game_over_screen {
  position: absolute;
  background-color: var(--screen-color);
  top: 202px;
  left: 52px;
  z-index: 100;
  width: 179px;
  height: 107px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Righteous";
  font-weight: 1000;
  color: var(--snake-color);
  font-size: 28px;
  gap: 0;
  animation: font-zoom 2s linear;
}

.game_over_screen p {
  font-size: 15px;
  height: 20px;
  margin-top: -3px;
  color: red;
  animation: font-appearing 2s;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes font-zoom {
  0% {
    font-size: 0px;
  }
  100% {
    font-size: 28px;
  }
}

@keyframes font-appearing {
  0% {
    font-size: 0px;
  }
  99% {
    font-size: 0px;
  }
  100% {
    font-size: 15px;
  }
}
.game_over_transition {
  position: absolute;
  animation: blinker 1s infinite;
  background-color: var(--screen-color);
  top: 201px;
  left: 51px;
  z-index: 100;
  width: 180px;
  height: 108px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Righteous";
  font-weight: 1000;
  font-size: 28px;
  gap: 0;
}

.snake_settings_button {
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 0px;
  color: var(--snake-color);
  cursor: pointer;
}

.snake_pause_play_button {
  position: absolute;
  font-size: 20px;
  right: 30px;
  top: 0;
  color: var(--snake-color);
  cursor: pointer;
}

.settings_screen {
  position: absolute;
  background-color: var(--screen-color);
  top: 202px;
  left: 52px;
  z-index: 110;
  width: 179px;
  height: 107px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Righteous";
  font-weight: 1000;
  color: var(--snake-color);
  font-size: 15px;
  gap: 0;
}

.snake_speed_option {
  font-size: 12px;
}

.snake_speed_option.active {
  color: rgb(251, 255, 39);
}

@media only screen and (max-width: 600px) {
  .snake-outer {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .phone-image {
    height: 100vh;
    width: 46vh;
    overflow-y: hidden;
  }

  .pixel {
    width: 1.33vh;
    height: 1.33vh;
  }

  .screen {
    position: absolute;
    z-index: 100;
    top: 26vh;
    left: 7vh;
    width: fit-content;
    height: fit-content;
    background-color: var(--screen-color);
    padding: 0 1vh 1vh 1vh;
    border-radius: 1vh 1vh 2vh 2vh;
  }

  .grid {
    border: 0.3vh solid var(--snake-color);
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.1vh;
  }

  .grid-row {
    display: flex;
    flex-direction: row;
    gap: 0.1vh;
  }

  .game_over_screen {
    position: absolute;
    background-color: var(--screen-color);
    top: 30.3vh;
    left: 8.3vh;
    z-index: 100;
    width: 28.5vh;
    height: 17.1vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Righteous";
    font-weight: 1000;
    font-size: 4.5vh;
    gap: 0;
    animation: font-zoom 2s linear;
  }

  .snake_button {
    position: absolute;
    height: 15vh;
    width: 15vh;
  }

  .snake_button.left {
    top: 68vh;
    left: 1vh;
  }

  .snake_button.right {
    top: 68vh;
    right: 1vh;
  }

  .snake_button.top {
    top: 60vh;
    right: 16.5vh;
    width: 13vh;
  }

  .snake_button.bottom {
    top: 77vh;
    right: 16.5vh;
    width: 13vh;
  }

  .game_over_screen p {
    font-size: 2.2vh;
    height: 3vh;
    margin-top: 0;
    color: red;
    animation: font-appearing 2s;
  }

  .score-bar {
    width: 100%;
    height: 3vh;
    font-size: 2vh;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
    border-bottom: 0.3vh solid var(--snake-color);
  }

  @keyframes font-zoom {
    0% {
      font-size: 0px;
    }

    100% {
      font-size: 4.5vh;
    }
  }

  @keyframes font-appearing {
    0% {
      font-size: 0px;
    }

    99% {
      font-size: 0px;
    }

    100% {
      font-size: 2.2vh;
    }
  }

  .settings_screen {
    position: absolute;
    background-color: var(--screen-color);
    top: 30.3vh;
    left: 8.3vh;
    z-index: 110;
    width: 28.5vh;
    height: 17.1vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Righteous";
    font-weight: 1000;
    color: var(--snake-color);
    font-size: 2.5vh;
    gap: 0;
  }

  .snake_speed_option {
    font-size: 1.8vh;
  }

  .snake_speed_option.active {
    color: rgb(251, 255, 39);
  }

  .snake_settings_button {
    position: absolute;
    right: 1vh;
    font-size: 2.5vh;
    top: 0vh;
    color: var(--snake-color);
    cursor: pointer;
  }

  .snake_pause_play_button {
    position: absolute;
    font-size: 2.5vh;
    right: 3.5vh;
    top: 0;
    color: var(--snake-color);
    cursor: pointer;
  }
}
